import React, { useState, useContext } from "react";
import { SuperContext } from "./SuperContext";
import DeleteModal from "../User/Ui/DeleteModal";
import { Fragment } from "react";
import ReactTooltip from "react-tooltip-rc";


function ServiceRow(props) {
  const [deletemodal, SetDeleteModal] = useState(false);

  const {
    table,
    mappedServiceData,
    SetEditMode,
    SetMappedServiceData,
    SetViewReadOnly, selectProfile
  } = useContext(SuperContext);

  const handleDelete = (e) => {
    SetDeleteModal(true);
    e.stopPropagation();
  };
  const setProfileForm = () => {
    SetViewReadOnly(true);
    SetEditMode(false);
    const selected = selectProfile(props.id, props.serviceformClose);
  };

  return (
    <Fragment>
<tr onClick={setProfileForm} key={props.id}  className={`trow single-row ${ Object.keys(mappedServiceData).length && mappedServiceData.id === props.id ? "activeRow" : "test1" }`}>      
        <td click="view">{props.name}</td>
        <td click="view">{props.owner_name}</td>
        <td click="view">{props.email}</td>
        <td click="view">{props.phone_extension}{props.phone_number}</td>
        <td>
        <div class="actions">
          <span class="action-delete" onClick={handleDelete}  data-for="main" data-tip="Delete Service Center" ><i class="fal fa-trash"></i></span>
          <ReactTooltip
            id="main"
            place="bottom"
            type="info"
            effect="float"
          />
          </div>
        </td>
      </tr>
      
      {deletemodal && <DeleteModal SetDeleteModal={SetDeleteModal} id={props.id} />}
    </Fragment>
  );
}

export default ServiceRow;

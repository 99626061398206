import React  from 'react';
const StaffFilterDropdownContent = () => {
  return (
    <div className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton">
      <a className="dropdown-item" href="">
        All vehivles
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
    </div>
  );
}

export default StaffFilterDropdownContent;

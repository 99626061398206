import React, { useContext } from "react";
import { ServiceContext } from "../ServiceContext";
import "./css/ServiceModal.css";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import deleteFile from "../../../../../assets/img/icon/upload/recycle-bin.svg";
function ServiceModal(props) {
  const {
    deleteServiceAction,
    table,
    SetTable,
    mappedServiceData,
    SetMappedServiceData,
    SetViewReadOnly,
    handleServiceDelete,
  } = useContext(ServiceContext);

  const deleteConfirm = (e) => {
    const onSuccess = () => {
      toast.success("Deleted Successfully", {
        className: "error-toast",
        position: "bottom-right",
      });
      props.SetDeleteModal(false);
      if (props.SetIsDeleted) {
        props.SetIsDeleted(true);
      }
    };
    const onFailure = () => {
      toast.error("Request Failed", {
        className: "error-toast",
        position: "bottom-right",
      });
    };
    deleteServiceAction(
      props.id ? props.id : mappedServiceData.service_id,
      onSuccess,
      onFailure
    );

    // alert("hai");
  };
  console.log(props.test, "props.test");
  console.log(mappedServiceData.service_id, "vehTypId");
  return (
    <>
      <Modal
        className="custom-modal"
        show={props.SetDeleteModal}
        onHide={() => props.SetDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>WARNING ....</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-info-img">
            <img src={deleteFile} className="delete-file" alt="" />
          </div>
          <h5>{`Are you sure you want to delete ${props.name != undefined ? props.name : ""
            }?`}</h5>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center pt-3">
            <button
              className="btn mr-3 btn-cancel"
              onClick={() => props.SetDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn mr-3 btn-danger" onClick={deleteConfirm}>
              Continue
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServiceModal;

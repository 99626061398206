import React from "react";

export const Validation = (
  values, field_value, isOnChange, field
) => {
  const errors = {};

  if (isOnChange) {
    if (field === "customer_name")
     {
      if (!field_value)
        errors.customer_name = "customer_name is required";
    }
    else if (field === "phone_number") {
      if (!values.phone_number)
        errors.phone_number = "phone_number is required";
    } 
    // else if (field === "servicetags") {
    //   if (!Object.keys(servicetags).length) {
    //     errors.servicetags = "Click Enter or Tab";
    //   }
    // } 
    // else if (field === "isPrice") {
    //   if (isPrice.length === 0) errors.isPrice = "isPrice is required";
    // }
  } else {
    //Vehicle Number
    if(!values.vehicle_number) 
    errors.vehicle_number = "Vehicle number is required";
   
    //customer name
    if(!values.customer_name)
    errors.customer_name = "Customer name is required";
    
    //phone number
    if(!values.phone_number)
    errors.phone_number = "Phone number is required";
   
    //vehicle type
    if(!values.vehicleType)
    errors.vehicleType = "Vehicle type is required";
   
    //vehicle model
    if(!values.vehicle_model)
    errors.vehicle_model = "Vehicle model is required";

    //service date
    if(!values.service_date)
    errors.vehicleType = "Service date is required";

    //service time
    if(!values.service_time)
    errors.vehicleType = "Service time is required";
    

    //vehicle type
    if(!values.service || Object.keys(values.service).length == 0)
    errors.service = "Service is required";

    //customer name
    if(!values.payment_type)
    errors.payment_type = "Payment type is required";    
    
  }
  return errors;
};

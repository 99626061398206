import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import LicenseUploadModal from "./LicenseUploadModal,";
import { Scrollbars } from "react-custom-scrollbars-2";
import licensebg from "../../../../../assets/img/license/license.webp";
import ReactTooltip from "react-tooltip-rc";
import configData from "../../../../../config.json";
import { toast } from "react-toastify";
import {
  createOrUpdatePermissions,
  listPermissions,
} from "../../../../../api/services/sap/profile-management";

const LicenseTab = () => {
  const {
    innertab,
    addImageSubmit,
    sapLicense,
    setSapLicense,
    handleLicenseShow,
    userProfile,
    setUserProfile,
    licenseviewonly,
    setLicenseViewOnly,
  } = useContext(ProfileContext);
  const [licenseformData, setLicenseFormData] = useState();
  // const [viewonly, setLicenseViewOnly] = useState(true);
  const [licenseData, setLicenseData] = useState([]);

  useEffect(() => {
    const data = listPermissions();
    data
      .then((res) => {
        // console.log(res, "res id");
        setLicenseData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(licenseData, "after useeffct");
  //License note handler
  const imageNoteHandler = (value, index, type) => {
    if (type === "add") {
      let newVar = sapLicense.filter((e) => {
        if (e.index === index) {
          e.imageNote = value;
        }
        return e;
      });
      setSapLicense(newVar);
    }
    else if (type === "add") {
      if (type === "edit") {
        let newData = licenseData.filter((e) => {
          if (e.index === index) {
            e.file_url = value;
          }
          return e;
        });
        setLicenseData(newData);
      }
    }
  };

  const [formsubmit, setFormSubmit] = useState(false);
  //preFormSubmitHandler
  const licenseFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setLicenseViewOnly(true);
    setUserProfile({ ...userProfile, sap_license: sapLicense });
    const updatedSapData = createOrUpdatePermissions({
      sap_license: [...licenseData, ...sapLicense],
    });
    toast.success("Details Updated Successfully", {
      position: "top-right",
    });
    updatedSapData.then((res) => {
      // console.log(res, "res of submit");
      const data = listPermissions();
      data.then((res) => {
        // console.log(res, "res of list data submit");
      });
    });
  };
  // console.log(userProfile, "userProfileuserProfile");
  //check file extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  return (
    <div
      className={innertab === 4 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="main-tab-block">
        <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
          <div className="inner-block tab-form-block">
            <Fragment>
              <div className="">
                {/*    */}
                {/* {licenseviewonly && (
                      <div className="action-block-right col-12 text-end p-0 mt-3">
                        <button
                          className="btn btn-action btn-edit"
                          rel="tooltip"
                          data-for="Edit-info"
                          data-tip="Edit Details"
                          onClick={(e) => setLicenseViewOnly(false)}
                        >
                          <i className="fal fa-pen"> </i>
                          <ReactTooltip
                            id="Edit-info"
                            place="top"
                            type="info"
                            effect="float"
                          />
                        </button>
                      </div>
                    )} */}
              </div>
              <form className="" onSubmit={licenseFormSubmitHandler}>
                <p className=" mb-3  text-info">
                  Service Center License Details
                </p>
                {/* <div className="form-box gst-img-box">
                      <div className="">
                        <img className="gstimage" src={licensebg} />
                      </div>
                    </div> */}
                <div className="form-box">
                  <div className="input-group input-group-custom img-block">
                    <div className="small-img-sub-block">
                      <div className="row-img row col-12">
                        {/* for file from db */}
                        {licenseData
                          ? licenseData.map((data, index) => {
                            return (
                              <div className="row">
                                <div
                                  className="col-md-4 col-img-list"
                                  key={index}
                                >
                                  <div className="img-list">
                                    <div className="choosed-file-block">
                                      {getExtension(
                                        data.file_name
                                      ).toLowerCase() === "pdf" ? (
                                        <div className="pdf-block ">
                                          <span className="pdf-icon">
                                            <i class="fal fa-file-pdf"></i>
                                          </span>
                                          <a
                                            href={`${configData.SERVER_URL}/uploads/service-compliances/${data.file_name}`}
                                            // href={`${configData.SERVER_URL}${data.file_name}`}
                                            download
                                            target="_blank"
                                          >
                                            <span>{data.file_url}</span>
                                          </a>
                                          {/* <h5>{photo.img.name}</h5> */}
                                        </div>
                                      ) : getExtension(
                                        data.file_name
                                      ).toLowerCase() === "docx" ? (
                                        <div className="pdf-block ">
                                          <span className="pdf-icon">
                                            <i class="fal fa-file-word word"></i>
                                          </span>
                                          <a
                                            href={`${configData.SERVER_URL}/uploads/service-compliances/${data.file_name}`}
                                            download
                                            target="_blank"
                                          >
                                            <span>{data.file_url}</span>
                                          </a>
                                          {/* <h5>{photo.img.name}</h5> */}
                                        </div>
                                      ) : getExtension(
                                        data.file_name
                                      ).toLowerCase() === "doc" ? (
                                        <div className="pdf-block ">
                                          <span className="pdf-icon">
                                            <i class="fal fa-word word"></i>
                                          </span>
                                          <a
                                            href={`${configData.SERVER_URL}/uploads/service-compliances/${data.file_name}`}
                                            download
                                            target="_blank"
                                          >
                                            <span>{data.file_url}</span>
                                          </a>
                                          {/* <h5>{photo.img.name}</h5> */}
                                        </div>
                                      ) : (
                                        <div className="pdf-block">
                                          <img
                                            src={`${configData.SERVER_URL}/uploads/service-compliances/${data.file_name}`}
                                            className="img-fluid"
                                            alt=""
                                          />
                                          <a
                                            href={`${configData.SERVER_URL}/uploads/service-compliances/${data.file_name}`}
                                            download
                                            target="_blank"
                                          ></a>
                                          {/* <img
                                                  src={photo.img.base64}
                                                  className="img-fluid"
                                                  alt="image"
                                                /> */}
                                        </div>
                                      )}
                                    </div>
                                    {/* {licenseviewonly && (
                                            <div>
                                              <a
                                                type="button"
                                                class="btn btn-outline-dark"
                                                href={photo.img.base64}
                                                target="_blank"
                                                download
                                              >
                                                <i class="fal fa-arrow-to-bottom"></i>
                                              </a>
                                            </div>
                                          )} */}
                                  </div>
                                </div>
                                <div className="col-md-8 ">
                                  {!licenseviewonly ? (
                                    <div className="text-end">
                                      <button
                                        disabled={
                                          licenseviewonly ? true : false
                                        }
                                        className="btn "
                                        onClick={() =>
                                          setLicenseData(
                                            licenseData.filter(
                                              (e) =>
                                                e.file_name !==
                                                data.file_name
                                            )
                                          )
                                        }
                                      >
                                        <i class="fal fa-times text-danger"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="form-box">
                                    <div className="mb-3">
                                      <label
                                        for="exampleInputEmail1"
                                        class="form-text text-start text-info"
                                      >
                                        Notes{" "}
                                        <sup className="theme-text">
                                          *
                                        </sup>
                                      </label>
                                      <textarea
                                        readOnly={
                                          licenseviewonly ? true : false
                                        }
                                        type="text"
                                        className="form-control note-textarea"
                                        // placeholder="Notes "
                                        onChange={(e) => {
                                          imageNoteHandler(
                                            e.target.value,
                                            index, "edit"
                                          );
                                        }}
                                        value={data.file_url}
                                      ></textarea>
                                      {/* <span className="form-error">Address is required!</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : ""}
                        {/* for BASE64 */}
                        {addImageSubmit && sapLicense
                          ? sapLicense.map((photo, index) => {
                            return (
                              <div className="row">
                                <div
                                  className="col-md-4 col-img-list"
                                  key={index}
                                >
                                  <div className="img-list">
                                    <div className="choosed-file-block">
                                      {getExtension(
                                        photo["img"]["name"]
                                      ).toLowerCase() === "pdf" ? (
                                        <div className="pdf-block ">
                                          <span className="pdf-icon">
                                            <i class="fal fa-file-pdf"></i>
                                          </span>
                                          <h5>{photo.img.name}</h5>
                                        </div>
                                      ) : getExtension(
                                        photo["img"]["name"]
                                      ).toLowerCase() === "docx" ? (
                                        <div className="pdf-block ">
                                          <span className="pdf-icon">
                                            <i class="fal fa-file-word word"></i>
                                          </span>
                                          <h5>{photo.img.name}</h5>
                                        </div>
                                      ) : getExtension(
                                        photo["img"]["name"]
                                      ).toLowerCase() === "doc" ? (
                                        <div className="pdf-block ">
                                          <span className="pdf-icon">
                                            <i class="fal fa-word word"></i>
                                          </span>
                                          <h5>{photo.img.name}</h5>
                                        </div>
                                      ) : (
                                        <div className="pdf-block">
                                          <img
                                            src={photo.img.base64}
                                            className="img-fluid"
                                            alt="image"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {licenseviewonly && (
                                      <div>
                                        <a
                                          type="button"
                                          class="btn btn-outline-dark"
                                          href={photo.img.base64}
                                          target="_blank"
                                          download
                                        >
                                          <i class="fal fa-arrow-to-bottom"></i>
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-8 ">
                                  {!licenseviewonly ? (
                                    <div className="text-end">
                                      <button
                                        disabled={
                                          licenseviewonly ? true : false
                                        }
                                        className="btn "
                                        onClick={() =>
                                          setSapLicense(
                                            sapLicense.filter(
                                              (e) => e !== photo
                                            )
                                          )
                                        }
                                      >
                                        <i class="fal fa-times text-danger"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="form-box">
                                    <div className="mb-3">
                                      <label
                                        for="exampleInputEmail1"
                                        class="form-text text-start text-info"
                                      >
                                        Notes{" "}
                                        <sup className="theme-text">
                                          *
                                        </sup>
                                      </label>
                                      <textarea
                                        readOnly={
                                          licenseviewonly ? true : false
                                        }
                                        type="text"
                                        className="form-control note-textarea"
                                        // placeholder="Notes "
                                        onChange={(e) => {
                                          imageNoteHandler(
                                            e.target.value,
                                            index, "add"
                                          );
                                        }}
                                        value={photo.imageNote}
                                      ></textarea>
                                      {/* <span className="form-error">Address is required!</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                      {!licenseviewonly && (
                        <div className="small-block">
                          <button
                            disabled={licenseviewonly ? true : false}
                            type="button"
                            className="btn-image"
                            onClick={handleLicenseShow}
                          >
                            <div className="upload-section justify-content-center">
                              <div className="upload-img">
                                <img
                                  src={uploadImageFile}
                                  alt="loading..."
                                />
                              </div>
                            </div>
                            Upload License
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="text-start"><button class="btn btn-info">Add another</button></div> */}
                  {/* <label
                        for="exampleInputEmail1"
                        class="form-text text-start "
                      >
                        Upload Business License deatils here{" "}
                        <sup className="theme-text">*</sup>
                      </label> */}
                  {/* Service Center Name */}
                </div>

                {!licenseviewonly && (
                  <div className="col-12 text-end p-0 mt-3">
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
          <LicenseUploadModal />
        </Scrollbars>
      </div>
    </div>
  );
};

export default LicenseTab;

import { sapAxios } from "../../axios";

const getCustomerList = async () => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "/get-customers",
      data: { request_from: "web_app" },
    });
    if (res.status) {
      console.log(res, "get-customers");
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

const getCustomerVehicleList = async (id) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: `/customer-vehicles/${id}`
    });
    if (res.status) {
      console.log(res, "getCustomerVehicleList");
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

const getCustomerOrders = async (id) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: `/customer-orders/${id}`
    });
    if (res.status) {
      console.log(res, "getCustomerOrders");
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

const getEditCustomer = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: `/customer-update`,
      data
    });
    if (res.status) {
      console.log(res, "get customer-update");
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}; 

export { getCustomerList, getCustomerVehicleList, getCustomerOrders , getEditCustomer };

import React, { useState, useContext, useEffect, Fragment } from "react";
import { ServiceContext } from "./ServiceContext";
import configData from "../../../../config.json";
import "../../../../assets/css/tags.css";
import { v4 as uuidv4 } from "uuid";
import ServiceFileUploadModal from "./ServiceFileUploadModal";
import { toast } from "react-toastify";
import NavToggle from "./Ui/NavToggle";
import { Validation } from "./Ui/Validation";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import ReactTooltip from "react-tooltip-rc";
import Select from "react-select";

const initialState = {
  service_name: "",
  service_description: "",
  product_description: "",
  service_discount: "",
  others: "",
  common_price: "",
  vehicle_select: "",
  is_active: 0,
  is_new: 1,
};

const errorFields = {
  service_name: "",
  vehicle_select: "",
  service_description: "",
  servicetags: "",
  producttags: "",
  warranty_description: "",
  validupto_month: "",
  validupto_year: "",
  priceDetails: "",
  isPrice: "",
  common_price: "",

  // others: "",
  // common_price: "",
};
function ServiceForm(props) {
  let rejectStatus;
  const [inform, setInform] = useState(false);
  const [formErrors, SetFormErrors] = useState(errorFields);
  const [error, setError] = useState(false);
  const [EditServiceID, SetEditServiceID] = useState("");
  const [formData, SetFormData] = useState(initialState);
  let [priceDetails, setPriceDetails] = useState([]);
  const [producttags, SetProductTags] = useState([]);
  const [warrantyDetails, setWarrantyDetails] = useState({
    warranty_id: "",
    warranty_description: "",
    validupto_month: "",
    validupto_year: "",
  });
  const [serviceImageData, setServiceImages] = useState([]);
  const [show, setShow] = useState(false); //Image Modal
  const [servicetags, SetServiceTags] = useState([]);
  let [inputlist, SetInputList] = useState([]); //Add Dynamic Variants
  const [isPrice, setIsPrice] = useState("");
  const {
    isUpdateForm,
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    addMode,
    setAddMode,
    SetViewReadOnly,
    editMode,
    SetEditMode,
    review,
    SetReview,
    servicePic,
    setServicePic,
    updateOrCreateService,
    isSelected,
    setIsSelected,
    SetFormChange,
    addImageSubmit,
    setAddImageSubmit,
    setLoader,
    vehicleTypes,
    vehicleVariants,
    saveVehicleVariants,
    pic,
    setPic,
    addonImages,
    setAddonImages,
  } = useContext(ServiceContext);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const addVariantPriceDetails = (i, variant_id_pk, price) => {
    const data = priceDetails.map((priceItem) => priceItem);
    data[i].price = price ? price : "";
    setPriceDetails(data);
  };

  //Service Tag
  const addServiceTags = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const value = event.target.value;
      if (!value.trim()) return;
      SetServiceTags([...servicetags, event.target.value]);
      event.target.value = "";
      event.preventDefault();
    } else return;
  };

  const removeServiceTags = (indexToRemove) => {
    SetServiceTags(servicetags.filter((_, index) => index != indexToRemove));
  };
  const addProductTags = (e) => {
    if (e.key == "Enter" || e.key == "Tab") {
      const value = e.target.value;
      if (!value.trim()) return;
      SetProductTags([...producttags, value]);
      e.target.value = "";
      e.preventDefault();
    } else return;
  };
  const removeProductTags = (index) => {
    SetProductTags(producttags.filter((el, i) => i !== index));
  };
  const updateToast = () => {
    return editMode ? "Updated Successfully" : "Added Successfully";
  };
  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputlist];
    list[index][name] =
      name === "price" ? (parseInt(value) ? parseInt(value) : "") : value;
    SetInputList(list);
  };
  const [showVariants, setShowVariant] = useState(0);
  const handleaddclick = () => {
    SetInputList([
      ...inputlist,
      {
        price: "",
        service_price_id: "",
        vehicle_variant_id: "",
        variant_name: "",
        status: "",
        rejected_reason: "",
      },
    ]);
    setShowVariant(1);
  };
  const handleremove = (index) => {
    const list = [...inputlist];
    list.splice(index, 1);
    SetInputList(list);
  };

  const onChangeValidate = (field) => {
    const errors = Validation(
      formData,
      servicetags,
      isPrice,
      priceDetails,
      true,
      field
    );
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setError(false);
      SetFormErrors(errorFields);
    }
  };
  //Form Submit
  const formSubmit = (e) => {
    e.preventDefault();
    let errors = Validation(formData, servicetags, isPrice, priceDetails);
    const onSuccess = () => {
      toast.success(updateToast, {
        position: "bottom-right",
      });
      props.serviceformClose(false);
      SetEditMode(false);
      setAddMode(false);
      SetMappedServiceData({});
      setAddImageSubmit(false);
      setServicePic([]);
    };
    // const onFailure = () => {
    //   toast.error("Request Failed", {
    //     position: "bottom-right",
    //   });
    // };
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      if (isPrice === "Common Price") {
        const newPrices = priceDetails.map((e) => ((e.price = ""), e));
        const newInputList = inputlist.map((e) => ((e.price = ""), e));
        priceDetails = newPrices;
        inputlist = newInputList;
      }
      if (isPrice === "Normal Price") formData.common_price = "";
      const data = {
        ...formData,
        service_images: [...serviceImageData, ...pic],
        product_tags: producttags,
        service_tags: servicetags,
        warranty_details: warrantyDetails,
        price_details: priceDetails,
        new_variants: inputlist,
        status:
          formData.status == 2
            ? 2
            : Object.values(e.nativeEvent.submitter.classList).includes(
                "save-draft"
              )
            ? 0
            : 1,
      };
      updateOrCreateService(data, EditServiceID, { onSuccess });
    }
  };
  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (regex.test(key)) {
      e.preventDefault();
    }
  };
  const filterPriceDetails = (servicePriceDetails) => {
    const extractPriceDetails = (item) => {
      return {
        price: item.price,
        vehicle_variant_id: item.vehicle_variant_id,
        variant_name: item.vehicleVariant.variant_name,
        service_price_id: item.service_price_id,
        status: item.vehicleVariant.status,
        rejected_reason: item.vehicleVariant.rejected_reason,
      };
    };

    let unapprovedVariantPrices = servicePriceDetails
      .filter(
        (item) =>
          item.vehicleVariant.status === 0 || item.vehicleVariant.status === -1
      )
      .map(extractPriceDetails);
    let approvedVariantPrices = vehicleVariants.map((e) => {
      const priceDetail = servicePriceDetails
        .filter((item) => item.vehicleVariant.status === 1)
        .map(extractPriceDetails)
        .filter((el) => el.vehicle_variant_id === e.vehicle_variant_id);
      if (priceDetail.length) {
        return priceDetail[0];
      }
      return {
        price: "",
        vehicle_variant_id: e.vehicle_variant_id,
        variant_name: e.variant_name,
        service_price_id: "",
      };
    });

    setPriceDetails(approvedVariantPrices);
    if (unapprovedVariantPrices.length) SetInputList(unapprovedVariantPrices);
  };
  const setTags = (serviceTags, productTags) => {
    const hasServiceTags =
      serviceTags.length >= 2 || !(serviceTags.length === 1 && !serviceTags[0]);
    const hasProductTags =
      productTags.length >= 2 || !(productTags.length === 1 && !productTags[0]);
    SetServiceTags(hasServiceTags ? serviceTags : []);
    SetProductTags(hasProductTags ? productTags : []);
  };
  const variantIcon = (vehicleTypeId) => {
    if (formData.vehicle_select === 1)
      return <i className="fal fa-motorcycle"></i>;
    if (formData.vehicle_select === 2) return <i className="fal fa-car"></i>;
    if (formData.vehicle_select === 3) return <i className="fal fa-bus"></i>;
  };

  const sideEffectFn = () => {
    const hasServiceData = !(
      mappedServiceData &&
      Object.keys(mappedServiceData).length === 0 &&
      Object.getPrototypeOf(mappedServiceData) === Object.prototype &&
      Object.keys(initialState).values === Object.keys(formData).values
    );

    if (hasServiceData) {
      const {
        service_id: id,
        vehicle_type_id,
        product_tags,
        service_tags,
        serviceImages,
        servicePriceDetails,
        serviceWarrantyDetail,
        ...rest
      } = mappedServiceData;
      if (mappedServiceData.common_price) setIsPrice("Common Price");
      else setIsPrice("Normal Price");
      let vehicle_select =
        mappedServiceData.status === 0 && formData.vehicle_select != ""
          ? formData.vehicle_select
          : vehicle_type_id;

      SetEditServiceID(id);
      filterPriceDetails(servicePriceDetails);
      SetFormData({ ...rest, vehicle_select });
      setTags(service_tags, product_tags);
      setWarrantyDetails(serviceWarrantyDetail);
      setServiceImages(serviceImages);
    } else {
      setPriceDetails(
        vehicleVariants.map((e) => ({
          price: "",
          vehicle_variant_id: e.vehicle_variant_id,
          variant_name: e.variant_name,
          service_price_id: "",
        }))
      );
    }
    // setLoader(false);
    return () => {
      if (!props.addservice) {
        SetFormData(initialState);
        SetServiceTags([]);
        SetProductTags([]);
        SetInputList([
          {
            price: "",
            service_price_id: "",
            vehicle_variant_id: "",
            variant_name: "",
            status: "",
          },
        ]);
        setWarrantyDetails({
          warranty_id: "",
          warranty_description: "",
          validupto_month: "",
          validupto_year: "",
        }); //Added so as to remove
        setServiceImages([
          {
            service_image_id: "",
            image_name: "",
            image_url: "",
          },
        ]);
        SetEditMode(false);
      }
    };
  };
  useEffect(() => {
    setPic([]);
    setServiceImages([]);
    SetFormData(initialState);
    SetServiceTags([]);
    SetProductTags([]);
    SetInputList([]);
    setWarrantyDetails({
      warranty_id: "",
      warranty_description: "",
      validupto_month: "",
      validupto_year: "",
    }); //Added so as to remove
    // SetEditMode(false);
  }, [addMode, mappedServiceData]);
  useEffect(sideEffectFn, [vehicleVariants]);

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const vehicleOptions = vehicleTypes.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  const MonthOptions = [
    { value: 0, label: "0 Months" },
    { value: 1, label: "1 Months" },
    { value: 2, label: "2 Months" },
    { value: 3, label: "3 Months" },
    { value: 4, label: "4 Months" },
    { value: 5, label: "5 Months" },
    { value: 6, label: "6 Months" },
    { value: 7, label: "7 Months" },
    { value: 8, label: "8 Months" },
    { value: 9, label: "9 Months" },
    { value: 10, label: "10 Months" },
    { value: 11, label: "11 Months" },
  ];
  const YearOptions = [
    { value: 0, label: "0 Years" },
    { value: 1, label: "1 Years" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
    { value: 8, label: "8 Years" },
    { value: 9, label: "9 Years" },
    { value: 10, label: "10 Years" },
    { value: 11, label: "11 Years" },
  ];

  const removeImg = () => {};

  let a = warrantyDetails.validupto_month;
  return (
    <div className="col-12">
      <form className="w-100" id="add-service" onSubmit={formSubmit}>
        {/* Service Image */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend d-flex align-items-center">
            <span className="input-group-text">
              <i class="fal fa-images"></i>Photo
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="small-block">
            {!editMode && addImageSubmit
              ? pic &&
                pic.map((photo, index) => {
                  return (
                    <div className="small-img ko" key={index}>
                      {" "}
                      <img
                        // src="../../../../../../server/src/public/{photo.image_url}"
                        src={photo.base64}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />{" "}
                    </div>
                  );
                })
              : editMode
              ? pic &&
                pic.map((photo, index) => {
                  return (
                    <div className="small-img ko img-list" key={index}>
                      {" "}
                      <button
                        type="button"
                        className="btn"
                        onClick={() => setPic(pic.filter((e) => e !== photo))}
                      >
                        <i class="fal fa-times"></i>
                      </button>
                      <img
                        src={photo.base64}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />{" "}
                    </div>
                  );
                })
              : ""}

            {serviceImageData &&
              serviceImageData.map((photo, index) => {
                return (
                  <div
                    className="img-list"
                    key={index}
                    service-image-id={photo.service_image_id}
                  >
                    {editMode ? (
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          setServiceImages(
                            serviceImageData.filter(
                              (e) =>
                                e.service_image_id != photo.service_image_id
                            )
                          )
                        }
                      >
                        <i class="fal fa-times"></i>
                      </button>
                    ) : (
                      ""
                    )}
                    <img
                      src={`${configData.SERVER_URL}${photo.image_url}`}
                      className="img-fluid"
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                );
              })}
            {!viewReadOnly && (
              <button
                type="button"
                className="btn-image"
                onClick={props.handleShow}
              >
                <div className="upload-section justify-content-center">
                  <div className="upload-img">
                    <img src={uploadImageFile} alt="loading..." />
                  </div>
                </div>
                Upload
              </button>
            )}
          </div>
        </div>
        {/* Serice Name */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              readonly
              onKeyPress={handleKeyPress}
              className="form-control"
              placeholder=""
              aria-label="Username"
              aria-describedby="basic-addon1"
              readOnly={viewReadOnly}
              value={formData.service_name}
              onChange={(e) =>
                SetFormData(
                  { ...formData, service_name: e.target.value },
                  SetFormChange(true)
                )
              }
              onBlur={(e) => onChangeValidate("service_name")}
            />
            {error && formErrors.service_name ? (
              <span className="form-error">{formErrors.service_name}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Vehichle Type */}
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-car"></i>Vehicle Type
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <Select
              className={`form-select p-0 ${
                viewReadOnly
                  ? "disable-select"
                  : [-1, 2].includes(mappedServiceData.status) && editMode
                  ? "disable-select"
                  : ""
              }`}
              isDisabled={
                viewReadOnly
                  ? true
                  : [-1, 2].includes(mappedServiceData.status) && editMode
                  ? true
                  : false
              }
              id="warranty_period"
              options={vehicleOptions}
              value={vehicleOptions.find(
                (x) => x.value === formData.vehicle_select
              )}
              onChange={(e) => {
                const value = parseInt(e.value);
                saveVehicleVariants(value);
                SetFormData({ ...formData, vehicle_select: e.value });
              }}
            />
            {/* <Select
              className={`form-select p-0 ${editMode && "edit-vehicle-type"}`}
              id="warranty_period"
              isDisabled={viewReadOnly ? true : false}
              value={vehicleOptions.find((x) => x.value == formData.vehicle_select)}
              options={vehicleOptions}
              onChange={(e) => {
                const value = parseInt(e.value);
                saveVehicleVariants(value);
                SetFormData({ ...formData, vehicle_select: value });
              }}
              onBlur={(e) => onChangeValidate("vehicle_select")}
            /> */}
            {error && formErrors.vehicle_select ? (
              <span className="form-error">{formErrors.vehicle_select}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Service Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Description
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <textarea
              className="form-control"
              readOnly={viewReadOnly}
              value={formData.service_description}
              onChange={(e) =>
                SetFormData({
                  ...formData,
                  service_description: e.target.value,
                })
              }
              onBlur={(e) => onChangeValidate("service_description")}
            ></textarea>
            {error && formErrors.service_description ? (
              <span className="form-error">
                {formErrors.service_description}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Service Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Service Tags
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <div className="tags-input-container w-100">
              {servicetags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{tag}</span>
                  {viewReadOnly ? null : (
                    <span
                      onClick={() => removeServiceTags(index)}
                      className="close"
                    >
                      &times;
                    </span>
                  )}
                </div>
              ))}
              <input
                onKeyDown={addServiceTags}
                readOnly={viewReadOnly}
                type="text"
                className="tags-input"
                // placeholder="Eg. Car Wash..."
                placeholder={
                  viewReadOnly
                    ? ""
                    : !servicetags.length
                    ? "Eg. Car Wash..."
                    : ""
                }
                onBlur={(e) => onChangeValidate("servicetags")}
              />
            </div>
            {error && formErrors.servicetags ? (
              <span className="form-error">{formErrors.servicetags}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Product Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-boxes"></i>Products Description
            </span>
          </div>
          <div className="input-block">
            <textarea
              className="form-control"
              value={formData.product_description}
              readOnly={viewReadOnly}
              onChange={(e) =>
                SetFormData({
                  ...formData,
                  product_description: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
        {/* Product Used Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Products Used
            </span>
          </div>
          <div className="input-block">
            <div className="tags-input-container w-100">
              {producttags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{tag}</span>
                  {viewReadOnly ? null : (
                    <span
                      className="close"
                      onClick={() => removeProductTags(index)}
                    >
                      &times;
                    </span>
                  )}
                </div>
              ))}
              <input
                readOnly={viewReadOnly}
                onKeyDown={addProductTags}
                type="text"
                className="tags-input"
                placeholder={
                  viewReadOnly
                    ? ""
                    : !producttags.length
                    ? "Eg. Detailing Products..."
                    : ""
                }
              />
            </div>
          </div>
        </div>
        {/* Warranty Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Warranty Details</span>
          </h6>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-certificate"></i>Warranty Description
            </span>
          </div>
          <div className="input-block">
            <textarea
              className="form-control"
              value={warrantyDetails.warranty_description}
              readOnly={viewReadOnly}
              onChange={(e) =>
                setWarrantyDetails({
                  ...warrantyDetails,
                  warranty_description: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Valid Upto
            </span>
          </div>
          <div className="form-width">
            <div className="row m-custom">
              <div className="col-md-6">
                <div className="input-block w-100">
                  <Select
                    className={`form-select p-0 ${
                      viewReadOnly && "disable-select"
                    }`}
                    id="warranty_period"
                    placeholder="Select..."
                    isDisabled={viewReadOnly ? true : false}
                    value={MonthOptions.find(
                      (x) => x.value == warrantyDetails.validupto_month
                    )}
                    options={MonthOptions}
                    onChange={(e) => {
                      setWarrantyDetails({
                        ...warrantyDetails,
                        validupto_month: parseInt(e.value),
                      });
                    }}
                  />
                  {/* <Select
                    placeholder={
                      !viewReadOnly && warrantyDetails.validupto_month
                        ? MonthOptions.find(
                          (x) => x.value == warrantyDetails.validupto_month
                        )
                        : ""
                    }
                    options={MonthOptions}
                    isDisabled={viewReadOnly ? true : false}
                    value={MonthOptions.find(
                      (x) => x.value == warrantyDetails.validupto_month
                    )}
                    onChange={(e) => {
                      setWarrantyDetails({
                        ...warrantyDetails,
                        validupto_month: parseInt(e.value),
                      });
                    }}
                  /> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-0">
                  <div className="input-block w-100">
                    <Select
                      className={`form-select p-0 ${
                        viewReadOnly && "disable-select"
                      }`}
                      options={YearOptions}
                      placeholder={
                        !viewReadOnly && YearOptions.validupto_year
                          ? YearOptions.find(
                              (x) => x.value == warrantyDetails.validupto_year
                            )
                          : ""
                      }
                      isDisabled={viewReadOnly ? true : false}
                      value={YearOptions.find(
                        (x) => x.value == warrantyDetails.validupto_year
                      )}
                      onChange={(e) => {
                        setWarrantyDetails({
                          ...warrantyDetails,
                          validupto_year: parseInt(e.value),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Price Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Price Details</span>
          </h6>
        </div>
        <div className="input-block">
          {error && formErrors.isPrice ? (
            <span className="form-error">{formErrors.isPrice}</span>
          ) : (
            ""
          )}
        </div>
        {/* Normal Price */}
        {viewReadOnly ? null : (
          <div className="radio-title">
            <input
              type="radio"
              value="Normal Price"
              name="isPrice"
              disabled={
                !viewReadOnly && !editMode && formData.vehicle_select == ""
                  ? true
                  : false
              }
              // onClick={(e) => setIsPrice(e.target.value)}
              onChange={(e) => {
                setIsPrice(e.target.value);
                e.target.checked = true;
              }}
              checked={isPrice === "Common Price" ? false : true}
              onBlur={(e) => onChangeValidate("isPrice")}
            />
            Set Price with respect to Variant
          </div>
        )}
        {
          // (!viewReadOnly || editMode ) &&
          <div
            className={` ${
              viewReadOnly
                ? "price-change view-only"
                : isPrice === "Common Price"
                ? "price-fade"
                : "price-change"
            }`}
          >
            {formData.vehicle_select &&
              priceDetails.map((item, i) => {
                const hideField =
                  viewReadOnly && (isNaN(item.price) || !Number(item.price));
                return (
                  <div
                    className={`input-group input-group-custom${
                      hideField ? " d-none" : ""
                    }`}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        {variantIcon(formData.vehicle_select)}
                        {item.variant_name}
                      </span>
                    </div>
                    <input
                      type="text"
                      readonly
                      className="form-control"
                      placeholder="Price"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.price ? item.price : ""}
                      readOnly={viewReadOnly}
                      onChange={(e) => {
                        addVariantPriceDetails(
                          i,
                          item.variant_id_pk,
                          parseInt(e.target.value)
                        );
                      }}
                      onBlur={(e) => onChangeValidate("priceDetails")}
                    />
                    {error && formErrors.priceDetails ? (
                      <span className="form-error">
                        {formErrors.priceDetails}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            {/* Add variants */}

            <div>
              {inputlist.map((x, i) => {
                return (
                  <Fragment>
                    <div className="col-12 p-0">
                      <div className="input-list-row">
                        <div className="col-main-list">
                          <div className="form-group col-list-row">
                            <input
                              name="variant_name"
                              value={x.variant_name}
                              readOnly={viewReadOnly}
                              type="text"
                              className="form-control"
                              placeholder="Variant Name"
                              onChange={(e) => handleinputchange(e, i)}
                            />
                          </div>
                          <div className="form-group col-md-6 mb-0">
                            <input
                              name="price"
                              type="text"
                              value={x.price}
                              readOnly={viewReadOnly}
                              placeholder="Price"
                              className="form-control"
                              onChange={(e) => handleinputchange(e, i)}
                            />
                          </div>
                          {mappedServiceData.status != 0 && x.status === -1 ? (
                            <div className="variant-action d-flex align-center">
                              <p
                                className="text-danger mr-1"
                                style={{ fontSize: "14px" }}
                              >
                                Rejected{" "}
                              </p>
                              <span
                                className="text-danger"
                                data-for="info"
                                data-tip={x.rejected_reason}
                              >
                                <i className="fal fa-info-circle"></i>
                              </span>
                              <ReactTooltip
                                id="info"
                                place="bottom"
                                type="info"
                                effect="float"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {viewReadOnly ? null : (
                          <div className="button-remove">
                            {inputlist.length && (
                              <span onClick={() => handleremove(i)}>
                                <span>
                                  <i class="fal fa-times"></i>
                                </span>
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>

            {viewReadOnly ? null : (
              <div class="form-group button-add mt-3 text-right">
                <button type="button" className="btn" onClick={handleaddclick}>
                  <i className="fal fa-plus"></i> Add Variants
                </button>
              </div>
            )}
          </div>
        }

        {/* Common Price */}
        {mappedServiceData.common_price == undefined && viewReadOnly ? (
          " "
        ) : (
          <div className="row common-row m-0">
            {viewReadOnly ? null : (
              <div className="radio-title">
                <input
                  readOnly={viewReadOnly}
                  disabled={
                    !viewReadOnly && !editMode && formData.vehicle_select == ""
                      ? true
                      : false
                  }
                  type="radio"
                  value="Common Price"
                  name="isPrice"
                  // onClick={(e) => setIsPrice(e.target.value)}
                  onChange={(e) => {
                    setIsPrice(e.target.value);
                    e.target.checked = true;
                  }}
                  checked={isPrice === "Common Price" ? true : false}
                  onBlur={(e) => onChangeValidate("isPrice")}
                />
                Set Common Price
              </div>
            )}
            <div
              className={`input-group input-group-custom              
            ${
              viewReadOnly
                ? " price-change view-only"
                : isPrice === "Common Price"
                ? " price-change"
                : " price-fade"
            }
            `}
            >
              {viewReadOnly && (
                <div className="input-group-prepend">
                  <span class="input-group-text">Common Price</span>
                </div>
              )}
              <input
                type="text"
                readonly
                className="form-control"
                placeholder=" Common Price"
                aria-label="Username"
                aria-describedby="basic-addon1"
                readOnly={viewReadOnly}
                value={formData.common_price}
                onChange={(e) =>
                  SetFormData({
                    ...formData,
                    common_price: parseInt(e.target.value)
                      ? parseInt(e.target.value)
                      : "",
                  })
                }
                onBlur={(e) => onChangeValidate("common_price")}
              />
            </div>
            {error && formErrors.common_price ? (
              <span
                className="input-block form-error"
                style={{
                  fontSize: "13px",
                  color: "#f44336",
                  padding: "0",
                }}
              >
                {formErrors.common_price}
              </span>
            ) : (
              ""
            )}
          </div>
        )}

        {viewReadOnly && mappedServiceData.status === -1 ? (
          <Fragment>
            <hr />
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-info-circle text-danger"></i>Reason For
                  Delete
                  <sup className="theme-text">*</sup>
                </span>
              </div>
              <textarea
                className="form-control"
                readOnly="true"
                value={mappedServiceData.rejected_reason}
              ></textarea>
            </div>
          </Fragment>
        ) : (
          ""
        )}
        {viewReadOnly ? null : (
          <div className="row p-0 mt-5">
            <div className="col-6 text-left">
              <button className="btn btn-update save-draft" type="submit">
                <i class="fal fa-save mr-2"></i>
                {/* {editMode ? "Update Draft" : "Save Draft"} */}
                {mappedServiceData.status === 0 ? "Update Draft" : "Save Draft"}
              </button>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-update"
                onClick={() => SetReview(!review)}
                type="submit"
              >
                Send For Review
              </button>
            </div>
          </div>
        )}
        {/* Service Image Modal */}
        <ServiceFileUploadModal
          setShow={setShow}
          showfilemodal={show}
          hidefilemodal={handleClose}
        />
      </form>
    </div>
  );
}

export default ServiceForm;

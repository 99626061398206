import React, { useState, useContext } from "react";
import { CustomerContext } from "../CustomerContext";
import ReactTooltip from "react-tooltip-rc";
const CustomerContentTitle = (props) => {
  return (
    <div className="content-title-action">
      <div className="title-action">
        <div className="title-action-left">
          <h5 className="head">Customers</h5>
        </div>
        {/* <div class="title-action-right d-flex">
          <button
            className="btn "
            id="addBtn"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="left"
            // title="Add New"
          >
            <span>
              <i className="fal fa-list mr-1"></i>ALL CUSTOMERS
            </span>
          </button>
        </div> */}
        <div className="search-sort-filter">
          <div className="header-action-block">
            <form action="" method="">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text search-span-txt">
                    <i className="fal fa-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="search-id"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerContentTitle;

import React, { useState, createContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import dateFormat, { masks } from "dateformat";
import {
  createStaff,
  getStaffList,
  deleteStaff,
} from "../../../../api/services/sap/staff-management";
import {
  getBookingsList,
  viewMappedBooking,
} from "../../../../api/services/sap/bookings-management";

export const OrdersContext = createContext();
export const OrdersProvider = (props) => {

  let preInitial = {
    description: "",
    index: 0,
    inspection_image: "",
    error: "empty"
  };

  const [loader, setLoader] = useState(true);
  const [tableKanban, setTableKanban] = useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [mappedOrderData, setMappedOrderData] = useState([]);
  const [mainOderData, setmainOderData] = useState([]);
  const [viewInnerOrder, setViewInnerOrder] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [mappedId, setMappedId] = useState("");
  //SERVICE TIME START,PAUSE,RESUME,FINISHED
  const [startTime, setStartTime] = useState([]);
  const [pauseTime, setPauseTime] = useState([]);
  const [resumeTime, setResumeTime] = useState([]);
  const [finishedTime, setFinishedTime] = useState([]);

  const [innertab, SetinnerTab] = useState(1);
  const [tabChange, setTabChange] = useState(true);
  //preServiceForm state
  const [preFormData, setPreFormDaTA] = useState([]);
  // const [viewonly, setViewOnly] = useState(false);

  //postServiceForm state
  const [postFormData, setPostFormData] = useState([]);
  //  const [viewonly, setViewOnly] = useState(false);

  //preservice pic
  const [preservicepic, setPreServicePic] = useState([]);
  const [addonImages, setAddonImages] = useState([]);
  const [addImageSubmit, setAddImageSubmit] = useState(false);

  //postService pic
  const [postservicepic, setPostServicePic] = useState([]);
  const [postaddonImages, setPostAddonImages] = useState([]);
  const [postAddImageSubmit, setPostAddImageSubmit] = useState(false);

  //pre pic Modal State
  const [preModal, setPreModal] = useState(false);
  const handleClose = () => setPreModal(false);
  const handleShow = () => setPreModal(true);

  //post pic Modal State
  const [postModal, setPostModal] = useState(false);
  const handleClosePostModal = () => setPostModal(false);
  const handleShowPostModal = () => setPostModal(true);

  const [staffTable, setStaffTable] = useState([]);
  const [orderTable, setOrderTable] = useState([]);
  //pst order data
  const [pastOrders, setPastOrders] = useState([])

  //innertab view-only-mode states --------------------|
  const [previewonly, setPreViewOnly] = useState(true);
  const [serviceviewonly, setServiceViewOnly] = useState(true);
  const [postviewonly, setPostViewOnly] = useState(true);
  const [paymentviewonly, setPaymentViewOnly] = useState(true);
  const [feedbacktviewonly, setFeedbackViewOnly] = useState(true);
  const [pastviewonly, setPastViewOnly] = useState(true);
  //Preform state
  const [preformvalues, setPreFormValues] = useState([]);
  const [preFormWindow, setPreFormWindow] = useState([]);

  const viewHandler = (item) => {
    const viewBookingData = getBookingsList(null, "orders", item);
    viewBookingData.then((response) => {
      setMappedOrderData(response);
      setViewInnerOrder(true);
    });
  };

  const getBookingsFn = () => {
    const getBookingsData = getBookingsList(null, "orders");
    getBookingsData &&
      getBookingsData.then((response) => {
        const orderList =
          response &&
          response.map((item) => ({
            ...item,
          }));
        setOrderTable(orderList);
      });
  };

  useEffect(() => {
    getBookingsFn();
  }, []);

  const gorupedList = (orderTable) => {
    let kanbanData = [
      {
        id: "kanbanBoard1",
        title: "Open",
        tasks: [],
      },
      {
        id: "kanbanBoard2",
        title: "In Progress",
        tasks: [],
      },
      {
        id: "kanbanBoard3",
        title: "Pending",
        tasks: [],
      },
      {
        id: "kanbanBoard4",
        title: "Completed",
        tasks: [],
      },
      {
        id: "kanbanBoard5",
        title: "Ready for Delivery",
        tasks: [],
      },
      {
        id: "kanbanBoard6",
        title: "Delivered",
        tasks: [],
      },
    ];
    orderTable.forEach((task, indx) => {
      let statusGroupIndx = kanbanData.findIndex(
        (sGroup, ind) => sGroup.title == task.orderStatus.title
      );
      if (statusGroupIndx == -1) {
        let statusGroup = {
          id: uuidv4(),
          title: task.orderStatus.title,
          tasks: [Object.assign({}, task)],
        };
        kanbanData.push(statusGroup);
      } else {
        kanbanData[statusGroupIndx].tasks.push(task);
      }
    });
    return kanbanData;
  };

  const handleOrderDelete = (objectId) => {
    let newList = orderTable.filter((item) => item.order_id !== objectId);
    setOrderTable(newList);
  };

  //Toggle Tabs Function
  const toggleInnerTab = (index) => {
    SetinnerTab(index);
    if (index === 1) {
      setTabChange(true);
    } else if (index === 2) {
      setTabChange(false);
      setPreViewOnly(true);
    } else if (index === 3) {
      setTabChange(false);
      setPreViewOnly(true);
    } else if (index === 4) {
      setTabChange(false);
      setPreViewOnly(true);
    } else if (index === 5) {
      setTabChange(false);
      setPreViewOnly(true);
    } else if (index === 6) {
      setTabChange(false);
      setPreViewOnly(true);
    }
  };

  let initialValues = {
    deleteId,
    setDeleteId,
    orderTable,
    setOrderTable,
    handleOrderDelete,
    gorupedList,
    tableKanban,
    setTableKanban,
    mappedOrderData,
    setMappedOrderData,
    viewInnerOrder,
    setViewInnerOrder,
    filterValue,
    setFilterValue,
    innertab,
    SetinnerTab,
    tabChange,
    setTabChange,
    preservicepic,
    setPreServicePic,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    preModal,
    setPreModal,
    handleClose,
    handleShow,
    preFormData,
    setPreFormDaTA,
    toggleInnerTab,
    mappedId,
    setMappedId,
    startTime,
    setStartTime,
    pauseTime,
    setPauseTime,
    resumeTime,
    setResumeTime,
    finishedTime,
    setFinishedTime,
    postFormData,
    setPostFormData,
    postservicepic,
    setPostServicePic,
    postaddonImages,
    setPostAddonImages,
    postAddImageSubmit,
    setPostAddImageSubmit,
    postModal,
    setPostModal,
    handleClosePostModal,
    handleShowPostModal,
    mainOderData,
    setmainOderData,
    staffTable,
    setStaffTable,
    loader,
    setLoader,
    viewHandler,
    getBookingsFn,
    previewonly,
    setPreViewOnly,
    serviceviewonly,
    setServiceViewOnly,
    postviewonly,
    setPostViewOnly,
    paymentviewonly,
    setPaymentViewOnly,
    feedbacktviewonly,
    setFeedbackViewOnly,
    pastviewonly,
    setPastViewOnly,
    pastOrders, setPastOrders,
    preFormWindow, setPreFormWindow,
    preInitial, preformvalues, setPreFormValues
  };

  useEffect(() => {
    const data = getStaffList();
    data.then((res) => {
      let tableData = res.map((item, i) => ({
        id: item.staff_id_pk,
        name: item.name,
        email: item.email,
        phone: item.phone,
        designation: item.designation,
      }));
      setStaffTable([...tableData]);
    });
  }, []);

  return (
    <OrdersContext.Provider value={initialValues}>
      {props.children}
    </OrdersContext.Provider>
  );
};

import React, { useState, useContext, useEffect, Fragment } from "react";
import { StaffContext } from "./StaffContext";
import StaffDetailView from "./Ui/StaffDetailView";
import StaffFileUploadModal from "./StaffFileUploadModal";
import AddStaffForm from "./AddStaffForm";
import EditStaffForm from "./EditStaffForm";
import EditStaffFileUpload from "./EditStaffFileUpload";
import { Scrollbars } from "react-custom-scrollbars-2";
import StaffDeleteModal from "./StaffDeleteModal";
import AddCancelConfirmModal from "./Ui/AddCancelConfirmModal";
import SatffIdModal from "./SatffIdModal";
import RiseLoader from "react-spinners/RiseLoader";
import EditStaffIdModal from "./EditStaffIdModal";
import ReactTooltip from "react-tooltip-rc";

import mainLoader from "../../../../assets/img/loader/loader.gif";

const StaffWindowRight = (props) => {
  //Staff Context
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    id,
    idproof,
    setIdProof,
    mappedStaff,
    setMappedStaff,
    handleStaffEdit,
    deletemodal,
    SetDeleteModal,
    handleDeleteClick,
    addonImages,
    setAddonImages,
    formChange,
    SetFormChange,
    loader,
    SetLoader,
    addonId,
    setAddonId,
    viewStaff,
  } = useContext(StaffContext);

  console.log(mappedStaff, "mappedStaff------------------------");

  //Add cancel Confirmation modal
  const [cancelConfirmModal, SetCancelConfirmModal] = useState(false);

  //Image Modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  //Id Upload  Modal
  const [showId, setShowId] = useState(false);
  const handleShowID = () => setShowId(true);
  const handleCloseID = () => setShowId(false);

  //Edit pic Modal
  const [piceditModal, setPicEditModal] = useState(false);
  const handleShowEditFile = () => {
    setPicEditModal(true);
    // const copyAddonImages = [mappedStaff["profile_image"]];
    // setAddonImages(copyAddonImages);
  };
  const handleCloseEditFile = () => setPicEditModal(false);

  //Edit Id proof Modal
  const [idEditModal, SetIdEditModal] = useState(false);
  const handleShowEditId = () => {
    SetIdEditModal(true);
    // const copyAddonId = [...mappedStaff["idproof"]];
    // setAddonId(copyAddonId);
  };
  const handleCloseEditId = () => SetIdEditModal(false);

  const closeAddStaffForm = (e) => {
    if (formChange === false) {
      console.log(formChange);
      SetCancelConfirmModal(false);
      props.CloseAddStaff(false);
      SetFormChange(false);

      // props.closeViewStaff(false);
      setMappedStaff({});
    } else if (formChange === true) {
      console.log(formChange, "true");
      SetCancelConfirmModal(true);
      e.stopPropagation();
    }

    // props.CloseAddStaff(false);
    // props.closeViewStaff(false);
    // setMappedStaff({});
    // setPic([]);
  };
  const closeconfirm = () => SetCancelConfirmModal(false);
  const confirmCancelModal = () => {
    if (props.addstaff) {
      SetCancelConfirmModal(false);
      props.CloseAddStaff(false);
      SetFormChange(false);
      props.closeStaffEdit(false);
    } else if (props.editstaff) {
      SetCancelConfirmModal(false);
      props.closeStaffEdit(false);
      SetFormChange(false);
      props.CloseAddStaff(false);
    }
  };
  //Close Edit view
  const closeEditStaff = (e) => {
    const allTableRows = document.querySelectorAll(".rdt_TableRow");
    allTableRows.forEach((rowElement) => {
      rowElement.classList.remove("current-selected-row");
    });
    if (formChange === true) {
      //TRUE
      console.log(formChange, "is true");
      SetCancelConfirmModal(true);
      console.log(cancelConfirmModal);
      e.stopPropagation();
    } else if (formChange === false) {
      //FALSE
      SetCancelConfirmModal(false);
      props.closeStaffEdit(false);
      // setMappedStaff({});
    }
  };
  // console.log(staffTable);

  //Use Effect Loader
  useEffect(() => {
    console.log(mappedStaff, "......................wright mappedStaff");
    SetLoader(true);
    const loaderTime = setTimeout(() => {
      SetLoader(false);
    }, 1000);
    return () => {
      clearTimeout(loaderTime);
    };
  }, [props.addstaff, props.editstaff, props.viewStaff, mappedStaff]);

  // console.log(mappedStaff, "mappedStaff-first");
  return (
    <Fragment>
      {props.addstaff && loader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} />
        </div>
      ) : props.addstaff ? (
        <div className="content-window-right">
          <div className="content-right-block">
            <div className="card-right">
              <div className="header-block">
                <div className="header-sec">
                  <div className="content-right-top">
                    <button
                      title="Close Window"
                      onClick={closeAddStaffForm}
                      class="btn btn-action btn-back"
                      id="backBtn"
                      rel="tooltip"
                      data-for="backTooltip"
                      data-tip="Back to listing"
                    >
                      <i class="fal fa-long-arrow-left"></i>
                    </button>
                    <ReactTooltip
                      id="backTooltip"
                      place="left"
                      type="info"
                      effect="float"
                    />
                    <h4>{"Add Staff"}</h4>
                    {/* <h4>{"Add Staff"}</h4> */}
                    {/* <h2 className="mb-0">{editMode ? "Edit" : 
                  viewReadOnly
                   ? "view" : "Add"}</h2> */}
                  </div>
                  <div className="action-block-right">
                    {props.viewStaff && (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        onClick={props.handleEditView}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                      >
                        <i className="fal fa-pen"> </i>
                      </button>
                    )}
                    {/* <button
                        className="btn btn-action btn-publish"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Publish services so that customers can view"
                        >
                      <i className="fal fa-arrow-to-top"></i>
                      </button> */}

                    {/* <button
                        className="btn btn-action btn-delete"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                        >
                      <i className="fal fa-trash"></i>
                      </button> */}
                    {/* <button
                      className="btn btn-action btn-close"
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Close Window"
                      onClick={closeAddStaff}
                    >
                      <i className="fal fa-times"></i>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
              <div className="row m-0 row-right">
                {/* Staff Form Start */}
                <AddStaffForm
                  addstaff={props.addstaff}
                  handleShow={handleShow}
                  closeform={props.CloseAddStaff}
                  handleShowID={handleShowID}
                />
                <StaffFileUploadModal
                  setShow={setShow}
                  showfilemodal={show}
                  hidefilemodal={handleClose}
                />
                <SatffIdModal
                  setShowId={setShowId}
                  showId={showId}
                  handleCloseID={handleCloseID}
                />
                {/* Staff Form End */}
              </div>
            </Scrollbars>
          </div>
        </div>
      ) : (
        ""
      )}
      {props.editstaff && loader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} />
        </div>
      ) : props.editstaff ? (
        <div className="content-window-right">
          <div className="content-right-block">
            <div className="card-right">
              <div className="header-block">
                <div className="header-sec">
                  <div className="content-right-top">
                    <button
                      title="Close Window"
                      onClick={closeEditStaff}
                      class="btn btn-action btn-back"
                      rel="tooltip"
                      data-for="backTooltip"
                      data-tip="Back to listing"
                    >
                      <i class="fal fa-long-arrow-left"></i>
                    </button>
                    <ReactTooltip
                      id="backTooltip"
                      place="left"
                      type="info"
                      effect="float"
                    />
                    {/* <h4>
                      {props.editstaff
                        ? `${mappedStaff.name}`
                        : "Edit Staff"}
                    </h4> */}
                    <h4>{props.editstaff ? `Edit ${mappedStaff.name}` : ""}</h4>
                    {/* <h2 className="mb-0">{editMode ? "Edit" : 
                  viewReadOnly
                   ? "view" : "Add"}</h2> */}
                  </div>
                  {/* <div className="action-block-right">
                    <button
                      className="btn btn-action btn-close"
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Close Window"
                      onClick={closeEditStaff}
                    >
                      <i className="fal fa-times"></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
              <div className="row m-0 row-right">
                {/* Staff Form Start */}
                <EditStaffForm
                  closeStaffEdit={props.closeStaffEdit}
                  handleShowEditFile={handleShowEditFile}
                  handleShowEditId={handleShowEditId}
                  editstaff={props.editstaff}
                />
                <EditStaffFileUpload
                  setPicEditModal={setPicEditModal}
                  showEditfilemodal={piceditModal}
                  handleCloseEditFile={handleCloseEditFile}
                />
                <EditStaffIdModal
                  SetIdEditModal={SetIdEditModal}
                  showEditIdModal={idEditModal}
                  handleCloseEditId={handleCloseEditId}
                />
                {/* Staff Form End */}
              </div>
            </Scrollbars>
          </div>
        </div>
      ) : (
        ""
      )}
      {props.viewStaff && loader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} />
        </div>
      ) : props.viewStaff ? (
        <StaffDetailView
          // handleStaffEdit={handleStaffEdit}
          viewStaff={props.viewStaff}
          addstaff={props.addstaff}
          closeViewStaff={props.closeViewStaff}
          CloseAddStaff={props.CloseAddStaff}
          handleEditView={props.openStaffEdit}
          closeStaffEdit={props.closeEditStaff}
          handleDeleteClick={handleDeleteClick}
        />
      ) : null}
      {cancelConfirmModal && (
        <AddCancelConfirmModal
          cancelConfirmModal={cancelConfirmModal}
          closeconfirm={closeconfirm}
          SetCancelConfirmModal={SetCancelConfirmModal}
          // id={mappedStaff.id}
          closeAddStaffForm={closeAddStaffForm}
          confirmCancelModal={confirmCancelModal}
          addstaff={props.addstaff}
          editstaff={props.editstaff}
        />
      )}
    </Fragment>
  );
};

export default StaffWindowRight;

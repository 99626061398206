// import {ContentHeader,FilterWrapper,ContentTitle,WindowLeft,WindowRight,InnerLoader} from './Ui';
import Layout from "../../Layout/Layout";
// import BookingsContentHeader from "./Ui/BookingsContentHeader";
// import BookingsContentTitle from "./Ui/BookingsContentTitle";
// import BookingsWindowLeft from "./BookingsWindowLeft";
// import BookingsWindowRight from "./BookingsWindowRight";
// import BookingsInnerLoader from "./Ui/BookingsInnerLoader";
import React, { useState } from "react";
// import { BookingsProvider } from "./BookingsContext";
import ContentHeader from "../../Layout/ContentHeader";
import VendorContentTitle from "./ui/VendorContentTitle";
import WindowLeft from "./WindowLeft";
import WindowRight from "./WindowRight";

const VendorSup = () => {
  const [addVendor, SetAddVendor] = useState(false);
  const [viewVendor, SetViewVendor] = useState(false);
  const [rightView, SetRightView] = useState(false);
  const handleAddVendorsClick = () => {
    handleViewRight();
    SetAddVendor(!addVendor);
    SetViewVendor(false);
  };
  const handleViewRight = () => SetRightView(true);
  const handleCloseRight = () => SetRightView(false);
  let toggleClassCheck = rightView ? " change" : "";

  const handleViewVendor = () => {
    handleViewRight();
    SetViewVendor(true);
  };

  return (
    <Layout>
      {/* <BookingsProvider> */}
      <div className="content-block">
        {/* <BookingsInnerLoader /> */}
        <section className="main-section">
          <div className="container-fluid p-0">
            <ContentHeader />
            <div className="col-12 p-0 page-window">
              <VendorContentTitle handleAddClick={handleAddVendorsClick} />
              <div className="content-section" view-content="service">
                <div className={`content-window ${toggleClassCheck} `}>
                  <WindowLeft viewVendorClick={handleViewVendor} />
                  {/* <BookingsWindowLeft
                      handleAddBookings={handleAddBookings}
                      bookingformClose={SetAddBookings}
                    /> */}
                  {rightView && (
                    <WindowRight
                      closeView={handleCloseRight}
                      addVendor={addVendor}
                      viewVendor={viewVendor}
                      // bookingformClose={SetAddBookings}
                      // addBookings={addBookings}
                      // handleAddBookings={handleAddBookings}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* </BookingsProvider> */}
    </Layout>
  );
};

export default VendorSup;

import React, { useState, useContext, useEffect, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { toast } from "react-toastify";
import { ApprovalContext } from "./ApprovalContext";
import ApprovalForm from "./ApprovalForm";
import { approveOrRejectService, getSimilarServicesAPI } from "../../../../api/services/sup/service-management";
import RejectModal from "./Ui/RejectModal";
import Dropdown from 'react-bootstrap/Dropdown';
import Suggestions from "./Ui/Suggestions";
import "../../Pages/Approval/Ui/css/Approval.css"
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import ConfirmModal from "./Ui/ConfirmModal";
import ReactTooltip from "react-tooltip-rc";


const WindowRight = (props) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [newVariantRequest, setNewVariantRequest] = useState(false);
  const [similarServices, setSimilarServices] = useState([]);
  const [index, setIndex] = useState(0);

  const {
    table,
    SetTable,
    SetViewRead,
    viewReadOnly,
    setIsUpdateForm,
    SetViewReadOnly,
    SetEditMode,
    editMode,
    SetMappedServiceData,
    mappedServiceData,
    formChange,
    SetFormChange,
    isSelected,
    setIsSelected,
    addservice,
    isUpdateForm
  } = useContext(ApprovalContext);

  useEffect(()=>{
     props.SetIsTableView(false);
     getSimiliarServices();
    },[mappedServiceData])

    useEffect(()=>{
      props.setViewPending(false);
    })

  const getSimiliarServices= () =>{

   let similarService = [];
    // e.preventDefault();
    const req = getSimilarServicesAPI({
      id: mappedServiceData.service_id_pk,
    });
    req.then((res) => {
        console.log(res.data,"similar services");
        similarService = res.data;
        setSimilarServices(similarService);
        console.log(similarService);
    }).catch((e) => {
      console.log(e,"error");
    });
  
    
   console.log(similarServices);   
  }


  const openConfirmModal=(e)=>{
    if(newVariantRequest){
      setConfirmModal(true);
    }else{
      approveService(e);
    }
  }

  const handleRejectView=(e)=>{
   setRejectModal(true);
   e.stopPropagation();
  }
  const handleCloseView = (e) => {
    if (formChange === false) {
      console.log("false")
      props.ApprovalFormClose(false);
      SetViewReadOnly(false);
      SetEditMode(false);
      setIsUpdateForm(false);
      SetMappedServiceData({});
      props.SetIsTableView(true);
    } else if (formChange === true) {
      console.log("true")
      // SetConfirmModal(true);
      e.stopPropagation();
    }
  };
  const handleEditView = (e) => {
    SetViewReadOnly(false);
    SetEditMode(true);
    // SetMappedServiceData({});
  };

  const updateIndex=(isNext)=>{
    var currIndex = index;
    const lastIndex = Object.keys(similarServices).length - 1
    console.log(lastIndex);
    if(isNext){
      if(currIndex == lastIndex){
        setIndex(lastIndex);
      }else{
        setIndex(currIndex + 1);
      }
    }else{
      if(currIndex == 0){
        setIndex(0);
      }else{
        setIndex(currIndex - 1);
      }
    }

    console.log("this is index",index)
  }

  
  const approveService = (e) => {
    e.preventDefault();
    const req = approveOrRejectService({
      type: "approve",
      service_id_pk: mappedServiceData.service_id_pk,
    });
    req.then((res) => {
      let newTable = table.map(e => (e.id === res.data.service_id_pk) ? (e.status = res.data.status,e.is_active = res.data.is_active, e) : e);
      SetTable(newTable);
      handleCloseView();
      toast.success(res.message, {
        position: "bottom-right",
      });
    }).catch((e) => {
      toast.error('Error', {
        position: "bottom-right",
      });
    });
  }

  return (
    <div
      className={`content-window-right 
        ${viewReadOnly ? "view-window" : ""}
        ${editMode ? "edit-window" : ""}
        ${addservice ? "add-window" : ""}`}
    >
      {/* <div className={"content-window-right" + props.text}> */}
      <div className="content-right-block">
        <div className="card-right">
          <div className="header-block">
            <div className="header-sec">
              <div className="content-right-top">
                <button
                  onClick={handleCloseView}
                  className="btn btn-action btn-back"
                  rel="tooltip"
                  data-for="close" 
                  data-tip="Back to services"  
                >
                  <i className="fal fa-long-arrow-left"></i>
                </button>
                <ReactTooltip 
            id="close"
            place="bottom"
            type="info"
            effect="float" />
                <h4>
                   {mappedServiceData.service_name}
                </h4>
              </div>
              
              <div className="action-block-right d-flex align-items-center justify-content-center">
              {mappedServiceData.status === 1 && (
                <div className="suggestions-dropdown mr-2">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" disabled = {Object.keys(similarServices).length === 0 ? true : false}>
                    SEE SIMILAR SERVICES ({Object.keys(similarServices).length})
                  </Dropdown.Toggle>
  
                  <Dropdown.Menu>
                  <div className="surf-page mx-4">
                                 <button className="surf-icon" disabled={index == 0 ? true : false }  onClick={()=>{updateIndex(false)}}><i className="fas fa-chevron-left"></i></button>
                                 <span className= "page-number"> ({index + 1}/{Object.keys(similarServices).length}) </span>
                                 <button className="surf-icon" disabled={index == Object.keys(similarServices).length-1 ? true : false } onClick={()=>{updateIndex(true)}}><i className="fas fa-chevron-right"></i></button>
                             </div>
                    <Dropdown.Item disabled>
                      
    
                      <div className="suggestion-box">
                             {Object.keys(similarServices).length? 
                            (<Suggestions similarService={similarServices[index]} index={index} />
                              ) : (   
                            <div className="no-data-available">
                            <div className="no-data-img">
                                <img src={noDataImage} alt="" />
                                <h5>No Data Available</h5>
                            </div>
                            </div>
                            )}
                            </div>
                    
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
  
                </div>
              )}
              
                {mappedServiceData.status === 1 && (
                  <Fragment>  
                    <button
                      className="btn btn-success mr-1"
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Approve"
                      onClick={openConfirmModal}
                    >
                      Approve
                    </button>
                    <button
                    onClick={handleRejectView}
                      className="btn btn-danger"
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Disapprove"
                    >
                      Reject
                    </button>
                  </Fragment>
                )}
                {mappedServiceData.status === 2 && (
                  <Fragment>
                     <p className="badge bg-success p-2">Approved</p>
                  </Fragment>
                ) }
                {mappedServiceData.status === -1 && (
                  <Fragment>
                     <p className="badge bg-danger p-2">Rejected</p>
                  </Fragment>
                ) }
              </div>
            </div>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 140px)` }}>
          <div className="name-block">
            <div className="sub-block">
              <div className="block-name">
                {mappedServiceData.name && <h2>{mappedServiceData.name}</h2>}
              </div>
            </div>
          </div>
          <div className="row m-0 row-right">
            {/* <!-- add service form --> */}
            <ApprovalForm
              addservice={props.addservice}
              ApprovalFormClose={props.ApprovalFormClose}
              variantRequest={setNewVariantRequest}
            />
            {/* <!-- add service form end--> */}
          </div>
          {rejectModal && (
            <RejectModal
              setRejectModal={setRejectModal}
              id={mappedServiceData.service_id_pk}
              handleCloseView={handleCloseView}
            />
          )}
        </Scrollbars>
        {}
      </div>
      {confirmModal && <ConfirmModal approveService={approveService} setConfirmModal={setConfirmModal}/>}
    </div> 
  );
};

export default WindowRight;

import React from "react";
import styled from "styled-components";
import RoundedWrapperCard from "../../../../../components/RoundedWrapperCard";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { MuiStyledTextField } from "../../../../../components/MuiStyledTextField";
import { FileUploadButton } from "../../../../../components/FileUploadButton";
import PhoneNumberField from "../../../../../components/PhoneNumberField";
import { CardHeadingLabel } from "../../../../../components/label";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import AddIcon from "./assets/icons/AddIcon.svg";
import CoOwnerComponent from "./components/CoOwnerComponent";
import SimpleReactValidator from "simple-react-validator";

type Props = { onNextClick?: () => void };

const VendorDocumentation = ({ onNextClick }: Props) => {
  const [value, setValue] = React.useState("No");
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [bankDetails, setBankDetails] = React.useState({
    account_holdername: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankDetails({ ...bankDetails, account_holdername: event.target.value });
  };
  function handleSubmitClick() {
    onNextClick?.();
  }
  const handleTradingLicenseUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handleEcommerceLicenseUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handleServiceAgreementUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handleNationalIdUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handlePassportUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handlevisaUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const [CoOwnerData, setCoOwnerData] = React.useState<{} | null>();
  const [CoOwnerList, setCoOwner] = React.useState<Array<object> | null>(null);
  const [isCoOwnerAdd, setIsCoOwnerAdd] = React.useState(false);
  const handleAddCoOwner = () => {
    setIsCoOwnerAdd(true);
    if (CoOwnerData) {
      setCoOwner((prevCoOwners) => [...prevCoOwners!, CoOwnerData]);
    } else if (CoOwnerList === null) {
      setCoOwner([{}]);
    } else {
      setCoOwner((prevCoOwners) => [...prevCoOwners!, {}]);
    }
    console.log("Clicked");
  };
  const [selectedModel, setSelectedModel] = React.useState("B2B");
  const handleModelChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelectedModel(event.target.name);
    console.log(event, "Checkbox");
  };
  console.log(CoOwnerList, "CoOwnerList ");
  React.useEffect(() => {
    console.log(CoOwnerData, "CoOwnerData ", CoOwnerList, "CoOwnerList");
  }, [CoOwnerData]);
  return (
    <StyledWrapper>
      {/* Business Details */}
      <StyledSubWrapper className="StyledSubWrapper">
        <CardHeadingLabel className="CardHeadingLabel">
          Business Details
        </CardHeadingLabel>
        <RoundedWrapperCard className="RoundedWrapperCard">
          <StyledGridWrapper container spacing={2}>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="Business/Company Trade Name"
                placeholder="Enter your business name"
                className="background-gray"
                validations="required"
              />
            </Grid>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="Official Website (if any)"
                placeholder="Enter your website URL here"
                className="background-gray"
              />
            </Grid>
            <Grid item xs={6} className="styled-PhoneNumberField">
              <PhoneNumberField
                label="Official Contact Number"
                placeholder="Enter your company’s official mobile number"
                validations="required"
              />
            </Grid>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="Official E-mail ID"
                placeholder="Enter your company’s official e-mail ID"
                className="background-gray"
                validations="required"
              />
            </Grid>
            <Grid item xs={6}>
              <FieldLabel>
                Upload a copy of <span>your Trading License</span>{" "}
                <span className="validField">*</span>
              </FieldLabel>
              <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handleTradingLicenseUpload} />
              </UploadFieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldLabel>
                Upload a copy of <span>your E-commerce License </span>(if any)
              </FieldLabel>
              <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handleEcommerceLicenseUpload} />
              </UploadFieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldLabel>
                Upload a copy of <span>Service Agreement/MoA/AoA </span>{" "}
                <span className="validField">*</span>
              </FieldLabel>
              <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handleServiceAgreementUpload} />
              </UploadFieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldLabel>
                Select your business model
                <span className="validField"> *</span>
              </FieldLabel>
              <MultipleCheckboxWrapper className="MultipleCheckboxWrapper">
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={selectedModel === "B2B"}
                      onChange={handleModelChange}
                      name="B2B"
                    />
                  }
                  label="Business-To-Business (B2B/Wholesale)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={selectedModel === "B2C"}
                      onChange={handleModelChange}
                      name="B2C"
                    />
                  }
                  label="Business-To-Consumer (B2C/Retail)"
                />
              </MultipleCheckboxWrapper>
            </Grid>
          </StyledGridWrapper>
        </RoundedWrapperCard>
      </StyledSubWrapper>
      <StyledSubWrapper className="StyledSubWrapper">
        <CardHeadingLabel className="CardHeadingLabel">
          Owner(s) Details
        </CardHeadingLabel>
        <RoundedWrapperCard className="RoundedWrapperCard">
          <StyledGridWrapper container spacing={2}>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="Primary Owner Name"
                placeholder="Enter your name"
                className="background-gray"
                validations="required"
              />
            </Grid>
            <Grid item xs={6} className="styled-PhoneNumberField">
              <PhoneNumberField
                label="Primary Owner Contact Number"
                placeholder="Enter your contact number"
              />
            </Grid>
            <Grid item xs={6}>
              <RadioGroupWrapper>
                <FormControl>
                  <FieldLabel>
                    Are you a resident of United Arab Emirates (UAE)?{" "}
                    <span className="validField">*</span>
                  </FieldLabel>
                  <RadioButtonWrapper className="RadioButtonWrapper">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes, I am a resident of UAE"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No, I do not reside in UAE"
                      />
                    </RadioGroup>
                  </RadioButtonWrapper>
                </FormControl>
              </RadioGroupWrapper>
            </Grid>

            <Grid item xs={6}>
              <FieldLabel>
                Upload your <span>Emirates National ID </span>(Applicable only
                if you are a resident of UAE)
              </FieldLabel>
              <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handleNationalIdUpload} />
              </UploadFieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldLabel>
                Upload a copy of <span>your passport </span>for documentation
                purposes
              </FieldLabel>
              <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handlePassportUpload} />
              </UploadFieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldLabel>
                Upload a copy of<span> your visa</span> for documentation
                purposes
              </FieldLabel>
              <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handlevisaUpload} />
              </UploadFieldWrapper>
            </Grid>
          </StyledGridWrapper>
        </RoundedWrapperCard>
      </StyledSubWrapper>

      <StyledSubWrapper>
        {CoOwnerList &&
          CoOwnerList.length > 0 &&
          CoOwnerList?.map((data, index) => {
            return (
              <>
                <CoOwnerComponent
                  isCoOwnerAdd={isCoOwnerAdd}
                  data={data}
                  index={index}
                  CoOwnerData={CoOwnerData}
                  setCoOwnerData={setCoOwnerData}
                />
              </>
            );
          })}
      </StyledSubWrapper>
      <SubmitBtnWrapper>
        <Button
          component="span"
          startIcon={<img src={AddIcon} alt="ADD" />}
          style={{
            background: "transparent",
            color: "#01579B",
            border: "1px solid #01579B",
            fontSize: "12px",
            width: "183px",
            height: "35px",
            marginBottom: "20px",
          }}
          onClick={handleAddCoOwner}
        >
          ADD A CO-OWNER
        </Button>
      </SubmitBtnWrapper>
      {/* Banking Details */}
      <StyledSubWrapper className="StyledSubWrapper">
        <CardHeadingLabel className="CardHeadingLabel">
          Banking Details
        </CardHeadingLabel>
        <RoundedWrapperCard className="RoundedWrapperCard">
          <StyledGridWrapper container spacing={2}>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="Account Holder Name"
                placeholder="Enter name of company or person bank account belongs to"
                className="background-gray"
                validations="required"
                // validator={simpleValidator.current}
                // value={bankDetails?.account_holdername}
                // onChange={handleBankChange}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="IBAN Number"
                placeholder="Enter IBAN number"
                className="background-gray"
                validations="required"
              />
            </Grid>

            <Grid item xs={6}>
              <MuiStyledTextField
                label="Account Number"
                placeholder="Enter your bank account number"
                className="background-gray"
                validations="required"
              />
            </Grid>
            <Grid item xs={6}>
              <MuiStyledTextField
                label="Bank Name"
                placeholder="Enter name of bank where the bank account is in"
                className="background-gray"
                validations="required"
              />
            </Grid>
          </StyledGridWrapper>
        </RoundedWrapperCard>
      </StyledSubWrapper>
      <SubmitBtnWrapper className="SubmitBtnWrapper">
        <StyledButton onClick={handleSubmitClick} variant="contained">
          Submit
        </StyledButton>
      </SubmitBtnWrapper>
    </StyledWrapper>
  );
};

export default VendorDocumentation;
const StyledWrapper = styled.div`
  display: "block";
`;
const RadioGroupWrapper = styled.div`
  display: "block";
  text-align: left;
`;
const RadioButtonWrapper = styled.div`
  padding: 0 20px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    padding: 3px 5px 3px 0;
  }
`;
const StyledSubWrapper = styled.div`
  display: "block";
  width: 100%;
  padding: 0 0 30px 0;
  .CardHeadingLabel {
    margin: 15px 0px 15px;
  }
  .RoundedWrapperCard {
    margin: 0px 25px 0px 25px;
  }
`;
const StyledTitleWrapper = styled.div`
  display: "block";
`;
const StyledGridWrapper = styled(Grid)`
  /* display: "block"; */
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  span {
    font-weight: 600;
    color: #000000;
  }
  .validField {
    color: #ff0000;
  }
`;

const UploadFieldWrapper = styled.div`
  padding: 5px 15px;
`;

const MultipleCheckboxWrapper = styled.div`
  padding: 0 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 3px 5px 3px 0;
  }
`;
const SubmitBtnWrapper = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  text-align: right;
  padding: 5px 24px;
  button {
    max-width: 180px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
    background: #01579b !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  padding: 10px 0px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background: #6e93f7 !important;
`;

import React from "react";
import PhoneNumberField from "../../../../../components/PhoneNumberField";
import { AdornmentTextField } from "../../../../../components/AdornmentTextField";
import CustomDropDown from "../../../../../components/CustomDropDown";
import styled from "styled-components";
import { Button, SelectChangeEvent } from "@mui/material";

type Props = {
  onNextClick?: () => void;
};
type Credential = {
  phone: undefined | string;
  email: string;
  phoneOtp: string;
  emailOtp: string;
};
const RegisterVendor = ({ onNextClick }: Props) => {
  const inputRefs = React.useRef<HTMLInputElement[]>([]);
  const [otpView, setOtpView] = React.useState<boolean>(false);
  const [verifyOtp, setVerifyOtp] = React.useState<boolean>(false);
  const [sendOtpPhone, setSendOtpPhone] = React.useState<boolean>(false);
  const [sendOtpEmail, setSendOtpEmail] = React.useState<boolean>(false);
  const [verifyEmail, setVerifyEmail] = React.useState<boolean>(false);
  const [verifyPhone, setVerifyPhone] = React.useState<boolean>(false);
  const [credentials, setCredentials] = React.useState<Credential>({
    phone: undefined,
    email: "",
    phoneOtp: "",
    emailOtp: "",
  });
  const [countryVal, setCountryVal] = React.useState({
    id: undefined,
    name: "",
  });

  const [values, setValues] = React.useState<string[] | undefined>(
    new Array(4).fill("")
  );
  const handleChange = (index: number, value: string) => {
    const newValues = [...values!];
    if (values) {
      newValues[index] = value;
      setValues(newValues);
      const otp = newValues.join("");
      if (otp.length === 4) {
        // onChange?.(otp);
        // dispatch(actions.changeLoginOtp(otp));
        return;
      }
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && values && !values[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  function handleSubmitClick() {
    // setOtpView(true);
    onNextClick?.();
  }
  function handleVerifyClick() {
    setOtpView(false);
    setVerifyOtp(true);
  }
  const handlePhoneChange = (
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    console.log(event, "handlePhoneChange");

    setCredentials((prevState) => ({ ...prevState, phone: value }));
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({ ...prevState, email: e.target.value }));
  };
  const handlePhoneOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({ ...prevState, phoneOtp: e.target.value }));
  };
  const handleEmailOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({ ...prevState, emailOtp: e.target.value }));
  };
  function handleSendPhoneOtpClick() {
    // setVerifyPhone(false);
    setSendOtpPhone(true);
    // setCredentials({ ...credentials, phone: undefined });
  }
  function handleSendEmailOtpClick() {
    setSendOtpEmail(true);
  }
  function handleVerifyPhoneClick() {
    setVerifyPhone(true);
  }
  function handleVerifyEmailClick() {
    setVerifyEmail(true);
  }
  function handleOnDropDownSelect(
    e: SelectChangeEvent<string | number>,
    val?: any
  ) {
    console.log(e);
    setCountryVal(val);
  }

  return (
    <>
      <StyledFiledWrapper className="StyledFiledWrapper">
        {!otpView ? (
          <>
            <CustomDropDown
              // onSelect={handleOnDropDownSelect}
              value={countryVal.id}
              label="Choose Country"
              onChange={handleOnDropDownSelect}
            />
            <PhoneNumberField
              placeholder={"Enter your mobile number"}
              showAdornment={true}
              disabled={verifyOtp}
              onChange={handlePhoneChange}
              value={credentials?.phone}
              onSendClick={handleSendPhoneOtpClick}
              sendOtp={sendOtpPhone}
              sendOtpOnly={true}
              className="styled-PhoneNumberField"
            />
            {sendOtpPhone && (
              <div className="AdornmentTextField-wrapper">
                <AdornmentTextField
                  placeholder={"Enter One Time Password"}
                  showAdornment={true}
                  disabled={verifyOtp}
                  onChange={handlePhoneOtpChange}
                  verifyOtp={verifyPhone}
                  value={credentials?.phoneOtp}
                  onCheckClick={handleVerifyPhoneClick}
                  sendOtp={true}
                />
              </div>
            )}
            <div className="AdornmentTextField-wrapper">
              <AdornmentTextField
                placeholder={"Enter your email ID"}
                showAdornment={true}
                disabled={verifyOtp}
                onChange={handleEmailChange}
                value={credentials?.email}
                onSendClick={handleSendEmailOtpClick}
                sendOtp={sendOtpEmail}
                sendOtpOnly={true}
              />
            </div>
            {sendOtpEmail && (
              <div className="AdornmentTextField-wrapper">
                <AdornmentTextField
                  placeholder={"Enter One Time Password"}
                  showAdornment={true}
                  disabled={verifyOtp}
                  onChange={handleEmailOtpChange}
                  verifyOtp={verifyEmail}
                  value={credentials?.emailOtp}
                  onCheckClick={handleVerifyEmailClick}
                  onSendClick={handleSendEmailOtpClick}
                  sendOtp={true}
                />
              </div>
            )}
            {verifyEmail && verifyPhone && countryVal.id && (
              <SubmitBtnWrapper className="SubmitBtnWrapper">
                <StyledButton onClick={handleSubmitClick} variant="contained">
                  proceed to next step
                </StyledButton>
              </SubmitBtnWrapper>
            )}
          </>
        ) : (
          // <OtpBlock className="otp-block">
          //   <StyledImageWrapper className="StyledImageWrapper">
          //     <StyledImage src={shieldIcon} />
          //   </StyledImageWrapper>
          //   <h4>Enter OTP Code</h4>
          //   <OtpFieldWrapper className="OtpFieldWrapper">
          //     <StyledInputWrapper style={{ display: "flex", gap: "10px" }}>
          //       {Array.from(Array(4).keys())?.map((index) => (
          //         <StyledInput
          //           key={index}
          //           type="text"
          //           id={`otpField${index}`}
          //           pattern="[0-9]*"
          //           inputMode="numeric"
          //           maxLength={1}
          //           value={values && values[index]}
          //           onChange={(event) =>
          //             handleChange(index, event.target.value)
          //           }
          //           onKeyDown={(event) => handleKeyDown(index, event)}
          //           ref={(el) => (inputRefs.current[index] = el!)}
          //         />
          //       ))}
          //     </StyledInputWrapper>
          //     <StyledBtnWrapper>
          //       <StyledButton onClick={handleVerifyClick} variant="contained">
          //         Verify OTP
          //       </StyledButton>
          //     </StyledBtnWrapper>
          //   </OtpFieldWrapper>
          // </OtpBlock>

          <></>
        )}
      </StyledFiledWrapper>
    </>
  );
};

export default RegisterVendor;
const StyledImageWrapper = styled.div``;
const StyledFiledWrapper = styled.div`
  padding: 20px 0 0 0;

  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
    }
    input {
      background: #e8e8e8;
    }
  }

  .AdornmentTextField-wrapper {
    .MuiTextField-root {
      width: 92% !important;
      div {
        background: #e8e8e8 !important;
      }
    }
  }
  width: 100%;
  text-align: left;
  max-width: 400px;
`;
const StyledBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SubmitBtnWrapper = styled.div`
  margin-right: 16px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: right;
  padding: 5px 0 5px 0;
  button {
    max-width: 180px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
    background: #01579b !important;
  }
`;
const OtpBlock = styled.div`
  text-align: center;
  background: #fff;
  padding: 30px 65px;
  border-radius: 12px;
  row-gap: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;
const StyledInput = styled.input`
  width: 48px;
  height: 60px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background: #ffffff;
  color: #000000;
  padding: 15px;
  border: "none";
  font-size: 20px !important;
`;
const StyledInputWrapper = styled.div`
  display: "flex";
  gap: "10px";
  align-items: center;
  justify-content: center;
`;
const StyledImage = styled.img`
  background: #4070f4;
  padding: 10px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;
const OtpFieldWrapper = styled.div`
  text-align: left;
  color: #000000;
  padding: 25px 0 0 0;
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  padding: 10px 0px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background: #6e93f7 !important;
`;

import React from "react";

export const Validation = (
  values,
  servicetags,
  isPrice,
  priceDetails,
  isOnChange,
  field
) => {
  const errors = {};
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  // const phoneRegex = /[0-9]|\./;

  if (isOnChange) {
    if (field === "service_name")
     {
      if (!values.service_name)
        errors.service_name = "Service name required";
    }
    else if (field === "vehicle_select") {
      if (!values.vehicle_select)
        errors.vehicle_select = "Vehichle type required";
    } 
    else if (field === "service_description") {
      if (!values.service_description) {
        errors.service_description = "Service description  required";
      }
    } 
    else if (field === "servicetags") {
      if (!Object.keys(servicetags).length) {
        errors.servicetags = "Click Enter or Tab";
      }
    } 
    else if (field === "isPrice") {
      if (isPrice.length === 0) errors.isPrice = "isPrice is required";
    }
    else if (field === "priceDetails") {
      if (!Object.keys(priceDetails).length) errors.priceDetails = "priceDetails submit is required";
    }
    if (field === "common_price")
     {
      if (!values.common_price)
        errors.common_price = "Common price required";
    }
  } 
  else {
    // Service Name
    if (!values.service_name) errors.service_name = "Service name required";
    //Vehichle Select
    if (!values.vehicle_select)
      errors.vehicle_select = "Vehichle type required";
    //Service Description
    if (!values.service_description)
      errors.service_description = "Service description  required";
    //Service tags
    // if (!servicetags.servicetags) {
    //   errors.servicetags = "servicetags is required.";
    //   console.log(typeof(service_description) , "service tags")
    // }
    if (!Object.keys(servicetags).length) {
      errors.servicetags = "Service tags is required";
    }
    // if (!Object.keys(priceDetails).length) {
    //   errors.priceDetails = "priceDetails is required.";
    //   console.log(typeof priceDetails, "priceDetails");
    // }
    // if (!priceDetails) {
    //   console.log("jhsdchg", priceDetails.priceDetails);
    //   errors.isPrice = "SUV is required";
    // }
    // if (field == null || field === ""){
    //   console.log("jhsdchg", isPrice.isPrice);
    //   errors.isPrice = "Radio is required";}
    // if (!isPrice) {
    //   console.log("jhsdchg", isPrice.isPrice);
    //   errors.isPrice = "Radio submit is required";
    // }
    // if (!values.common_price && isPrice === "Common Price") errors.common_price = "Common Price required";

  }
  return errors;
};

import React, { Fragment, useContext, useEffect } from "react";
import { StaffContext } from "../StaffContext";
import configData from "../../../../../config.json";
import { Scrollbars } from "react-custom-scrollbars-2";
import placeholderImg from "../../../../../assets/img/user/3.png";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { getLookupDataAPI } from "../../../../../api/services/common/commonAPIs";
import dateFormat, { masks } from "dateformat";

const StaffPersonalDetailsTab = (props) => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    innertab,
    SetinnerTab,
    idproof,
    setIdProof,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
  } = useContext(StaffContext);

  let BloodOptions = [{}];
  let IdOptions = [{}];
  let RelationOptions = [{}];
  let GenderOption = [{}];

  BloodOptions = bloodGroups.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  IdOptions = idproofType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  RelationOptions = relationType.map((item) => ({
    label: item.title,
    value: item.value,
  }));
  console.log(RelationOptions);

  GenderOption = gender.map((item) => ({
    label: item.title,
    value: item.value,
  }));
  // let a = Array.from(props.pic);
  const sideEffect = () => {
    const bloodGroup = getLookupDataAPI({ type: "blood_group" });
    const idproof = getLookupDataAPI({ type: "idproof_type" });
    const relationType = getLookupDataAPI({ type: "relation_type" });
    const gender = getLookupDataAPI({ type: "gender" });

    bloodGroup
      .then((res) => {
        setBloodGroups(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    idproof
      .then((res) => {
        setIdproofType(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    relationType
      .then((res) => {
        setRelationType(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    gender
      .then((res) => {
        setGender(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });
  };

  useEffect(sideEffect, []);

  // console.log(mappedStaff, "mappedStaff");
  // console.log(mappedStaff.staffEmergencyDetail, "emrgncy");

  //check id proof extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }

  return (
    <div
      className={innertab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="inner-form-block">
          {/* {mappedStaff.length &&
            mappedStaff.map((e, index) => {
              console.log("elements mapped dtta", e); */}
          {/* let a = Array.from(mappedStaff[0].staffEmergencyDetail);
              console.log("element", a); */}
          {/* return ( */}
          <Fragment>
            <div className="name-block pt-0">
              <div className="sub-block">
                <div className="img-block">
                  {mappedStaff.pic ? (
                    mappedStaff.pic.map((photo, index) => {
                      return (
                        <div className="small-img mr-2" key={index}>
                          <img
                            src={photo.base64}
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="small-img mr-2">
                      <img
                        src={
                          mappedStaff.profile_image
                            ? `${configData.SERVER_URL}${mappedStaff.profile_image}`
                            : placeholderImg
                        }
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  )}
                </div>
                <div className="idproof-block">
                  <div className="block-name">
                    <h2>{mappedStaff.name}</h2>
                    <div className="name-designation">
                      <p>{mappedStaff.emp_id}</p>
                      <h6>{mappedStaff.designation}</h6>
                    </div>
                    {/* <h1>{mappedStaff.id}</h1> */}
                  </div>
                  <div className="id-top">
                    {mappedStaff.staffIdProof &&
                    mappedStaff.staffIdProof.id_proof ? (
                      // mappedStaff.staffIdProof.idProofType.map((proof, index) => {
                      //   return (
                      <div className="small-block">
                        <Fragment className="row-img row">
                          <div class="img-list-col">
                            <div className="img-list">
                              {getExtension(
                                mappedStaff.staffIdProof.id_proof
                              ).toLowerCase() === "pdf" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-file-pdf"></i>
                                  </span>
                                  <a
                                    href={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                    download
                                    target="_blank"
                                  >
                                    <span>
                                      {
                                        mappedStaff.staffIdProof.idProofType
                                          .title
                                      }
                                    </span>
                                  </a>
                                </div>
                              ) : getExtension(
                                  mappedStaff.staffIdProof.id_proof
                                ).toLowerCase() === "docx" ? (
                                <a
                                  href={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                  download
                                  target="_blank"
                                >
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-word word"></i>
                                    </span>
                                    <p>
                                      {
                                        mappedStaff.staffIdProof.idProofType
                                          .title
                                      }
                                    </p>
                                  </div>
                                </a>
                              ) : getExtension(
                                  mappedStaff.staffIdProof.id_proof
                                ).toLowerCase() === "doc" ? (
                                <a
                                  href={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                  download
                                  target="_blank"
                                >
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-word word"></i>
                                    </span>
                                    <p>
                                      {
                                        mappedStaff.staffIdProof.idProofType
                                          .title
                                      }
                                    </p>
                                  </div>
                                </a>
                              ) : (
                                <span className="img-proof">
                                <img
                                    src={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                    className="img-fluid"
                                    alt=""
                                  />
                                <a
                                  href={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                  download
                                  target="_blank"
                                >
                                  
                                  <p>
                                    {mappedStaff.staffIdProof.idProofType.title}
                                  </p>
                                </a>
                                </span>
                              )}
                            </div>
                          </div>
                        </Fragment>

                        {/* <Fragment className="mr-2 pl-2 pr-2">
                          <div className="img-list" key="">
                            <div>
                              {getExtension(
                                mappedStaff.staffIdProof.id_proof
                              ).toLowerCase() === "pdf" ? (
                                <a
                                  href={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                  download
                                  target="_blank"
                                >
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-pdf"></i>
                                    </span>
                                    <h5>
                                      {
                                        mappedStaff.staffIdProof.idProofType
                                          .title
                                      }
                                    </h5>
                                  </div>
                                </a>
                              ) : getExtension(
                                  mappedStaff.staffIdProof.id_proof
                                ).toLowerCase() === "docx" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-file-word word"></i>
                                  </span>
                                  <h5>
                                    {mappedStaff.staffIdProof.idProofType.title}
                                  </h5>
                                </div>
                              ) : getExtension(
                                  mappedStaff.staffIdProof.id_proof
                                ).toLowerCase() === "doc" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-word word"></i>
                                  </span>
                                  <h5>
                                    {mappedStaff.staffIdProof.idProofType.title}
                                  </h5>
                                </div>
                              ) : getExtension(
                                  mappedStaff.staffIdProof.id_proof
                                ).toLowerCase() === "jpg" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-word word"></i>
                                  </span>
                                  <h5>
                                    {mappedStaff.staffIdProof &&
                                    mappedStaff.staffIdProof.idProofType &&
                                    mappedStaff.staffIdProof.idProofType.title
                                      ? mappedStaff.staffIdProof.idProofType
                                          .title
                                      : ""}
                                  </h5>
                                </div>
                              ) : (
                                <div className="small-img" key="">
                                  <img
                                    src={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Fragment> */}
                      </div>
                    ) : (
                      <div className="img-list">
                        <div className="pdf-block ">
                          <span className="pdf-icon">
                            <i class="fal fa-file-pdf"></i>
                          </span>
                          <p>No data uploaded</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-user"></i>Name
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff.name}
                  readOnly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-envelope"></i>Email
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff.email}
                  readOnly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-phone"></i>Phone Number
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff.phone}
                  readOnly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-map-marker-alt"></i>Address
                  </span>
                </div>
                <textarea
                  className="form-control"
                  value={mappedStaff.address}
                  readOnly
                ></textarea>
              </div>
              <div class="input-group input-group-custom">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fal fa-tint"></i>Gender
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <div className="input-block">
                  <Select
                    // defaultValue={mappedStaff.gender}
                    defaultValue={GenderOption.find(
                      (x) => x.value === mappedStaff.gender
                    )}
                  />
                </div>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-calendar"></i>Date of Birth
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <div className="input-block">
                  <DatePicker
                    // maxDate={new Date()}
                    format="dd/MM/y"
                    disabled={true}
                    calendarIcon={null}
                    clearIcon={null}
                    className="form-control"
                    value={mappedStaff.date_of_birth}
                  />
                  {/* <span className="form-error">Date of Joining is required!</span> */}
                </div>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-tint"></i>Blood Group
                  </span>
                </div>
                <div className="input-block">
                  <Select
                    // defaultValue={mappedStaff.gender}
                    defaultValue={BloodOptions.find(
                      (x) => x.value === mappedStaff.blood_group_id
                    )}
                  />
                </div>
                {/* <select
                        value={mappedStaff.blood_group_id}
                        disabled
                        id="warranty_period"
                        className="form-control "
                      >
                        <option>Choose...</option>
                        <option value="1">A+</option>
                        <option value="2">A-</option>
                        <option value="3">B+</option>
                        <option value="4">B-</option>
                        <option value="5">O+</option>
                        <option value="6">O-</option>
                        <option value="7">AB+</option>
                        <option value="8">AB-</option>
                      </select> */}
              </div>
              {/* <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fal fa-user-tag"></i>Designation
                        </span>
                      </div>
                      <input
                        value={mappedStaff.designation}
                        readOnly
                        type="text"
                        className="form-control"
                      />
                    </div> */}
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-money-bill-alt"></i>Salary
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff.salary}
                  readOnly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-calendar"></i>Date of Joining
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <DatePicker
                  // maxDate={new Date()}
                  format="dd/MM/y"
                  disabled={true}
                  calendarIcon={null}
                  clearIcon={null}
                  className="form-control"
                  value={mappedStaff.date_of_joining}
                />
                {/* <input
                        type="text"
                        value={mappedStaff.date_of_joining}
                        readOnly
                        className="form-control"
                      /> */}
              </div>
              <div class="col-12 block-header main-title">
                <h6>
                  <span className="header-title">
                    Emergency Contact Details
                  </span>
                </h6>
              </div>

              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-user"></i>Name
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={
                    mappedStaff.staffEmergencyDetail &&
                    mappedStaff.staffEmergencyDetail.name
                  }
                  readonly
                />
              </div>

              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-phone"></i>Phone Number
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={
                    mappedStaff.staffEmergencyDetail &&
                    mappedStaff.staffEmergencyDetail.phone
                  }
                  readonly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-users"></i>Relation
                  </span>
                </div>
                <div className="input-block">
                  <Select
                    // defaultValue={mappedStaff.gender}
                    defaultValue={RelationOptions.find((x) =>
                      x.value ===
                      (mappedStaff.staffEmergencyDetail &&
                        mappedStaff.staffEmergencyDetail.relation_type)
                        ? mappedStaff.staffEmergencyDetail.relation_type
                        : 0
                    )}
                  />
                </div>
                {/* <div className="input-block">
                        <Select
                          disabled
                          // defaultValue={mappedStaff.gender}
                          defaultValue={RelationOptions.find(
                            (x) => x.value === e.emergency_details_relation_type
                          )}
                        />
                      </div> */}
                {/* s */}
              </div>
              <div class="input-group input-group-custom">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fal fa-map-marker-alt"></i>Emergency Address
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <div className="input-block">
                  <textarea
                    readonly
                    class="form-control"
                    value={
                      mappedStaff.staffEmergencyDetail &&
                      mappedStaff.staffEmergencyDetail.address
                    }
                  ></textarea>
                  {/* <span className="form-error">Address is required!</span> */}
                </div>
              </div>
            </div>
          </Fragment>
          {/* ); */}
          {/* })} */}
        </div>
      </Scrollbars>
    </div>
  );
};

export default StaffPersonalDetailsTab;

import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";
import { toast } from "react-toastify";
import {
  createOrUpdateSocialInfoDetails,
  listSocialInfoDetails,
} from "../../../../../api/services/sap/profile-management";

const SocialMediaTab = () => {
  const {
    innertab,
    userProfile,
    setUserProfile,
    socialviewonly,
    setSocialViewOnly,
  } = useContext(ProfileContext);
  const [socialmediaformData, setSocialMediaFormData] = useState();
  const [formsubmit, setFormSubmit] = useState(false);

  //use effect for get social media
  useEffect(() => {
    const data = listSocialInfoDetails();
    data
      .then((response) => {
        // console.log(response," social response");
        setSocialMediaFormData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const userid = localStorage.getItem("userid");
  // console.log(userid,"userid con");
  const socialFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setSocialViewOnly(true);
    const socialData = createOrUpdateSocialInfoDetails({
      ...socialmediaformData,
      service_admin_social_info_id: socialmediaformData.service_admin_social_info_id
    });
    toast.success("Details Updated Successfully", {
      position: "top-right",
    });
    socialData.then((res) => {
      const data = listSocialInfoDetails();
      data.then((response) => {
        // console.log(response, "responseresponse social");
      })
    });

  };

  return (
    <div
      className={innertab === 6 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <div className="">
                {/*    */}
                {/* {socialviewonly && (
                      <div className="action-block-right col-12 text-end p-0 mt-3">
                        <button
                          className="btn btn-action btn-edit"
                          rel="tooltip"
                          data-for="Edit-info"
                          data-tip="Edit Details"
                          onClick={(e) => setSocialViewOnly(false)}
                        >
                          <i className="fal fa-pen"> </i>
                          <ReactTooltip
                            id="Edit-info"
                            place="top"
                            type="info"
                            effect="float"
                          />
                        </button>
                      </div>
                    )} */}
              </div>
              <form className="" onSubmit={socialFormSubmitHandler}>
                <p className=" mb-3 text-start text-info">
                  Social Media Details
                </p>
                <div className="form-box">
                  {/* Website */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-globe"></i>Website
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={socialviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Paste website link here..."
                        onChange={(e) => {
                          setSocialMediaFormData({
                            ...socialmediaformData,
                            website: e.target.value,
                          });
                        }}
                        value={socialmediaformData && socialmediaformData.website ? socialmediaformData.website : ""}
                      />
                    </div>
                  </div>
                  {/* Watsapp */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fab fa-whatsapp"></i>Watsapp
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={socialviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Paste watsapp link here..."
                        onChange={(e) => {
                          setSocialMediaFormData({
                            ...socialmediaformData,
                            whatsapp: e.target.value,
                          });
                        }}
                        value={socialmediaformData && socialmediaformData.whatsapp ? socialmediaformData.whatsapp : ""}
                      />
                    </div>
                  </div>
                  {/* Facebook */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fab fa-facebook-f"></i>Facebook
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={socialviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Paste facebook link here..."
                        onChange={(e) => {
                          setSocialMediaFormData({
                            ...socialmediaformData,
                            facebook: e.target.value,
                          });
                        }}
                        value={socialmediaformData && socialmediaformData.facebook ? socialmediaformData.facebook : ""}
                      />
                    </div>
                  </div>
                  {/* Google Map Address*/}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-map-marker-alt"></i>Google Map
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={socialviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Paste google map link here..."
                        onChange={(e) => {
                          setSocialMediaFormData({
                            ...socialmediaformData,
                            google_map: e.target.value,
                          });
                        }}
                        value={socialmediaformData && socialmediaformData.google_map ? socialmediaformData.google_map : ""}
                      />
                    </div>
                  </div>
                  {/* G suite link*/}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fab fa-google"></i>G suite
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={socialviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Paste G-suite link here..."
                        onChange={(e) => {
                          setSocialMediaFormData({
                            ...socialmediaformData,
                            g_suite: e.target.value,
                          });
                        }}
                        value={socialmediaformData && socialmediaformData.g_suite ? socialmediaformData.g_suite : ""}
                      />
                    </div>
                  </div>
                </div>
                {!socialviewonly && (
                  <div className="col-12 text-end p-0 mt-3">
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default SocialMediaTab;

import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode; // make the component able to receive children elements
  className?: string;
  style?: React.CSSProperties;
};

const RoundedWrapperCard = ({ className, children, style }: Props) => {
  return (
    <StyledCardWrapper className={className} style={style}>
      {children}
    </StyledCardWrapper>
  );
};

export default RoundedWrapperCard;
const StyledCardWrapper = styled.div`
  display: "block";
  background: #ffffff;
  padding: 30px 35px;
  border-radius: 8px;
`;

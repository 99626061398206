import React, { useState, useContext, Fragment } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import { ProfileContext } from "../ProfileContext";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";

const LicenseUploadModal = () => {
  const {
    innertab,
    SetinnerTab,
    toggleInnerTab,
    preFormData,
    setPreFormDaTA,
    user,
    setUser,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    sapLicense,
    setSapLicense,
    licenseModal,
    setlicenseModal,
    handleLicenseClose,
    handleLicenseShow,
  } = useContext(ProfileContext);
  //Convert to Base64
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      const sapLength =sapLicense.length;
      // console.log(sapLength, "sapLength");
      reader.addEventListener("load", () => {
        res({
          img:{
            base64: reader.result,
            name: name,
            type,
            size: size,
          },
          index : sapLength,
          imageNote:""
        });
      });
      reader.readAsDataURL(image);
    });
  };
  //OnChange Function
  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      setSapLicense([...sapLicense, ...newImages ]);
    }
  };
  //Close Modal
  const closeFileUpload = () => {
    setlicenseModal(false);
    setSapLicense([]);
  };
  //Confirm Modal Click Function
  const ImageSubmit = (e) => {
    setSapLicense(sapLicense);
    setlicenseModal(false);
    e.preventDefault();
    // setCancelFile(true);
    setAddImageSubmit(true);
    // console.log(sapLicense, "on confirm");
    
  };
  
  //check file extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }

  return (
    <Modal
      className="file-modal"
      show={licenseModal}
      onHide={handleLicenseClose}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>JPG or PNG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header closeButton></Modal.Header>
              <h5 className="title">Upload Images</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadImage}
                    type="file"
                    accept="image/jpeg, image/png, image/webp,.pdf ,.doc, .docx"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img src={uploadImageFile} alt="loading..." />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <Row className="row-img">
                {sapLicense &&
                  sapLicense.map((photo, index) => {
                    // console.log(idproof);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={photo}>
                          <button
                            className="btn "
                            onClick={() =>
                              setSapLicense(sapLicense.filter((e) => e !== photo))
                            }
                          >
                            <i class="fal fa-times text-danger"></i>
                          </button>
                          <div>
                            {getExtension( photo["img"]["name"]).toLowerCase() ===
                            "pdf" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-pdf"></i>
                                </span>
                                <h5>{photo.img.name}</h5>
                              </div>
                            ) : getExtension( photo["img"]["name"]).toLowerCase() ===
                              "docx" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-word word"></i>
                                </span>
                                <h5>{photo.img.name}</h5>
                              </div>
                            ) : getExtension( photo["img"]["name"]).toLowerCase() ===
                              "doc" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-word word"></i>
                                </span>
                                <h5>{photo.img.name}</h5>
                              </div>
                            ) : (
                              <img
                                src={photo.img.base64}
                                className="img-fluid"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Row>
              {/* <Row className="row-img">
                {sapLicense &&
                  sapLicense.map((photo, index) => {
                    // console.log(pic);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={photo}>
                          <button
                            className="btn"
                            onClick={() =>
                              setSapLicense(sapLicense.filter((e) => e !== photo))
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <img
                            src={photo.base64}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
              </Row> */}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleLicenseClose}>
                  Close
                </Button>
                {Object.keys(sapLicense).length && Object.keys(sapLicense).length ? (
                  <Button variant="primary" onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                ) : (
                  <Button variant="primary" disabled onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                )}
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default LicenseUploadModal;

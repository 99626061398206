import React from "react";
import styled, { css } from "styled-components";
import DataTable, {
  PaginationOptions,
  TableColumn,
} from "react-data-table-component";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbar,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import MuiPagination from "@mui/material/Pagination";
import { TablePaginationProps } from "@mui/material/TablePagination";

// import dateFormat, { masks } from "dateformat";

type Props = {};
// type ColumnData = any[];

const WindowLeft = ({ ...props }) => {
  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "10",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: true,
  };
  const UserplanText = styled.div`
    color: #ffa97d;
  `;
  const InactiveText = styled.div`
    color: #ffa97d;
  `;

  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={10}
        page={1}
        onChange={(event, newPage) => {
          console.log(event, "event", newPage, "newPage");
          //   handleGetAllUsersList(newPage, 10, "DESC");
          //   onPageChange(event as any, newPage - 1);
        }}
      />
    );
  }
  function CustomPagination(props: any) {
    return (
      <GridPagination
        ActionsComponent={Pagination}
        {...props}
        rowsPerPage={10}
        count={10}
      />
    );
  }
  //function for no data available
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  const columns: GridColDef[] = [
    { field: "", headerName: "Sl.no", width: 90 },
    {
      field: "vendor_name",
      headerName: "Name",
      width: 150,
      //   editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      //   editable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone",
      type: "number",
      width: 150,
      //   editable: true,
    },
    {
      field: "country",
      headerName: "Country",
      type: "number",
      width: 150,
      //   editable: true,
    },
    {
      field: "account_status",
      headerName: "Account status",
      type: "number",
      width: 150,
    },
    {
      field: "ecommerce_status",
      headerName: "Ecommerce status",
      type: "number",
      width: 150,
    },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //   <>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`
    //     <div className="account-status-block">
    //         <span>Active</span>
    //     </div>
    //     </>,
    // },
  ];

  const rows = [
    {
      id: 1,
      vendor_name: "Snow",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 2,
      vendor_name: "Lannister",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 3,
      vendor_name: "Lannister",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 4,
      vendor_name: "Stark",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 5,
      vendor_name: "Targaryen",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 6,
      vendor_name: "Melisandre",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 7,
      vendor_name: "Clifford",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 8,
      vendor_name: "Frances",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
    {
      id: 9,
      vendor_name: "Roxie",
      email: "jonsnow@gmail.com",
      phone_number: 9713556345345,
      country: "Kingdom of Saudi Arabia",
      account_status: true,
      ecommerce_status: true,
    },
  ];

  return (
    <div className="content-window-left">
      <div className="content-table-block">
        <div className="content-table table-responsive single-column-view">
          {/* <div className="date-block-main">
            <button
              className="btn btn-none"
              id=""
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={viewCalendar}
            >
              <span>
                <i className="fal fa-calendar"></i>
              </span>
            </button>
            {startDate !== endDate ? (
              <button onClick={currentDateHandler}>Today</button>
            ) : (
              ""
            )}
            <div className="date-block">
              <div className="date-control">
                <div
                  className={`date-control-sub ${
                    startDate === endDate ? "" : "disabled-block"
                  }`}
                >
                  <div
                    className="date-control-left"
                    onClick={() => chooseDate(prevDay)}
                  >
                    <i class="fal fa-caret-left"></i>
                  </div>
                  <div
                    className="date-control-right"
                    onClick={() => chooseDate(nextDay)}
                  >
                    <i class="fal fa-caret-right"></i>
                  </div>
                </div>
              </div>
              <div className="date">
                {startDate === endDate ? (
                  <h6>{startDate}</h6>
                ) : (
                  <h6>
                    {startDate} - {endDate}
                  </h6>
                )}
              </div>
            </div>
          </div> */}

          {/* <DataTable
            // onRowClicked={viewData}
            columns={columns}
            data={[]}
            // data={filterValue}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          /> */}
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              onRowClick={props.viewVendorClick}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //   checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
          {/* <DataGrid
            // onRowClick={(e) => handleViewClick(e)}
            rows={[]}
            columns={columns}
            pageSize={10}
            checkboxSelection={false}
            disableSelectionOnClick
            // rowsPerPageOptions={[10]}
            // getRowId={(row) => row.order_unique_id}
            // components={{
            //   Toolbar: GridToolbar,
            //   NoRowsOverlay: customRowsOverlay,
            //   Pagination: CustomPagination,
            // }}
            slots={{
              //   columnMenu: MyCustomColumnMenu,
              //   Toolbar: GridToolbar,
            //   Pagination: CustomPagination,
            }}
            slotProps={{
              //   columnMenu: { background: "red", counter: rows.length },
              toolbar: GridToolbar,
            }}
            disableDensitySelector
            // componentsProps={{
            //   toolbar: {
            //     value: searchText,
            //     onChange: (searchVal) => requestSearch(searchVal),
            //     onCancelSearch: () => cancelSearch,
            //     csvOptions: { disableToolbarButton: true },
            //     printOptions: { disableToolbarButton: true },
            //     showQuickFilter: true,
            //     quickFilterProps: { debounceMs: 250 },
            //   },
            // }}
            pagination
          /> */}
        </div>
      </div>
    </div>
  );
};

export default WindowLeft;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";

type Props = {};

const WindowRight = ({ ...props }) => {
  return (
    <div className="content-window-right">
      <div className="content-right-block">
        <div className="card-right">
          <div className="header-block">
            <div className="header-sec">
              <div className="content-right-top">
                <button
                  onClick={props.closeView}
                  className="btn btn-action btn-back"
                  rel="tooltip"
                  data-for="backAction"
                  data-tip="Close"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <i className="fal fa-long-arrow-left"></i>
                </button>
                <ReactTooltip
                  id="backAction"
                  place="bottom"
                  type="info"
                  effect="float"
                />
                <h4>
                  {
                    //   editMode
                    //     ? `Edit ${mappedBookingsData.customer_name}`
                    props.viewVendor
                      ? `View Vendor`
                      : // ? `View ${mappedBookingsData.customer_name}`
                        "Add Vendor"
                  }
                </h4>

                {/* <button onClick={handleEdit}>edit</button> */}
              </div>
              {/* {BookingsTable.cancelData.reason ? BookingsTable.cancelData.reason : ""} */}
              {/* {viewReadOnly && (
                <div className="action-block-right">
                  <button
                    className="btn btn-action btn-cancel"
                    rel="tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Delete"
                    onClick={cancelBooking}
                    style={{
                      color: "#f44336",
                      background: "#fff3f2",
                      border: "solid 1px #fff3f2",
                    }}
                  >
                    Cancel Booking{" "}
                  </button>
                  <button
                    onClick={handleEdit}
                    className="btn btn-action btn-edit"
                    style={{
                      color: "#2e7d32",
                      background: "#f1ffe0",
                      border: "1px solid #f1ffe0",
                    }}
                    rel="tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Edit"
                    aria-describedby="tooltip55424"
                  >
                    <i className="fal fa-pen"> </i>
                  </button>
                </div>
              )} */}
              {/* {viewReadOnly && <h1>{cancellded != undefined ? cancellded : "Salman"}</h1>} */}
            </div>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
          <div className="name-block">
            <div className="sub-block">
              <div className="block-name">
                {/* {mappedServiceData.name && <h2>{mappedServiceData.name}</h2>} */}
              </div>
            </div>
          </div>
          <div className="row-right">
            {/* <BookingsForm
              bookingformClose={props.bookingformClose}
              SetAddBookings={props.handleAddBookings}
              addBookings={props.addBookings}
            /> */}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default WindowRight;

import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";
import { BankValidation } from "../Ui/BankValidation";
import { toast } from "react-toastify";
import {
  createOrUpdateBankDetails,
  getBankList,
} from "../../../../../api/services/sap/profile-management";
const BankDetailstab = () => {
  const {
    innertab,
    userProfile,
    setUserProfile,
    bankviewonly,
    setBankViewOnly,
    bankformData,
    setBankFormData,
    formErrors,
    SetFormErrors,
    error,
    setError,
  } = useContext(ProfileContext);

  const [formsubmit, setFormSubmit] = useState(false);
  //-------------------------------------------------------->

  //use effect fort get bank data
  // useEffect(() => {
  //   const data = getBankList();
  //   data.then((res) => {
  //     setBankFormData(
  //       res.map((item) => ({
  //         account_holder_name: item.account_holder_name,
  //         account_number: item.account_number,
  //         bank_name: item.bank_name,
  //         branch_name: item.branch_name,
  //         ifsc_code: item.ifsc_code,
  //       }))
  //     );
  //     // setBankViewOnly(true);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     setBankFormData({
  //       account_holder_name: "",
  //       account_number: "",
  //       bank_name: "",
  //       branch_name: "",
  //       ifsc_code: "",
  //     });
  //   });

  //   return () => {
  //     setError(false);
  //     SetFormErrors({});
  //   };
  // }, [bankviewonly]);
  // useEffect(()=>{
  // if(!bankviewonly){
  //   setError(false);
  //   SetFormErrors({});
  // }
  // },[bankviewonly])
  // console.log(bankformData, "bankformData");
  // -------------------------------->

  //ON change Validate
  const onChangeValidate = (field, value) => {
    const errors = BankValidation(bankformData, value, true, field);
    if (Object.keys(errors).length != 0) {
      setError(true);
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setError(false);
      }
    }
  };

  const userid = localStorage.getItem("userid");
  //preFormSubmitHandler
  const bankFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    let errors = BankValidation(bankformData);
    const id = localStorage.getItem("userid");
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      const bankData = createOrUpdateBankDetails({
        ...bankformData,
        userid: userid,
      });
      setBankViewOnly(true);
      toast.success("Details Updated Successfully", {
        position: "top-right",
      });
      bankData.then((res) => {
        const data = getBankList();
        data.then((res) =>{
          setBankFormData(res);
        })
        
      });
    }
  };
  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <div className="">
                {/*    */}
                {/* {bankviewonly && (
                      <div className="action-block-right col-12 text-end p-0 mt-3">
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-for="Edit-info"
                        data-tip="Edit Details"
                        onClick={(e) => setBankViewOnly(false)}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit-info"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    </div>
                    )} */}
              </div>
              <form className="" onSubmit={bankFormSubmitHandler}>
                <p className=" mb-3 text-start text-info">
                  Service Center Bank Details
                </p>
                <div className="form-box">
                  {/* Account Holder Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-user"></i>Account Holder Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        // readOnly={bankviewonly ? true : false}
                        readOnly={
                          bankviewonly && bankformData == null
                            ? false
                            : bankviewonly && bankformData !== null
                            ? false
                            : ""
                        }
                        type="text"
                        className="form-control"
                        placeholder="Account holder name"
                        onChange={(e) => {
                          onChangeValidate(
                            "account_holder_name",
                            e.target.value
                          );
                          setBankFormData({
                            ...bankformData,
                            account_holder_name: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.account_holder_name
                            ? bankformData.account_holder_name
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate(
                              "account_holder_name",
                              e.target.value
                            );
                          }
                        }}
                      />
                      {!bankviewonly &&
                      error &&
                      formErrors.account_holder_name ? (
                        <span className="form-error">
                          {formErrors.account_holder_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Bank Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-university"></i>Bank Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        // readOnly={bankviewonly ? true : false}
                        readOnly={
                          bankviewonly && bankformData == null
                            ? false
                            : bankviewonly && bankformData !== null
                            ? false
                            : ""
                        }
                        type="text"
                        className="form-control"
                        placeholder="Bank name"
                        onChange={(e) => {
                          onChangeValidate("bank_name", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            bank_name: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.bank_name
                            ? bankformData.bank_name
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("bank_name", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.bank_name ? (
                        <span className="form-error">
                          {formErrors.bank_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Branch Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-code-branch"></i>Branch Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        // readOnly={bankviewonly ? true : false}
                        readOnly={
                          bankviewonly && bankformData == null
                            ? false
                            : bankviewonly && bankformData !== null
                            ? false
                            : ""
                        }
                        type="text"
                        className="form-control"
                        placeholder="Branch name"
                        onChange={(e) => {
                          onChangeValidate("branch_name", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            branch_name: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.branch_name
                            ? bankformData.branch_name
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("branch_name", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.branch_name ? (
                        <span className="form-error">
                          {formErrors.branch_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Account Number */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-sort-numeric-down"></i>Account Number
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        type="text"
                        // readOnly={bankviewonly ? true : false}
                        readOnly={
                          bankviewonly && bankformData == null
                            ? false
                            : bankviewonly && bankformData !== null
                            ? false
                            : ""
                        }
                        className="form-control"
                        placeholder="Account number"
                        onChange={(e) => {
                          onChangeValidate("account_number", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            account_number: e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ),
                          });
                        }}
                        value={
                          bankformData && bankformData.account_number
                            ? bankformData.account_number
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("account_number", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.account_number ? (
                        <span className="form-error">
                          {formErrors.account_number}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* IFSC Code */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-keyboard"></i>IFSC Code
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        // bankviewonly ? true :
                        readOnly={
                          bankviewonly && bankformData == null
                            ? false
                            : bankviewonly && bankformData !== null
                            ? false
                            : ""
                        }
                        type="text"
                        className="form-control"
                        placeholder="IFSc code"
                        onChange={(e) => {
                          onChangeValidate("ifsc_code", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            ifsc_code: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.ifsc_code
                            ? bankformData.ifsc_code
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("ifsc_code", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.ifsc_code ? (
                        <span className="form-error">
                          {formErrors.ifsc_code}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* {Object.keys(bankformData).filter((value) => (bankformData[value] != undefined && bankformData[value]) ? true:false ).length}<p>"bankformData.length"</p> */}
                {/* bankviewonly  || (bankviewonly && bankformData && bankformData !== null && innertab == 2) ? */}
                {Object.keys(bankformData).filter((value) => (bankformData[value] === undefined)) && !bankviewonly || !Object.keys(bankformData).filter((value) => (bankformData[value] === undefined)).length && !bankviewonly ||  !bankviewonly && Object.keys(bankformData).length ? (
                  <div class="col-12 text-end p-0 mt-3">
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                ):null}
              </form>
            </Fragment>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default BankDetailstab;

import React, { useState, useContext, Fragment } from "react";
import StaffRow from "./StaffRow";
import configData from "../../../../config.json";
import { StaffContext } from "./StaffContext";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import "../../Layout/Ui/css/Pagination.css";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import ReactTooltip from "react-tooltip-rc";
import placeholderImg from "../../../../assets/img/user/3.png";
import StaffDeleteModal from "./StaffDeleteModal";
const StaffWindowLeft = (props) => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    staffcredential,
    SetRowActive,
    getStaffData,
    setDeleteId,
    setDeleteModal,
    deleteModal,
    setAddImageSubmit,
  } = useContext(StaffContext);
  const [selectedRow, setSelectedRow] = useState(null);
  // console.log(setDeleteModal);
  //Datatable head
  const columns = [
    {
      name: "PROFILE",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row.profile ? (
            <div className="small-img mr-2" key="">
              <img
                data-tag="allowRowEvents"
                src={`${configData.SERVER_URL}/uploads/staff-profiles/${row.profile}`}
                className="img-fluid"
                alt=""
                height="100"
                width="100"
              />
            </div>
          ) : (
            <div className="small-img mr-2">
              <img
                data-tag="allowRowEvents"
                src={placeholderImg}
                className="img-fluid"
                alt=""
                height="100"
                width="100"
              />
            </div>
          )}
          <span data-tag="allowRowEvents">{row.name}</span>
        </div>
      ),
      allowOverflow: false,
    },
    {
      name: "E-MAIL ID",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => (
        <span data-tag="allowRowEvents">{row.email ? row.email : "N/A"}</span>
      ),
    },
    {
      name: "MOBILE NUMBER",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "DESIGNATION",
      selector: (row) => row.designation,
      sortable: true,
      cell: (row) => (
        <span data-tag="allowRowEvents">
          {row.designation ? row.designation : "N/A"}
        </span>
      ),
    },
    {
      name: "ACTIONS",
      // selector: (row) => row.id,
      cell: (row) => (
        <div class="actions">
          <span
            class="action-delete p-3"
            onClick={(e) => handleDeleteModal(e, row.id)}
            data-for="deletestaff"
            data-tip="Delete"
          >
            <i class="fal fa-trash"></i>
            <ReactTooltip
              id="deletestaff"
              place="bottom"
              type="info"
              effect="float"
            />
          </span>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: "rows",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: 0,
  };
  const staffRowClick = (e, row) => {
    setSelectedRow(e.id);
    // Find the nearest parent element with class "rdt_TableRow"
    const closestTableRow = row.target.closest(".rdt_TableRow");

    // Check if the closest parent element is found
    if (closestTableRow) {
      // Remove the class from all elements with class "rdt_TableRow"
      const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });

      // Append a new class to the closest parent element
      closestTableRow.classList.add("current-selected-row");
    }

    props?.viewStaffClick();

    getStaffData(e.id);
    if (mappedStaff) {
      props.viewStaffClick();
    }
    setDeleteId(e.id);
    setPic([]);
    setAddImageSubmit(false);

    // const data = staffTable.filter((e) => e.id == props.id);
    SetRowActive(true);
    //setMappedStaff(data);
    console.log(e, "eeeeee", row, "row");
  };
  const handleDeleteModal = (e, rowId) => {
    setDeleteId(rowId);
    setDeleteModal(true);
    e.stopPropagation();
  };

  // //With React
  // const [pageNumber, setPageNumber] = useState(0);
  // const usersPerPage = 10;
  // const pagesVisited = pageNumber * usersPerPage;
  // const displayUsers = staffTable.slice(
  //   pagesVisited,
  //   pagesVisited + usersPerPage
  // );
  // const pageCount = Math.ceil(staffTable.length / usersPerPage);
  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };
  return (
    <Fragment>
      <div className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive single-column-view">
            <DataTable
              onRowClicked={(e, row) => staffRowClick(e, row)}
              columns={columns}
              data={staffTable}
              highlightOnHover={true}
              pointerOnHover={true}
              pagination
              paginationComponentOptions={paginationComponentOptions}
            />
            {deleteModal && (
              <StaffDeleteModal
              // deleteModal={props.deleteModal}
              // setDeleteModal={props.setDeleteModal}
              // handleDeleteCancel={props.handleDeleteCancel}
              // id={staffTable[0].id}
              />
            )}
            {/* <div> pass - {staffcredential.password}</div>
            <div> id - {staffcredential.id}</div> */}
            {/* <table className="table bg-white custom-table clickable-table">
              <thead>
                <tr
                  className={`trow  ${pic ? "double-row" : "single-row "} ${
                    Object.keys(mappedStaff).length &&
                    mappedStaff.id === staffTable.id
                      ? "activeRow"
                      : "test1"
                  }`}

                  // className={`trow single-row${
                  //   Object.keys(mappedStaff).length &&
                  //   mappedStaff.id === staffTable.id
                  //     ? " activeRow"
                  //     : " test1"
                  // }`}
                  // className={`trow single-row
                  // ${pic ? "double-row" : "test"}`}
                >
                  <th>Profile Photo </th>
                  <th>Name</th>
                  <th>E-mail ID</th>
                  <th>Mobile Number</th>
                  <th>Designation</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(displayUsers).length ? (
                  displayUsers.map((td, index) => (
                    <StaffRow
                      viewStaffClick={props.viewStaffClick}
                      key={td.id}
                      id={td.id}
                      name={td.name}
                      email={td.email}
                      phone={td.phone}
                      designation={td.designation}
                      address={td.address}
                      salary={td.salary}
                      pic={td.pic}
                      idproof={td.idproof}
                      showDeleteModal={props.showDeleteModal}
                      deleteModal={props.deleteModal}
                      setDeleteModal={props.setDeleteModal}
                      handleDeleteCancel={props.handleDeleteCancel}
                      // assigne={td.assigne}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="no-data-td">
                      <div className="no-data-available">
                        <div className="no-data-img">
                          <img src={noDataImage} alt="" />
                          <h5>No Data Available</h5>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
            {/* {Object.keys(displayUsers).length ? (
              <ReactPaginate
                previousLabel={<i className="fal fa-long-arrow-left"></i>}
                nextLabel={<i className="fal fa-long-arrow-right"></i>}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            ) : (
              ""
            )} */}
          </div>
        </div>
        {/* <div className="content-card-block">
          <div className="content-card">
            <div className="row m-0">
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-card">
                <div className="card card-box">
                  <div className="img-box">
                    <img
                      className="card-img-top card-box-img"
                      src="assets/img/vehichle/5.jpg"
                      alt="Card image cap"
                    />
                  </div>
                  <div className="card-body card-box-body">
                    <div className="card-txt">
                      <div className="card-title-block">
                        <h5>Foam Wash</h5>
                        <span className="status badge badge-custom-success">
                          Active
                        </span>
                      </div>
                      <p className="card-text">
                        Some quick example text to build on the card tt.
                      </p>
                      <div className="card-footer-box pt-2">
                        <div className="card-table">
                          <table className="table small-striped">
                            <tbody>
                              <tr>
                                <td>Discount</td>
                                <td>10% OFF</td>
                              </tr>
                              <tr>
                                <td>VALID TILL</td>
                                <td>2/03/22 - 30/03/22</td>
                              </tr>
                              <tr>
                                <td>TOTAL COUPONS</td>
                                <td>50</td>
                              </tr>
                              <tr>
                                <td>REMAINING</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td className="text-success">
                                  <i className="fa fa-circle size-10"></i> Active
                                </td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td className="text-danger">
                                  <i className="fa fa-circle size-10"></i> Expired
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-card">
                <div className="card card-box">
                  <div className="img-box">
                    <img
                      className="card-img-top card-box-img"
                      src="assets/img/vehichle/5.jpg"
                      alt="Card image cap"
                    />
                  </div>
                  <div className="card-body card-box-body">
                    <div className="card-txt">
                      <div className="card-title-block">
                        <h5>Foam Wash</h5>
                        <span className="status badge badge-custom-danger">
                          Expired
                        </span>
                      </div>
                      <p className="card-text">
                        Some quick example text to build on the card tt.
                      </p>
                      <div className="card-footer-box pt-2">
                        <div className="card-table">
                          <table className="table small-striped">
                            <tbody>
                              <tr>
                                <td>Discount</td>
                                <td>10% OFF</td>
                              </tr>
                              <tr>
                                <td>VALID TILL</td>
                                <td>2/03/22 - 30/03/22</td>
                              </tr>
                              <tr>
                                <td>TOTAL COUPONS</td>
                                <td>50</td>
                              </tr>
                              <tr>
                                <td>REMAINING</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td className="text-success">
                                  <i className="fa fa-circle size-10"></i> Active
                                </td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td className="text-danger">
                                  <i className="fa fa-circle size-10"></i> Expired
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default StaffWindowLeft;

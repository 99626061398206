import { sapAxios } from "../../axios";

const getProfileInfo = async (id) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `profile/${id}`,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const updateProfileInfo = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/profile-save`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const createOrUpdatePermissions = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/create-permissions-details`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const createOrUpdateBankDetails = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/create-bank-details`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const getBankList = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `/get-bank-list`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const createOrUpdateGstDetails = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/create-gst-details`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const listGst = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `/get-gst-list`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const createOrUpdateSocialInfoDetails = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/create-social-info-details`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const listSocialInfoDetails = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `/get-social-info-list`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const listPermissions = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `/license-permission-list`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const createOrUpdateOutletTiming = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/create-outlet-timing`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const listOutletTimings = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `/get-outlet-timing-list`,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

export { 
    getProfileInfo,
    updateProfileInfo,
    createOrUpdateBankDetails,
    getBankList,
    createOrUpdateGstDetails,
    listGst,
    createOrUpdateSocialInfoDetails,
    listSocialInfoDetails,
    createOrUpdatePermissions,
    listPermissions,
    createOrUpdateOutletTiming,
    listOutletTimings
};
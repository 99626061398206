import React, { useContext } from "react";
import { CustomerContext } from "./CustomerContext";
import Scrollbars from "react-custom-scrollbars-2";
import { useEffect } from "react";
import { useState } from "react";
import {
  getCustomerList,
  getCustomerVehicleList,
} from "../../../../api/services/sap/customer-management";
import vehicleIcon from "../../../../assets/img/icon/vehicle.png";
const VehicleDetails = () => {
  const { innertab, mappedOrderData, vehicleData, setVehicleData } =
    useContext(CustomerContext);
  const [orderNum, setOrderNum] = useState();
  const [vehicles, setVehicles] = useState({});

  // console.log(mappedOrderData.vehicle_details.vehicle_number)
  console.log(mappedOrderData);
  useEffect(() => {
    // const { vehicle_details, no_of_orders, ...rest } = mappedOrderData;
    // setVehicleData(vehicle_details);
    // setOrderNum([no_of_orders]);
    // console.log(mappedOrderData, "mappedOrderData");
    if (mappedOrderData) {
      const data = getCustomerVehicleList(mappedOrderData.customer_id);
      data.then((res) => {
        setVehicleData(res.data);
        console.log(res, "res getCustomerVehicleList");
      });
    }
  }, []);
  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="inner-form-block customer-inner-form-block">
          {vehicleData &&
            vehicleData.map((item, index) => {
              return (
                <div className="row ml-0 mr-0 mt-0 mb-3 vehicle-details-block">
                  <div
                    className=""
                    style={{
                      textAlign: "left",
                      paddingBottom: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="vehicle-num">Vehicle {index + 1}</p>
                    <p className="variant-name-block">{item.variant_name}</p>
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <i className="fal fa-sort-numeric-down"></i>Vehicle
                        Number
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicle_registration_number}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        {/* <i className="fal fa-user"></i> */}
                        <img
                          className="img-fluid"
                          src={vehicleIcon}
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyCcontent: "center",
                          }}
                        />
                        Vehicle Type
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicle_type}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <img
                          className="img-fluid"
                          src={vehicleIcon}
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyCcontent: "center",
                          }}
                        />{" "}
                        Manufacturer
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.manufacturer}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <img
                          className="img-fluid"
                          src={vehicleIcon}
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyCcontent: "center",
                          }}
                        />
                        Model
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicle.model}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <i class="fal fa-palette"></i>
                        Color
                      </span>
                    </div>
                    {/* <textarea
                      className="form-control"
                      value={item.color}
                      readOnly
                    ></textarea> */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Color"
                      aria-describedby="basic-addon1"
                      value={item.vehicle.color}
                      readOnly
                    />
                  </div>
                  <div className="text-right">
                    <a href="#">Orders Completed : {item.order_completed}</a>
                  </div>
                </div>
              );
            })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default VehicleDetails;

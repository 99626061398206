import React from "react";
import VendorLayout from "../../../components/layout/vendor";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import RegisterVendor from "./components/registration";
import VendorDocumentation from "./components/documentation";
import VendorRegisterDashboard from "./components/dashboard";

const steps = ["Registration", "Documentation"];

type Props = {};

const VendorRegistration = (props: Props) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    // setSkipped(newSkipped);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  console.log("activeStep", activeStep !== 0);

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <VendorLayout>
      <div className="content-block">
        <Wrapper className="Wrapper">
          <TitleHeaderWrapper className="TitleHeaderWrapper">
            <TitleHeader>Register as a Vendor</TitleHeader>
          </TitleHeaderWrapper>
          <SubTitleWrapper className="SubTitleWrapper">
            <SubTitleHeader>
              Thankyou for your interest in joining our platform!
            </SubTitleHeader>
            <SubTitleHeader className="light-header">
              Complete the next few steps to get yourself onboarded..
            </SubTitleHeader>
          </SubTitleWrapper>
          {!(activeStep > 1) && (
            <Box
              className="StyledStepper-Wrapper"
              sx={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyledStepper
                sx={{ width: "100%", minWidth: "50%", maxWidth: "50%" }}
                className="StyledStepper"
                activeStep={activeStep}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </StyledStepper>
            </Box>
          )}
          {activeStep === 0 && (
            <ContentWrapper className="ContentWrapper">
              <RegisterVendor onNextClick={handleNext} />
            </ContentWrapper>
          )}
          {activeStep === 1 && (
            <ContentWrapper className="ContentWrapper">
              <VendorDocumentation onNextClick={handleNext} />
            </ContentWrapper>
          )}
          {activeStep === 2 && (
            <ContentWrapper className="ContentWrapper">
              <VendorRegisterDashboard />
            </ContentWrapper>
          )}
          {/* {activeStep === steps.length && activeStep !== 0 ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : activeStep !== 0 ? (
            <React.Fragment>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            ""
          )} */}
        </Wrapper>
      </div>
    </VendorLayout>
  );
};

export default VendorRegistration;
const Wrapper = styled.div`
  padding: 15px 15px 0 15px;
`;
const ContentWrapper = styled.div`
  padding: 15px 15px 15px 15px;
`;
const TitleHeaderWrapper = styled.div`
  text-align: start;
  margin: 0 0 30px 0;
`;
const TitleHeader = styled.h4`
  text-align: start;
`;
const SubTitleWrapper = styled.div`
  text-align: start;
  margin: 0 0 30px 0;
`;
const SubTitleHeader = styled.h6`
  text-align: start;
  &.light-header {
    color: #a09d9d !important ;
  }
`;
const StyledStepper = styled(Stepper)`
  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          text-transform: uppercase;
          &.Mui-active {
            color: #01579b;
          }
          &.Mui-completed {
            color: #00b383;
          }
          &.Mui-disabled {
            color: #000000 !important;
          }
        }
      }
      .MuiStepLabel-iconContainer {
        &.Mui-disabled {
          svg {
            border: 1px solid black !important;
            border-radius: 50% !important;
            color: transparent !important;
            circle {
              fill: white;
            }
            .MuiStepIcon-text {
              fill: #000000 !important;
            }
          }
        }
      }
    }
    &.Mui-completed {
      .MuiStepLabel-iconContainer {
        svg {
          color: #00b383;
        }
      }
    }
  }
  .MuiStepConnector-root {
    &.Mui-completed,
    &.Mui-active {
      .MuiStepConnector-line {
        border-color: #00b383;
      }
    }
  }
`;

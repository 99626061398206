import React, { useState, useContext } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import "./Ui/css/style.css";
import { StaffContext } from "./StaffContext";
import uploadFileId from "../../../../assets/img/icon/upload/add-file.svg";
const SatffIdModal = (props) => {
  const {
    idproof,
    setIdProof,
    cancelFile,
    setCancelFile,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    addProofSubmit,
    setProofSubmit,
  } = useContext(StaffContext);
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  const uploadId = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      setIdProof([...idproof, ...newImages]);
    }
    e.target.value = "";
  };
  const closeIdUpload = () => {
    props.handleCloseID(false);
  };
  const ImageSubmit = (e) => {
    props.setShowId(false);
    e.preventDefault();
    setCancelFile(true);
    setProofSubmit(true);
    console.log(addProofSubmit);
  };

  //check id extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }

  // console.log(typeof idproof, "idproof datatype");
  return (
    <Modal
      className="file-modal"
      show={props.showId}
      onHide={props.handleCloseID}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>PDF or JPG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header closeButton></Modal.Header>
              <h5 className="title">Upload Id</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadId}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png, image/webp, .doc, .docx, .pdf"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img
                            src={uploadFileId}
                            className="pdf-file"
                            alt="loading..."
                          />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <Row className="row-img">
                {idproof &&
                  idproof.map((proof, index) => {
                    // console.log(idproof);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={proof}>
                          <button
                            className="btn "
                            onClick={() =>
                              setIdProof(idproof.filter((e) => e !== proof))
                            }
                          >
                            <i class="fal fa-times text-danger"></i>
                          </button>
                          <div>
                            {getExtension(proof["name"]).toLowerCase() ===
                            "pdf" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-pdf"></i>
                                </span>
                                <h5>{proof.name}</h5>
                              </div>
                            ) : getExtension(proof["name"]).toLowerCase() ===
                              "docx" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-word word"></i>
                                </span>
                                <h5>{proof.name}</h5>
                              </div>
                            ) : getExtension(proof["name"]).toLowerCase() ===
                              "doc" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-word word"></i>
                                </span>
                                <h5>{proof.name}</h5>
                              </div>
                            ) : (
                              <img
                                src={proof.base64}
                                className="img-fluid"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeIdUpload}>
                  Close
                </Button>
                {Object.keys(idproof).length ? (
                  <Button variant="primary" onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                ) : (
                  <Button variant="primary" disabled onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                )}
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SatffIdModal;

import React, { useState } from "react";
// import  '../../../assets/css/bootstrap.min.css';
import "../../../assets/fontawesome/css/fontawesome-all.css";
import "../../../assets/css/admin-custom.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import mainLogo from "../../../assets/img/logo/logo.svg";
import Logo from "./assets/img/logo.png";
import LogoWhite from "./assets/img/logo-white.svg";
import LogoColor from "./assets/img/motorque-logo.svg";
import NavToggle from "./Ui/NavToggle";
import "./Ui/css/NavToggle.css";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const menuItems = [
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   icon: <i class="fal fa-user"></i>,
    // },
    {
      path: "/service",
      name: "Service",
      icon: <i class="fal fa-taxi"></i>,
    },
    {
      path: "/staff",
      name: "Staff",
      icon: <i className="fal fa-users"></i>,
    },
    {
      path: "/bookings",
      name: "Bookings",
      icon: <i className="fal fa-calendar"></i>,
    },
    {
      path: "/orders",
      name: "Orders",
      icon: <i class="fal fa-tasks"></i>,
    },
    {
      path: "/customer",
      name: "Customer",
      icon: <i class="fal fa-user"></i>,
    },
  ];

  const [activeStatus, setActiveStatus] = useState(false);
  const updatedActiveStatus = (status) => {
    setActiveStatus(status);
  };

  return (
    <div className="sidebar-block">
      <div className="menu-side">
        <div className="sidebar-min-head text-center">
          <div className="sidebar-logo">
            {/* <span className="letter-logo">D</span> */}
            <span className="logo">
              <img
                className="img-fluid"
                src={LogoColor}
                style={{ height: "100px", objectFit: "cover" }}
                alt=""
              />
            </span>
            {/* <h6 className="custom-sidebar-head m-0">Dashboard</h6> */}
          </div>
        </div>
        <div className="NavToggle-block">
          <NavToggle setActiveStatus={updatedActiveStatus} />
          <span className="active-nav">
            {activeStatus ? "Active" : "Inactive"} on App
          </span>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 213px)` }}>
          <div className="menu-scroll">
            <ul className="list-sidebar bg-defoult menu-list">
              <li>
                {menuItems.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeClassName="active"
                  >
                    <div className="nav-label">
                      <span className="mr-1 icon-span">{item.icon}</span>
                      <span className="span-name">{item.name}</span>
                    </div>
                  </NavLink>
                ))}
              </li>
            </ul>
          </div>
        </Scrollbars>
        <div className="bottom-action">
          <button class="btn btn-faq">FAQS</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

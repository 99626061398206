import React from 'react'

export const Validation = (values,isOnChange,field) => {
  
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const phoneRegex = /[0-9]|\./;
    
   if(isOnChange){
    if(field === "name"){
        if (!values.name)
        errors.name = "Name is required.";
        }else if(field === "owner_name"){
        if(!values.owner_name)
        errors.owner_name = "Owner Name is required.";
            }else if(field === "phone_number"){
        if(!values.phone_number){
            errors.phone_number = "Phone Number is required.";
          } else if(values.phone_number.length != 10){
            errors.phone_number = "Please enter a valid phone number.";
          } 
    }else if(field === "password"){
        if (!values.password) {
            errors.password = "Password is required.";
            } else if (values.password.length <= 6) {
            errors.password = "Password must be more than 6 characters.";
            }
    }else if(field === "confirm_password"){
        if(!values.confirm_password){
            errors.confirm_password = "Please confirm the password.";
          }else if(values.confirm_password != values.password){
            errors.confirm_password = "Passwords do not match, please verify."
          }
    }else if(field === "address"){
        if(!values.address)
            errors.address = "Address is required."  
            
    }else if(field === "email"){
        if (!values.email) {
            errors.email = "Email is required.";
            } else if (!emailRegex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            }
    }


   }else{
// Name
if (!values.name)
errors.name = "Name is required.";

//owner name
if(!values.owner_name)
errors.owner_name = "Owner Name is required.";

//Email
if (!values.email) {
errors.email = "Email is required.";
} else if (!emailRegex.test(values.email)) {
errors.email = "This is not a valid email format!";
}

//Password

if (!values.password) {
errors.password = "Password is required.";
} else if (values.password.length <= 6) {
errors.password = "Password must be more than 6 characters.";
}

// phone_number
if(!values.phone_number){
  errors.phone_number = "Phone Number is required.";
} else if(values.phone_number.length != 10){
  errors.phone_number = "Please enter a valid phone number.";
} 

//confirm_password
if(!values.confirm_password){
  // debugger
  errors.confirm_password = "Please confirm the password.";
}else if(values.confirm_password != values.password){
  // debugger
  errors.confirm_password = "Passwords do not match, please verify."
}

//address
if(!values.address){
errors.address = "Address is required."  
}
   }
   return errors;    
}

// name: "",
// owner_name: "",
// email: "",
// phone_number: "",
// phone_extension: "091",
// website: "",
// address: "",
// password: "",
// confirm_password: "",
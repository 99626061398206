import React, { useContext, useState, Fragment, useEffect } from "react";
import { Form } from "react-bootstrap";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import ReactTooltip from "react-tooltip-rc";
import TimeRange from "react-time-range";
import moment from "moment";
import Switch from "react-switch";
import dateFormat, { masks } from "dateformat";
import {
  createOrUpdateOutletTiming,
  listOutletTimings,
} from "../../../../../api/services/sap/profile-management";

const TimingsTab = () => {
  const [timevalue, setTimeValue] = useState([]);
  const [value, onChange] = useState(["10:00", "11:00"]);

  const { innertab, timingviewonly, setTimingViewOnly } =
    useContext(ProfileContext);

  useEffect(() => {
    const data = listOutletTimings();
    data
      .then((res) => {
        // console.log(res, "response of time list");
        setTimeValue(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(timevalue, "timevalue response");
  // const [viewonly, setTimingViewOnly] = useState(true);

  // dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),

  const workingDays = [
    {
      index: 0,
      days: "Sunday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
    {
      index: 1,
      days: "Monday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
    {
      index: 2,
      days: "Tuesday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
    {
      index: 3,
      days: "Wednesday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
    {
      index: 4,
      days: "Thursday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
    {
      index: 5,
      days: "Friday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
    {
      index: 6,
      days: "Saturday",
      isOpen: false,
      startTime: moment(),
      endTime: moment(),
    },
  ];
  // console.log(moment(), "moment()");
  const [timing, setTiming] = useState([]);
  const [timer, setTimer] = useState(false);

  const returnFunctionStart = (event, index, timeType) => {
    // console.log("-------", new Date(event));
    handleTiming(event, index, timeType);
  };

  const handleTiming = (e, workId) => {
    // console.log(e, "event", workId, "index");
    let timeList =
      timevalue &&
      timevalue.map((element, rowIndex) => {
        element.sapWorkingHour.map((data, hourIndex) => {
          if (data.sap_working_hours_id == workId) {
            data.start_time = e[0];
            data.end_time = e[1];
          }
          return data;
        });

        return element;
      });

    // console.log(timeList, "timeList-----------------------");
    // console.log({...timevalue, sapWorkingHour:timeList}, "timeList - object-----------------------");
    setTimeValue(timeList);
  };
  // console.log(timevalue, "timingtimingtiming");

  //Switch toggle function
  const isOpenhandler = (checkVal, index) => {
    // console.log(checkVal, "value", index, "index");

    let timingList =
      timevalue &&
      timevalue.map((element, rowIndex) => {
        // console.log(element.day, rowIndex, "day-index");
        if (rowIndex == index) {
          if (element.is_working == 1) {
            element.is_working = 0;
          } else {
            element.is_working = 1;
          }
          return element;
        }
        return element;
      });
    setTimeValue(timingList);
    // console.log(timevalue, "timevalue index");
  };

  // console.log(timingformData, "timingformDatatimingformData");

  const [formsubmit, setFormSubmit] = useState(false);
  //preFormSubmitHandler
  const timingsFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setTimingViewOnly(true);

    // console.log(timevalue, "timevalue-----------------------");

    const data = createOrUpdateOutletTiming(timevalue);
    data.then((res) => {
      // console.log(res, "----------------------------------------------");
    });
  };
  const timeHandler = (e, index) => {
    setTimer(!timer);
    // setValue(e);
    // console.log(e, "e timer value");
    // console.log(value,"e value");
  };
  // console.log(value,"e value");
  return (
    <div
      className={innertab === 3 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <div className="">
                {/*    */}
                {/* {timingviewonly && (
                      <div className="action-block-right col-12 text-end p-0 mt-3">
                        <button
                          className="btn btn-action btn-edit"
                          rel="tooltip"
                          data-for="Edit-info"
                          data-tip="Edit Details"
                          onClick={(e) => setTimingViewOnly(false)}
                        >
                          <i className="fal fa-pen"> </i>
                          <ReactTooltip
                            id="Edit-info"
                            place="top"
                            type="info"
                            effect="float"
                          />
                        </button>
                      </div>
                    )} */}
              </div>
              <form className="" onSubmit={timingsFormSubmitHandler}>
                <h5 className=" mb-3 text-start text-info">
                  Outlet Timings
                </h5>
                <div className="form-box">
                  {/* Account Holder Name */}
                  {timevalue
                    ? timevalue.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="input-group input-group-custom">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i class="fal fa-calendar-check"></i>
                                {item.day}
                                <sup className="theme-text">*</sup>
                              </span>
                            </div>
                            <div className="input-block">
                              <div className="input-time-block">
                                <div className="switch-block">
                                  <label className="switch-label">
                                    <h6>Open</h6>
                                    <Switch
                                      id={index}
                                      onChange={(e) =>
                                        isOpenhandler(e, index)
                                      }
                                      checked={
                                        item.is_working == 1
                                          ? true
                                          : item.is_working == 0 && false
                                      }
                                      className="react-switch"
                                      width={45}
                                      height={22}
                                      handleDiameter={20}
                                      borderRadius={20}
                                      uncheckedIcon={false}
                                      onColor="#01579b"
                                      offColor="#e0e0e0"
                                      offHandleColor="#9e9e9e"
                                      onHandleColor="#ffffff"
                                    />
                                  </label>
                                </div>
                                {/* <div className="form-check form-switch pr-5">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id={index}
                                        onClick={(e) => {
                                          isOpenhandler(e, index);
                                        }}
                                        disabled={timingviewonly ? true : false}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="flexSwitchCheckChecked"
                                      >
                                        Open
                                      </label>
                                    </div> */}
                                {item.sapWorkingHour
                                  ? item.sapWorkingHour.map(
                                    (timedata) => {
                                      return (
                                        <div
                                          className="timing-block"
                                          key={
                                            timedata.sap_working_hours_id
                                          }
                                        >
                                          <TimeRangePicker
                                            onChange={(e) =>
                                              handleTiming(
                                                e,
                                                timedata.sap_working_hours_id
                                              )
                                            }
                                            value={[
                                              timedata.start_time,
                                              timedata.end_time,
                                            ]}
                                            // disabled={item.is_working == 1
                                            //   ? :true }
                                            amPmAriaLabel
                                            maxDetail="second"
                                            format="hh:mm a"
                                            clearIcon={null}
                                            clockIcon={null}
                                            disableClock={true}
                                            className="form-control"
                                            startTime={
                                              timedata.start_time
                                            }
                                            // startTimeId="start_time_id"
                                            endTime={timedata.end_time}
                                          // endtimeId="end_time_id"
                                          />
                                        </div>
                                      );
                                    }
                                  )
                                  : ""}

                                {/* <TimeRange
                                      onStartTimeChange={(e) => {
                                        if (!timingviewonly) {
                                          returnFunctionStart(
                                            e,
                                            index,
                                            "start"
                                          );
                                        }
                                      }}
                                      onEndTimeChange={(e) => {
                                        if (!timingviewonly) {
                                          returnFunctionStart(e, index, "end");
                                        }
                                      }}
                                      startMoment={item.startTime}
                                      endMoment={item.endTime}
                                      startLabel={"Starts at"}
                                      endLabel={"Ends at"}
                                      disabled={true}
                                    /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : ""}
                </div>
                {!timingviewonly && (
                  <div className="col-12 text-end p-0 mt-3">
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default TimingsTab;

import React, { Fragment, useContext } from "react";
import { StaffContext } from "../StaffContext";
import { Scrollbars } from "react-custom-scrollbars-2";


const StaffWorkHistoryTab = () => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    innertab,
    SetinnerTab,
  } = useContext(StaffContext);
  return (
    <div
      className={innertab === 3 ? " show active" : "tab-pane fade"}
      id="nav-work"
      role="tabpanel"
      aria-labelledby="nav-work-tab"
    >
       <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
      <div className="inner-form-block">
        {mappedStaff.length &&
          mappedStaff.map((e, index) => {
            return (
              <Fragment>
                <div className="col-12 p-0">
                  <div id="accordion">
                    <div className="custom-box">
                      <div
                        className="card-header collapsed"
                        id="headingTwo"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h6 className="mb-0">Work History</h6>
                      </div>
                      <div
                      // id="collapseTwo"
                      // className="collapse"
                      // aria-labelledby="headingTwo"
                      // data-parent="#accordion"
                      >
                        <div className="card-body p-0">
                          <div className="col-12 inner-content-table-block p-0">
                            <div className="content-table table-responsive">
                              <table className="table bg-white custom-table inner-tbl">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Service Name</th>
                                    <th scope="col">Vehicle No.</th>
                                    <th scope="col">Vehicle Model</th>
                                    <th scope="col">Activity</th>
                                    <th scope="col">Total Working Hrs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>04/03/2022 1</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 2</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 3</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 4</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 5</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 6</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 7</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 8</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 9</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 10</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                  <tr>
                                    <td>04/03/2022 11</td>
                                    <td>9:00 am</td>
                                    <td>Ceramic Coating</td>
                                    <td>KL 87980</td>
                                    <td>Honda fgfdvgsd</td>
                                    <td>Wash &amp; Coating</td>
                                    <td>10 Hrs</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
      </Scrollbars>
    </div>
  );
};

export default StaffWorkHistoryTab;

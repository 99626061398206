import React, { Fragment, useState, useEffect, useContext } from 'react';
import "../Ui/css/variant-content.css"
import { getVehicleVariants } from "../../../../../api/services/sup/variant-management";
import { VariantContext } from '../VariantContext';
const VariantContent = (props) => {
   const [vehicleVariants, setVehicleVariants] = useState([]);
   const [length, setLength] = useState(0);

   const {
    variantApproved
    } = useContext(VariantContext);

   const fetchVehicleVariants = () => {
      const returnedPromise = getVehicleVariants(null,1);
      returnedPromise.then((res) => {
      if(res){
         let tableData = res.filter(p => p.vehicle_type_id_fk == props.value).map((item) => ({
             id: item.variant_id_pk,
             variant_name: item.variant_name,
             vehicle_type: item.vehicle_type_id_fk,
           }))
       
         console.log(" varinats onl",tableData);
         setVehicleVariants(tableData);
         setLength(tableData.length);
      }
      });
   };

   useEffect(() => {
      fetchVehicleVariants();
   }, [variantApproved]);

   return (
      <Fragment> 
         <div className="contents" style={{padding:`5px 20px`}}>
            <div className="row">
               <h5 className='my-3 text-start' data-id={props.value}> {props.name} <span className='count'>({length})</span> </h5>
                  <div className="col-12">
                     <div className='variants d-flex'>
                        {vehicleVariants && vehicleVariants.map((a, index) => ( 
                           a.vehicle_type == props.value ? (
                              <div className='card-view'>
                              <p>{a.variant_name}</p> 
                              <sup data-variant-id={a.id}><i className='fas fa-pen'></i></sup> 
                              </div>
                           ):('')
                        ))} 
                     </div>
                  </div>
            </div>
         </div>
      </Fragment>
    
   )
}

export default VariantContent;

import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { StaffContext } from "../StaffContext";
import StaffExpenseHistoryTab from "./StaffExpenseHistoryTab";
import StaffPersonalDetailsTab from "./StaffPersonalDetailsTab";
import StaffPreviligesTab from "./StaffPreviligesTab";
import StaffWorkHistoryTab from "./StaffWorkHistoryTab";
import ReactTooltip from "react-tooltip-rc";
import StaffDeleteModal from "../StaffDeleteModal";

const StaffDetailView = (props) => {
  const {
    innertab,
    SetinnerTab,
    editbtn,
    setEditBtn,
    mappedStaff,
    formChange,
    SetFormChange,
    rowAcive,
    SetRowActive,
    setDeleteModal,
    deleteModal,
    setDeleteId,
    handleDeleteClick,
    setAddonImages,
  } = useContext(StaffContext);
  const editClickHandler = () => {
    {
      props.handleEditView();
    }
    SetFormChange(false);
    setAddonImages([]);

    // console.log("THIS IS WORKING ", "formChange IS", formChange);
  };
  const closeStaffViewHandler = () => {
    props.closeViewStaff(false);
    SetRowActive(false);
    const allTableRows = document.querySelectorAll(".rdt_TableRow");
    allTableRows.forEach((rowElement) => {
      rowElement.classList.remove("current-selected-row");
    });
  };

  // useEffect(() => {

  //   return () => {};
  // }, [editbtn, toggleInnerTab(1)]);
  console.log(mappedStaff);
  const toggleInnerTab = (index) => {
    SetinnerTab(index);
    if (index === 1) {
      setEditBtn(true);
    } else if (index === 2) {
      setEditBtn(false);
    } else if (index === 3) {
      setEditBtn(false);
    } else if (index === 4) {
      setEditBtn(false);
    }
  };
  const handleDeleteModal = (e) => {
    e.stopPropagation();
    setDeleteModal(true);
  };
  return (
    <div className="content-window-right">
      <div className="content-right-block">
        <div class="card-right">
          <div class="header-block">
            <div class="header-sec">
              <div className="content-right-top">
                <button
                  title="Close Window"
                  onClick={closeStaffViewHandler}
                  class="btn btn-action btn-back"
                  rel="tooltip"
                  data-for="backTooltip"
                  data-tip="Back to listing"
                >
                  <i class="fal fa-long-arrow-left"></i>
                  <ReactTooltip
                    id="backTooltip"
                    place="left"
                    type="info"
                    effect="float"
                  />
                </button>
                <h4>{props.viewStaff ? `View ${mappedStaff.name}` : ""}</h4>
                {/* <h4>{props.viewStaff && mappedStaff.length ? `${mappedStaff[0].name}` : ""}</h4> */}
                {/* <h2 className="mb-0">{editMode ? "Edit" :
                  viewReadOnly
                   ? "view" : "Add"}</h2> */}
              </div>
              <div className="action-block-right text-end">
                {/* <button
            className="btn btn-action btn-publish"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="top"
            title="Publish services so that customers can view"
          >
            <i className="fal fa-arrow-to-top"></i>
          </button> */}
                <button
                  className="btn btn-action btn-delete"
                  rel="tooltip"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  data-for="Delete"
                  data-tip="Delete Staff"
                  onClick={handleDeleteModal}
                >
                  {deleteModal && (
                    <StaffDeleteModal closeStaffView={props.closeViewStaff()} />
                  )}
                  <i className="fal fa-trash"></i>
                  {/* <StaffDeleteModal
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                  /> */}
                  <ReactTooltip
                    id="Delete"
                    place="bottom"
                    type="info"
                    effect="float"
                  />
                </button>
                {editbtn ? (
                  <button
                    className="btn btn-action btn-edit"
                    rel="tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                    data-for="Edit"
                    data-tip="Edit Staff"
                    onClick={editClickHandler}
                  >
                    <i className="fal fa-pen"> </i>
                    <ReactTooltip
                      id="Edit"
                      place="bottom"
                      type="info"
                      effect="float"
                    />
                  </button>
                ) : (
                  ""
                )}

                {/* <button
            className="btn btn-action btn-close"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="top"
            title="Close Window"
            onClick={() => props.closeViewStaff(false)}
          ></button> */}
              </div>
            </div>
          </div>
        </div>

        <div className="inner-tab-main">
          <div className="inner-tab v-tab d-flex ">
            <div className="tab-ul">
              <ul
                className="nav nav-tabs flex-direction-column"
                id=""
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={innertab === 1 ? " nav-link active" : "nav-link"}
                    id="nav-personal-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-personal"
                    aria-selected="true"
                    onClick={() => toggleInnerTab(1)}
                  >
                    <span>Personal Details</span>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className={innertab === 2 ? " nav-link active" : "nav-link"}
                    id="nav-privileges-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-privileges"
                    aria-selected="false"
                    onClick={() => toggleInnerTab(2)}
                  >
                    <span>Credentials</span>
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className={innertab === 2 ? " nav-link active" : "nav-link"}
                    id="nav-privileges-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-privileges"
                    aria-selected="false"
                    onClick={() => toggleInnerTab(2)}
                  >
                    <span>Privileges</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={innertab === 3 ? " nav-link active" : "nav-link"}
                    id="nav-work-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-work"
                    aria-selected="false"
                    onClick={() => toggleInnerTab(3)}
                  >
                    <span>Work History</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={innertab === 4 ? " nav-link active" : "nav-link"}
                    id="nav-work-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-work"
                    aria-selected="false"
                    onClick={() => toggleInnerTab(4)}
                  >
                    <span>Expense History</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content staff-tab-content" id="">
              <StaffPersonalDetailsTab />
              {/* <StaffCredentialsTab/> */}
              <StaffPreviligesTab />

              <StaffWorkHistoryTab />

              <StaffExpenseHistoryTab />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDetailView;

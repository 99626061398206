import { sapAxios } from "../../axios";

const getDataByVehicleNumber = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "get-vehicle-info",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const vehicleNumberSuggestion = async (data) => {
  //  data = {"vehicle_number": "aR"}
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "suggest-vehicle-number",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const serviceListByVehicleType = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "vehicle-type-service-list",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const getVehicleModel = async (data) => {
  // data = {
  //     "manufacturer": "1"
  //     }
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "get-vehicle-model",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const getManufacturerLIst = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "GET",
      url: "get-manufacture-list",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};


const getBookingsList = async (data, pageType='bookings', orderId='') => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `get-bookings-list/`+pageType+`/`+orderId,
            data
        });
        if (res.status) {
            return res.data;
        }
       
    return Promise.reject(res);
  } catch (err) {}
};
const createOrUpdateBooking = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "create-bookings",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const viewMappedBooking = async (id = undefined) => {
    const axiosInst = sapAxios();
    const url = '/view-bookings/' + id;
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
};
const deleteBookings = async (id = undefined) => {
  const axiosInst = sapAxios();
  const url = "delete/bookings/" + id;
  try {
    const res = await axiosInst({
      method: "GET",
      url,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const cancelBookings = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "cancel-bookings/",
      data
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const updatePayments = async (data) => {
  const axiosInst = sapAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "bookings-payment",
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
export {
  getDataByVehicleNumber,
  vehicleNumberSuggestion,
  serviceListByVehicleType,
  getVehicleModel,
  getManufacturerLIst,
  getBookingsList,
  createOrUpdateBooking,
  viewMappedBooking,
  deleteBookings,
  cancelBookings,
  updatePayments
};

import React from "react";

export const GstValidation = (values, field_value, isOnChange, field) => {
  const errors = {};
  let regGST = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

  //Validation For OnBlur
  if (isOnChange) {
    //Legal Business name
    if (field === "business_name" && isOnChange) {
      if (!field_value) {
        errors.business_name = "Business name is required.";
      }
      if (field_value.length >= 1) {
        errors.business_name = null;
      }
      //   if (!regGST.test(field_value)) {
      //     errors.business_name = "Pattern test";
      //   }
    }
    //GST Number
    if (field === "gst_number" && isOnChange) {
      if (!field_value) {
        errors.gst_number = "Gst number is required.";
      }
      if (field_value.length < 15) {
        errors.gst_number = "Invalid Gst number1";
      }
      if (field_value.length === 15) {
        errors.gst_number = "";
      }
      if (field_value.length > 15) {
        errors.gst_number = "Invalid Gst number2";
      }
    }
  }

  //Validation for OnSubmit
  else {
    //Legal Business name
    if (!values.business_name)
      errors.business_name = "Business name is required.";

    //GST Number
    if (!values.gst_number) {
      errors.gst_number = "Phone Number is required";
    }
    if (values.gst_number.length != 15) {
      errors.gst_number = "Please check your Gst number number.";
    }
    // if (!regGST.test(field_value)) {
    //   errors.gst_number = "Please check your Gst number number.";
    // }
  }
  return errors;
};

export default GstValidation;

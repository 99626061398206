import React, { useState, useContext, useEffect, Fragment } from "react";
import configData from "../../../../config.json";
import { ApprovalContext } from "./ApprovalContext";
import "../../../../assets/css/tags.css";
import { v4 as uuidv4 } from "uuid";
import {
  getVehicleTypes,
  getVehicleVariants,
} from "../../../../api/services/sup/service-management"
import { toast } from "react-toastify";
import { approveOrRejectVariant } from "../../../../api/services/sup/variant-management";
import ReactTooltip from "react-tooltip-rc";
import RejectVariantModal from "./Ui/RejectVariantModal";


const initialState = {
  service_name: "",
  service_center_name:"",
  vehicle_select: "",
  service_description: "",
  product_description: "",
  service_discount: "",
  others: "",
  commonPrice: "",
};

function ApprovalForm(props) {

  const [isPrice, setIsPrice] = useState("");
  const [serviceCenterName, SetServiceCenterName ] = useState("");
  const [EditServiceID, SetEditServiceID] = useState("");
  const [formData, SetFormData] = useState(initialState);
  const [serviceImageData, setServiceImages] = useState([])
  const [priceDetails, setPriceDetails] = useState([]);
  const [producttags, SetProductTags] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([
    {
      title: "",
      value: "",
    },
  ]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [warrantyDetails, setWarrantyDetails] = useState({
    warranty_description: "",
    warranty_month: "",
    warranty_year: "",
  });
  const [show, setShow] = useState(false); //Image Modal
  const [servicetags, SetServiceTags] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [variantDelete,setVariantDelete] = useState({});
  const [inputlist, SetInputList] = useState([
    {
      price: "",
      service_price_id_fk: "",
      variant_id_fk: "",
      variant_name: "",
      status:"",
      rejected_reason:""
    },
  ]); //Add Dynamic Variants
  const {
    isUpdateForm,
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    editMode,
    getServiceData,
    SetEditMode,
    review,
    SetReview,
    servicePic,
    updateOrCreateService,
    isSelected,
    setIsSelected,
    SetFormChange,
  } = useContext(ApprovalContext);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const fetchVehicleTypes = () => {
    const returnedPromise = getVehicleTypes();
    returnedPromise.then((res) => {
      setVehicleTypes(
        res.map((item) => ({
          title: item.title,
          value: item.value,
        }))
      );
    });
  };
  const fetchVehicleVariants = () => {
    const returnedPromise = getVehicleVariants();
    returnedPromise.then((res) => {
      setVehicleVariants(
        res.map((item) => ({
          price: "",
          variant_id_pk: item.variant_id_pk,
          variant_name: item.variant_name,
          service_price_id_fk: "",
          vehicle_type_id_fk: item.vehicle_type_id_fk,
        }))
      );
    });
  };
  const addVariantPriceDetails = (i, variant_id_pk, price) => {
    const data = priceDetails.map((priceItem) => priceItem);
    data[i].price = price;
    setPriceDetails(data);
  };

  //Service Tag
  const addServiceTags = (event) => {
    if (event.key === "Enter") {
      SetServiceTags([...servicetags, event.target.value]);
      event.target.value = "";
      event.preventDefault();
    }
  };
  const removeServiceTags = (indexToRemove) => {
    SetServiceTags(servicetags.filter((_, index) => index != indexToRemove));
  };
  // Product Tag Input
  function addProductTags(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    SetProductTags([...producttags, value]);
    e.target.value = "";
    e.preventDefault();
  }
  function removeProductTags(index) {
    SetProductTags(producttags.filter((el, i) => i !== index));
  }
  const updateToast = () => {
    return editMode ? "Updated Successfully" : "Added Successfully";
  };


  const setPrices = (servicePriceDetails, vehicle_select) => {
    const extractPriceDetails = (item) => {
    
      return {
        price: item.price,
        variant_id_fk: item.vehicleVariant.variant_id_pk,
        variant_name: item.vehicleVariant.variant_name,
        service_price_id_fk: item.service_price_id_pk,
        status : item.vehicleVariant.status,
        rejected_reason: item.vehicleVariant.rejected_reason
      };
    };
    let rejectedVariantPrices = servicePriceDetails.filter((item) => item.vehicleVariant.status === -1)
    .map(extractPriceDetails);
    let unapprovedVariantPrices = servicePriceDetails
      .filter((item) => item.vehicleVariant.status === 0 || item.vehicleVariant.status === -1)
      .map(extractPriceDetails);
    let approvedVariantPrices = servicePriceDetails
      .filter((item) => item.vehicleVariant.status === 1)
      .map(extractPriceDetails);
    setPriceDetails(() => {
      let newPriceDetails = vehicleVariants
        .filter((item) => item.vehicle_type_id_fk === vehicle_select)
        .map((item) => {
          const priceData = approvedVariantPrices.filter(
            (aItem) => aItem.variant_name === item.variant_name
          );
          item.price = priceData[0] ? priceData[0].price : "";
          item.service_price_id_fk = priceData[0]
            ? priceData[0].service_price_id_fk
            : "";
          return item;
        });
      return newPriceDetails;
    });
    SetInputList(unapprovedVariantPrices ? unapprovedVariantPrices : [{}]);
    if(Object.keys(unapprovedVariantPrices).length != 0 && Object.keys(unapprovedVariantPrices).length != Object.keys(rejectedVariantPrices).length){
      props.variantRequest(true);
    }else{
      props.variantRequest(false)
    }
  };

  useEffect(() => {
    if (
      !(
        mappedServiceData &&
        Object.keys(mappedServiceData).length === 0 &&
        Object.getPrototypeOf(mappedServiceData) === Object.prototype
      )
    ) {
      let {
        product_used,
        service_id_fk: id,
        servicePriceDetails,
        profile,
        service_tags,
        serviceWarrantyDetail,
        vehicleType,
        vehicle_type_id_fk: vehicle_select,
        ...rest
      } = mappedServiceData;

      setPrices(servicePriceDetails, vehicle_select);
  
      SetServiceCenterName(profile.service_center_name);

      SetEditServiceID(id);
      SetServiceTags(service_tags ? service_tags.split(",") : []);
      SetProductTags(product_used ? product_used.split(",") : []);
      
      SetFormData({ ...rest, vehicle_select, service_center_name:serviceCenterName });
      setWarrantyDetails({
        warranty_description: serviceWarrantyDetail.warranty_description,
        warranty_month: serviceWarrantyDetail.validupto_month,
        warranty_year: serviceWarrantyDetail.validupto_year,
      });
    }

    return () => {
      SetFormData(initialState);
      SetServiceTags([]);
      SetProductTags([]);
      SetInputList([{ variant_name: "", price: "" }]);
      SetEditMode(false);
      // SetMappedServiceData({});
    };
  }, [vehicleVariants]);

  useEffect(() => {
    console.log("second", vehicleVariants);
   fetchVehicleTypes();
    fetchVehicleVariants();
  }, [props.addservice, mappedServiceData]);

  const approveVariant = (e,variant) => {
    e.preventDefault();
    const req = approveOrRejectVariant({
      type: "approve",
      variant_id_fk: variant.variant_id_fk,
    });
    req.then((res) => {
      reloadPageDetails();
      toast.success(res.message, {
        position: "bottom-right",
      });
    }).catch((e) => {
      toast.error('Error', {
        position: "bottom-right",
      });
    });
  }

  const deletePopUp=(e,variant)=>{
     setVariantDelete(variant);
     setRejectModal(true);
     e.stopPropagation();
  }

  const reloadPageDetails=()=>{
    getServiceData(mappedServiceData.service_id_pk);
  }


  const sideEffectFn = () => {
    const hasServiceData = !(
      mappedServiceData &&
      Object.keys(mappedServiceData).length === 0 &&
      Object.getPrototypeOf(mappedServiceData) === Object.prototype
    );
    if (hasServiceData) {
      const {
        serviceImages,
        ...rest
      } = mappedServiceData;

      setServiceImages(serviceImages);
    } 
    // setLoader(false);
    return () => {
      if (!props.addservice) {
        setServiceImages([
          {
          service_image_id :"",
          image_name: "",
          image_url: "",
          },
        ])
        SetEditMode(false);
      }
    };
  };
 useEffect(sideEffectFn, [mappedServiceData]);

  return (
    <div className="col-12">
      <form className="w-100" id="add-service">
        {/* Service Image */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend d-flex align-items-center">
            <span className="input-group-text">
              <i class="fal fa-images"></i>Photo
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="small-block">
            {
              serviceImageData &&
              serviceImageData.map((photo, index) => {
                return (
                  <div className="img-list" key={index}  service-image-id={photo.service_image_id}>
                      {editMode?
                        <button className="btn"  onClick={() =>
                          setServiceImages(serviceImageData.filter((e) => e.service_image_id != photo.service_image_id))
                        }>
                          <i class="fal fa-times"></i>
                        </button> :""
                      }
                      <img
                        src={`${configData.SERVER_URL}${photo.image_url}`}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />
                  </div>
                );
              })}
           
          </div>
        </div>
        {/* Serice Name */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Centre Name
            </span>
          </div>
          <input
            type="text"
            readonly
            className="form-control"
            aria-label="Username"
            aria-describedby="basic-addon1"
            readOnly={viewReadOnly}
            value={formData.service_center_name}
          />
        </div>
        {/* Vehichle Type */}
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-car"></i>Vehicle Type
            </span>
          </div>
          <select
            id="warranty_period"
            className="form-select"
            disabled={viewReadOnly}
            value={formData.vehicle_select}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              let newPriceDetails = vehicleVariants.filter(
                (item) => item.vehicle_type_id_fk === value
              );
              setPriceDetails(newPriceDetails);
              SetFormData({ ...formData, vehicle_select: value });
            }}
          >
            <option value="" disabled>
              Select Type
            </option>
            {vehicleTypes.map((item) => (
              <option value={item.value}>{item.title}</option>
            ))}
            <option value="-1">Others</option>
          </select>
        </div>
        {/* Service Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Description
            </span>
          </div>
          <textarea
            className="form-control"
            readOnly={viewReadOnly}
            value={formData.service_description}
            onChange={(e) =>
              SetFormData({ ...formData, service_description: e.target.value })
            }
          ></textarea>
        </div>
        {/* Service Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Service Tags
            </span>
          </div>
          <div className="tags-input-container">
            {servicetags.map((tag, index) => (
              <div className="tag-item" key={index}>
                <span className="text">{tag}</span>
              </div>
            ))}

            <input
              onKeyDown={addServiceTags}
              readOnly={viewReadOnly}
              type="text"
              className="tags-input"
            />
          </div>
        </div>
        {/* Product Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-boxes"></i>Products Description
            </span>
          </div>
          <textarea
            className="form-control"
            value={formData.product_description}
            readOnly={viewReadOnly}
            onChange={(e) =>
              SetFormData({ ...formData, product_description: e.target.value })
            }
          ></textarea>
        </div>
        {/* Product Used Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Products Used
            </span>
          </div>
          <div className="tags-input-container">
            {producttags.map((tag, index) => (
              <div className="tag-item" key={index}>
                <span className="text">{tag}</span>
                {viewReadOnly ? null : (
                  <span
                    className="close"
                    onClick={() => removeProductTags(index)}
                  >
                    &times;
                  </span>
                )}
              </div>
            ))}

            <input
              readOnly={viewReadOnly}
              onKeyDown={addProductTags}
              type="text"
              className="tags-input"
            />
          </div>
        </div>
        {/* Warranty Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Warranty Details</span>
          </h6>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-certificate"></i>Warranty Description
            </span>
          </div>
          <textarea
            className="form-control"
            value={warrantyDetails.warranty_description}
            readOnly={viewReadOnly}
            onChange={(e) =>
              setWarrantyDetails({
                ...warrantyDetails,
                warranty_description: e.target.value,
              })
            }
          ></textarea>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Valid Upto
            </span>
          </div>
          <div className="form-width">
            <div className="row m-custom">
              <div className="col-md-6">
                <select
                  value={warrantyDetails.warranty_month}
                  disabled={viewReadOnly}
                  onChange={(e) =>
                    setWarrantyDetails({
                      ...warrantyDetails,
                      warranty_month: e.target.value,
                    })
                  }
                  id="warranty_period"
                  className="form-select"
                >
                  <option value="0">0 Month</option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="4">4 Months</option>
                  <option value="5">5 Months</option>
                  <option value="6">6 Months</option>
                  <option value="7">7 Months</option>
                  <option value="8">8 Months</option>
                  <option value="9">9 Months</option>
                  <option value="10">10 Months</option>
                  <option value="11">11 Months</option>
                </select>
              </div>
              <div className="col-md-6">
                <div className="form-group m-0">
                  <select
                    value={warrantyDetails.warranty_year}
                    disabled={viewReadOnly}
                    onChange={(e) =>
                      setWarrantyDetails({
                        ...warrantyDetails,
                        warranty_year: e.target.value,
                      })
                    }
                    id="warranty_period"
                    className="form-select"
                  >
                    <option value="0">0 Year</option>
                    <option value="1">1 Year</option>
                    <option value="2">2 Year</option>
                    <option value="3">3 Year</option>
                    <option value="4">4 Year</option>
                    <option value="5">5 Year</option>
                    <option value="6">6 Year</option>
                    <option value="7">7 Year</option>
                    <option value="8">8 Year</option>
                    <option value="9">9 Year</option>
                    <option value="10">10 Year</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* Price Details Block */}
      <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Price Details</span>
          </h6>
        </div>

        {mappedServiceData.common_price  ? "": (
          <div
          className={`${
            isPrice === "Normal Price" ? " price-change" : ""
          }`}
        >
          {formData.vehicle_select &&
            formData.vehicle_select === 1 &&
            priceDetails
              .filter(
                (item) => item.vehicle_type_id_fk === formData.vehicle_select
              )
              .map((item, i) => {
                return (
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-motorcycle"></i>
                        {item.variant_name}
                      </span>
                    </div>
                    <input
                      type="text"
                      readonly
                      className="form-control"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      readOnly={viewReadOnly}
                      value={item.price ? item.price : ""}
                      onChange={(e) => {
                        addVariantPriceDetails(
                          i,
                          item.variant_id_pk,
                          parseInt(e.target.value)
                        );
                      }}
                    />
                  </div>
                );
              })}
          {formData.vehicle_select &&
            formData.vehicle_select === 2 &&
            priceDetails
              .filter(
                (item) => item.vehicle_type_id_fk === formData.vehicle_select
              )
              .map((item, i) => (
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-car"></i>
                      {item.variant_name}
                    </span>
                  </div>
                  <input
                    type="text"
                    readOnly={viewReadOnly}
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={item.price ? item.price : ""}
                    onChange={(e) => {
                      addVariantPriceDetails(
                        i,
                        item.variant_id_pk,
                        parseInt(e.target.value)
                      );
                    }}
                  />
                </div>
              ))}
          {formData.vehicle_select === 3 &&
            priceDetails
              .filter(
                (item) => item.vehicle_type_id_fk === formData.vehicle_select
              )
              .map((item, i) => (
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-bus"></i>
                      {item.variant_name}
                    </span>
                  </div>
                  <input
                    type="text"
                    readOnly={viewReadOnly}
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={item.price ? item.price : ""}
                    onChange={(e) => {
                      addVariantPriceDetails(
                        i,
                        item.variant_id_pk,
                        parseInt(e.target.value)
                      );
                    }}
                  />
                </div>
              ))}
          {/* Add variants */}
          {inputlist.map((x, i) => {
            return (
              <Fragment>
                <div className="col-12 p-0">
                  <div className="input-list-row">
                    {x && (
                      <div className="col-main-list">
                        <div className="form-group col-list-row">
                          <input
                            name="variant_name"
                            value={x.variant_name}
                            readOnly={viewReadOnly}
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-6 mb-0">
                          <input
                            name="price"
                            type="text"
                            value={x.price}
                            readOnly={viewReadOnly}
                            className="form-control"
                            
                          />
                        </div>
                        {mappedServiceData.status === 1 && x.status === 0 ? (
                            <div className="variant-action d-flex">
                          <button type="button" className="btn btn-outline-success mr-2" onClick={(e)=>approveVariant(e,x)}>Approve</button>
                          <button type="button" className=" btn btn-outline-danger" onClick={(e)=>deletePopUp(e,x)}>Reject</button>

                        </div>
                          )
                         : "" }
                         {mappedServiceData.status != 0  && x.status === -1 ? (
                          <div className="variant-action d-flex align-center">
                               <p className="text-danger mr-1" style={{fontSize : '14px'}}>Rejected </p>
                               <span className="text-danger" data-for="info" data-tip={x.rejected_reason}  ><i className="fal fa-info-circle"></i></span> 
                          <ReactTooltip 
                            id="info"
                            place="bottom"
                            type="info"
                            effect="float" />
                          
                          </div>
                          )
                         : "" }
                      
                      </div>
                      
                    )}
                  </div>
                </div>
          
              </Fragment>
            );
          })}
        </div>
        )}
        {mappedServiceData.common_price ? (
        <div className="common-price-field-section">
          <div className="row common-row m-0">
          <div className="radio-title">
              <input
                style={{display:"none"}}
                readOnly={viewReadOnly}
                type="radio"
                value="Common Price"
                name="isPrice"
              />
              Common Price
            </div>
        <div
          className={`input-group input-group-custom p-3 ${
            isPrice === "Common Price" ? " price-change" : ""
          }`}
        >
          <input
            type="text"
            readonly
            className="form-control"
            aria-label="Username"
            aria-describedby="basic-addon1"
            readOnly={viewReadOnly}
            value={formData.common_price}
            onChange={(e) =>
              SetFormData({
                ...formData,
                common_price: parseInt(e.target.value)
                  ? parseInt(e.target.value)
                  : "",
              })
            }
            //   onChange={(e) => SetFormData({ ...formData, name: e.target.value })
            // }
          />
        </div>
          </div>
        </div>) : ""}
        
        {viewReadOnly && mappedServiceData.status === -1 ? (
          <Fragment>
            <hr />
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-info-circle text-danger"></i>Reason For
                  Rejection
                  {/* <sup className="theme-text">*</sup> */}
                </span>
              </div>
              <textarea
                className="form-control"
                value={mappedServiceData.rejected_reason}
              ></textarea>
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </form>
      {rejectModal && (<RejectVariantModal variant={variantDelete} setRejectModal={setRejectModal} reloadPageDetails={reloadPageDetails} />)} 
    </div>
  );
}

export default ApprovalForm;

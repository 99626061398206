import React, { useState, useContext, useEffect } from "react";
import { Fragment } from "react";
import { ApprovalContext } from "./ApprovalContext";
import Modal from "./Ui/ServiceModal";
function ApprovalRow(props) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [deletemodal, SetDeleteModal] = useState(false);

  const {
    table,
    setEditData,
    SetTable,
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    SetViewReadOnly,
    handleServiceDelete,
    review,
    SetReview,
    editMode,
    isSelected,
    setIsSelected,
    getServiceData
  } = useContext(ApprovalContext);

  //Popup delete
  const handleDelete = (e) => {
    SetDeleteModal(true);
    e.stopPropagation();
  };

  const setEditForm = () => { 
    getServiceData(props.id, setOpenEditForm);
  };
  const effectFn = () => {
    if (openEditForm) {
      props.handleViewService();
      props.ApprovalFormClose(true);
      SetViewReadOnly(true);
      setOpenEditForm(false);
    }
  };

  useEffect(effectFn, [openEditForm]);

  return (
    <Fragment>
      <tr
        onClick={setEditForm}
        key={props.id}
        className={`trow single-row          ${
          Object.keys(mappedServiceData).length &&
          mappedServiceData.service_id_pk === props.id
            ? "activeRow"
            : "test1"
        }`}
      >
        <td click="view">
          {props.name} <span className= "badge bg-primary ml-2" >{props.isNew==1?'New':''}</span> 
        </td>
        <td click="view">{props.serviceCenter} </td>
        <td click="view">{props.vehicleSelect}</td>
        <td click="view">
          {props.isActive == 1 ? "Active" : "Inactive"}
        </td>
        <td style={{textAlign:`center`}} click="view">
        {props.status === -1 && (
            <span>Rejected</span>
          )}
          {props.status === 2 && (
            <span>Approved</span>
          )}  
          {props.status === 1 && (
            <span>Pending Review</span>
          )}
        </td>
      </tr>
      {deletemodal && <Modal SetDeleteModal={SetDeleteModal} id={props.id} />}
    </Fragment>
  );
}

export default ApprovalRow;

import React from "react";

export const BankValidation = (values, field_value, isOnChange, field) => {
  console.log(values,"values values");
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const numberPattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
  const stringPattern = /(^[a-z ]+$)/i;
  const ifscRegex = "^[A-Z]{4}0[A-Z0-9]{6}$";

  //Validation For OnBlur
  if (isOnChange) {
    //Service CenterName name
    if (field === "account_holder_name" && isOnChange) {
      if (!field_value) {
        errors.account_holder_name = "Account Holder Name is required.";
      }
      if (field_value.length >= 1) {
        errors.account_holder_name = null;
      }
      // if (!stringPattern.test(field_value)) {
      //   errors.account_holder_name = "Only strings are accepted";
      // }
    }
    //Bank name
    if (field === "bank_name" && isOnChange) {
      if (!field_value) {
        errors.bank_name = "Bank Name is required.";
      }
      if (field_value.length >= 1) {
        errors.bank_name = null;
      }
      // if (!stringPattern.test(field_value)) {
      //   errors.bank_name = "Only strings are accepted";
      // }
    }
    //Branch name
    if (field === "branch_name" && isOnChange) {
      if (!field_value) {
        errors.branch_name = "Branch Name is required.";
      }
      if (field_value.length >= 1) {
        errors.branch_name = null;
      }
      // if (!stringPattern.test(field_value)) {
      //   errors.branch_name = "Only strings are accepted";
      // }
    }
    //Account Number
    if (field === "account_number" && isOnChange) {
      if (!field_value) {
        errors.account_number = "Account number is required.";
      }
      if (field_value.length <= 11) {
        errors.account_number = "Account number is invalid please update";
      }
      if (field_value.length >= 11) {
        errors.account_number = "";
      }
      if (field_value.length > 18) {
        errors.account_number = "Account number is invalid";
      }
      // if (!accntNumber.test(field_value)) {
      //   errors.account_number = "Account number is invalid";
      // }
    }
    //IFSC code
    if (field === "ifsc_code" && isOnChange) {
      if (!field_value) {
        errors.ifsc_code = "IFSC code is required.";
      }
      if (field_value.length <= 11) {
        errors.ifsc_code = "IFSC code is required.";
      }
      if (field_value.length === 11) {
        errors.ifsc_code = "";
      }
      if (field_value.length > 11) {
        errors.ifsc_code = "IFSC code is invalid";
      }

    //   if (field_value.length ===11 && !ifscRegex.test(field_value)) {
    //     errors.ifsc_code = "Account number format is invalid";
    //   }
    }

  }

  //Validation for OnSubmit
    else {
      //Account holder name
      if (!values.account_holder_name)
        errors.account_holder_name = "Account holder name is required.";

      //Bank name
      if (!values.bank_name) {
        errors.bank_name = "Bank name is required";
      }
      //Branch name
      if (!values.branch_name) {
        errors.branch_name = "Branch name is required";
      }
      //Account number
      if (!values.account_number) {
        errors.account_number = "Account number is required.";
      }
      //IFSC code
      if (!values.ifsc_code) {
        errors.ifsc_code = "IFSC code is required.";
      }
    }
  return errors;
};

export default BankValidation;

import React, { useState, useContext } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import { StaffContext } from "./StaffContext";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";

import configData from "../../../../config.json";
import { Scrollbars } from "react-custom-scrollbars-2";
import placeholderImg from "../../../../assets/img/user/3.png";

const EditStaffFileUpload = (props) => {
  const {
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    addonImages,
    setAddonImages,
    editImageSubmit,
    setEditImageSubmit,
    
  } = useContext(StaffContext);
  // console.log("pic",pic);

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  console.log(addonImages, "addonImages--------------------------");
  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);

      const copyFinalAddon = [...newImages];
      setAddonImages(copyFinalAddon);
    }
    e.target.value = "";
  };

  console.log(addonImages, "addonImages----------------------");

  const closeFileUpload = () => {
    props.handleCloseEditFile(false);
    setAddonImages([]);
  };
  const ImageSubmit = (e) => {
    props.handleCloseEditFile(false);
    e.preventDefault();
    mappedStaff.profile_image = "";
    setMappedStaff(mappedStaff);
    // mappedStaff["profile_image"] = [...addonImages];
    // setMappedStaff([...mappedStaff]);
    setEditImageSubmit(true);
    // setMappedStaff({});
  };
  // console.log(mappedStaff);

  return (
    <Modal
      className="file-modal"
      show={props.showEditfilemodal}
      onHide={props.handleCloseEditFile}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>JPG or PNG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header closeButton></Modal.Header>
              <h5 className="title">Upload Images</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadImage}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png, image/webp"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img src={uploadImageFile} alt="loading..." />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <Row className="row-img">
                {!mappedStaff.profile_image && addonImages ?
                  addonImages.map((addonPhoto, index) => {
                    // console.log(pic);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={index}>
                          <button
                            className="btn"
                            onClick={
                              () =>
                                setAddonImages(
                                  addonImages.filter((e) => e !== addonPhoto)
                                )
                              // setPic(pic.filter((e) => e !== photo))
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <img
                            src={
                              addonPhoto.base64
                                && addonPhoto.base64
                               
                            }
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />
                        </div>
                      </div>
                    );
                  }) :""}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeFileUpload}>
                  Close
                </Button>
                {Object.keys(addonImages).length ? (
                  <Button variant="primary" onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                ) : (
                  <Button variant="primary" disabled>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                )}

                {/* <Button variant="secondary" onClick={closeFileUpload}>
                  Close
                </Button>
                <Button variant="primary" disabled={ addonImages ?"true":""} onClick={ImageSubmit}>
                  Confirm
                  <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                </Button> */}
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default EditStaffFileUpload;

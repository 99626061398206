import React, { useState, createContext ,useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { createProfile, deleteProfile, getServiceAdminList } from "../../../../api/services/sup/service-admin-management";

export const SuperContext = createContext("");

export const SuperProvider = (props) => {
  const [canUpdateTable, setCanUpdateTable] = useState(true);
  const [editMode, SetEditMode] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [mappedServiceData, SetMappedServiceData] = useState({});
  const [table, SetTable] = useState([]);
  const [viewReadOnly, SetViewReadOnly] = useState(false);
  const [formChange, SetFormChange] = useState(false);
  
  
  const restructureResData = (data = []) => {
    let restructuredData = data.map((item) => {
      return {
        id: item.service_admin_id_pk,
        name: item.service_center_name,
        user_name: item.email,
        owner_name: item.owner_name,
        email: item.email,
        phone_number: item.phone,
        phone_extension: item.phone_extension,
        website: item.website,
        address: item.address,
        password: "",
        confirm_password:"",
      };
    });

    return restructuredData;
  }

  const restructureFormData = (data = {}) => {console.log(data);
    let res = {
      service_center_name: data.name,
      owner_name: data.owner_name,
      email: data.email,
      phone: data.phone_number,
      phone_extension: data.phone_extension,
      website: data.website,
      address: data.address,
      username: data.phone_number,
      password: data.password,
      comfirm_password: data.confirm_password,
    };
    if (isUpdateForm) {
      res = {
        service_center_name: data.name,
        owner_name: data.owner_name,
        email: data.email,
        phone: data.phone_number,
        phone_extension: data.phone_extension,
        website: data.website,
        address: data.address,
      };
    }
    return res;
  }

  const getTableData = (id = undefined) => {
    const data = (async () => await getServiceAdminList(id))();
    return data;
  }

  const handleServiceEdit = (newData = {}, id = undefined) => {
    newData = restructureFormData(newData);
    const data = createProfile(newData, id);
    data.then((res) => {
      res = restructureResData(res);
      let tempTable = table.filter(item => item.id !== id);
      setIsUpdateForm(false);
      SetTable([res[0], ...tempTable]);
    }).catch(err => { console.log(err) });
  }

  const handleServiceDelete=(id) => {
    const data = (async () => await deleteProfile(id))();
    data.then((res) => {
      let tempTable = table.filter(item => item.id !== parseInt(res.id));
      SetTable([...tempTable]);
    }).catch(() => {});
  }

  const selectProfile = (id, callback) => {
    const data = getServiceAdminList(id);
    data.then((res) => {
      res = restructureResData(res);
      SetMappedServiceData(res[0]);
      SetViewReadOnly(true);
      callback(true);
      setIsUpdateForm(true);
    }).catch(() => {});
  }

  let initialStateValues = {
    table,
    SetTable,
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    SetViewReadOnly,
    editMode,
    SetEditMode,
    handleServiceEdit,
    handleServiceDelete,
    canUpdateTable,
    setCanUpdateTable,
    selectProfile,
    isUpdateForm,
    setIsUpdateForm,
    formChange, 
    SetFormChange,
  };

  useEffect(() => {
    if (canUpdateTable) {
      const data = getTableData();
      data.then((res) => {
        res = restructureResData(res);
        SetTable(res);
      });
      setCanUpdateTable(!canUpdateTable);
    }
  }, [])

  return (
    <SuperContext.Provider value={initialStateValues}>
      {props.children}
    </SuperContext.Provider>
  );
};

import React, { Fragment, useContext, useState } from "react";
import { CustomerContext } from "../CustomerContext";
import DataTable from "react-data-table-component";
import { Rating } from "react-simple-star-rating";
import { getFeedbacks } from "../../../../../api/services/sap/order-management";

const CustomerRating = () => {
  const starQ = [{
    index: 0,
    // question: "Lorem ipsum dolor sit amet. Est ullam deleniti cum repellat totam et magnam eius. In facilis soluta ut expedita ",
    star: ""
  }
];
  const [cRating, setCRating] = useState({});
  const [rating, setRating] = useState(starQ);
  const [comment, setComment] = useState("");
  const [starRating, setStarRating] = useState(0);

  const {
    innertab,
    feedbackInnerTab,
    setFeedbackInnerTab,
    filterValue,
    setMappedOrderData,
    mappedOrderData,  
    orderTable,
    customerRating,
    setCustomerRating,
    customerOrdersListData
  } = useContext(CustomerContext);
  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.order_unique_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.service_date,
      sortable: true,
      // cell: (row) => (
      //   <div>
      //     {row.customer_name}
      //     {row.booking_from_app === 1 && (
      //       <span class="ml-1 badge rounded-pill bg-warning text-dark">
      //         APP
      //       </span>
      //     )}
      //   </div>
      // ),
      allowOverflow: false,
    },
    {
      name: "Services",
      selector: (row) => {
        let req =[];
        row.orderServices.forEach((element)=>{
           req.push(element.service_name)
         })

         return req.join(', ')
      },
      // cell: (row) => <div data-tag="allowRowEvents">row.orderService && {row.orderService.length}</div>, // need salman's help
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      sortable: true,
    },
    // {
    //   name: "No of Orders",
    //   selector: (row) => row.no_of_orders,
    //   sortable: true,
    // },
  ];

  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: "rows",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: 0,
  };
  //Datatable sort icon
  const sortIcon = <i class="fal fa-sort"></i>;
  //custom datatable styles
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        borderColor: "red",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const viewHandler = (item) => {
    const res = getFeedbacks(item.order_id,3,3);   
     res
     .then((res) => {
        setCRating(res);
        setComment(res.description);
        setStarRating(res.rating);
      })
    setCustomerRating(true);
    if(customerRating){
     
   }
  };
 
  const handleRating = (star, ratingIndex, index) => {
    let ratingList = rating && rating.map((element) => {
      if (element.index == index) {
        element.star = star;
        return element;

      }
      return element;
    });
    setRating(ratingList);
  };

  return (
    <div
      className={feedbackInnerTab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <div className="mt-3 mb-3">
          {customerRating ? (
            ""
          ) : (
            <DataTable
              columns={columns}
              data={customerOrdersListData}
              highlightOnHover={true}
              pointerOnHover={true}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              sortIcon={sortIcon}
              customStyles={customStyles}
              onRowClicked={(event) => viewHandler(event)}
            />
          )}
        </div>
        {customerRating ? (
                <div className="inner-block">
                <div className="feedback-block">
                  <h5>Record your experience with the customer:</h5>
                  <Fragment>
                    {
                      rating && rating.map((item, index) => {
                        return (
                          <div className="row star-row">
                            {/* <div className="col-md-6 p-0">
                              <h6>{item.question}</h6>
                            </div> */}
                            <div className="col-12 rating-row">
                              <Rating className="feedback-rating" initialValue={starRating}  onClick={(star, ratingIndex) => handleRating(star, ratingIndex, index)} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </Fragment>
                  <textarea class="form-control" value={comment} placeholder="Comment"></textarea>
                </div>
              </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CustomerRating;

import React, { useState, createContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import dateFormat, { masks } from "dateformat";
import {
  getCustomerList,
  getCustomerOrders,
} from "../../../../api/services/sap/customer-management";
import { getFeedbacks } from "../../../../api/services/sap/order-management";

export const CustomerContext = createContext();

export const CustomerProvider = (props) => {
  const [loader, setLoader] = useState(true);
  const [tableKanban, setTableKanban] = useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [mappedOrderData, setMappedOrderData] = useState([]);
  const [mainOderData, setmainOderData] = useState([]);
  const [viewInnerOrder, setViewInnerOrder] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [mappedId, setMappedId] = useState("");
  const [customerRating, setCustomerRating] = useState(false);
  //SERVICE TIME START,PAUSE,RESUME,FINISHED
  const [startTime, setStartTime] = useState([]);
  const [pauseTime, setPauseTime] = useState([]);
  const [resumeTime, setResumeTime] = useState([]);
  const [finishedTime, setFinishedTime] = useState([]);

  const [innertab, SetinnerTab] = useState(1);
  const [feedbackInnerTab, setFeedbackInnerTab] = useState(1);

  const [tabChange, setTabChange] = useState(true);
  //preServiceForm state
  const [preFormData, setPreFormDaTA] = useState([]);
  const [viewonly, setViewOnly] = useState(false);
  //postServiceForm state
  const [postFormData, setPostFormData] = useState([]);
  //  const [viewonly, setViewOnly] = useState(false);

  //preservice pic
  const [preservicepic, setPreServicePic] = useState([]);
  const [addonImages, setAddonImages] = useState([]);
  const [addImageSubmit, setAddImageSubmit] = useState(false);
  //postService pic
  const [postservicepic, setPostServicePic] = useState([]);
  const [postaddonImages, setPostAddonImages] = useState([]);
  const [postAddImageSubmit, setPostAddImageSubmit] = useState(false);
  //pre pic Modal State
  const [preModal, setPreModal] = useState(false);
  const handleClose = () => setPreModal(false);
  const handleShow = () => setPreModal(true);
  //post pic Modal State
  const [postModal, setPostModal] = useState(false);
  const handleClosePostModal = () => setPostModal(false);
  const handleShowPostModal = () => setPostModal(true);

  const [staffTable, setStaffTable] = useState([]);

  //orderHistory
  const [selectedOption, setSelectedOption] = useState("");
  const [vehicleFilter, setVehicleFilter] = useState(false);
  const [customerOrdersListData, setCustomerOrdersListData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState([]);

  //edit mode
  const [editMode, setEditMode] = useState(false);

  const [orderTable, setOrderTable] = useState([]);
  const handleOrderDelete = (objectId) => {
    let newList = orderTable.filter((item) => item.order_id !== objectId);
    setOrderTable(newList);
  };
  const getCustomersList=()=>{
    const data = getCustomerList();
    data.then((res) => {
      console.log(res, "customer api");
      let customerList = res.data.map((item) => {
        return item;
      });
      console.log(customerList, "customerList");
      setOrderTable(customerList);
      // console.log(customerListData, "customerListData");
    });
  }

  // const getCustomerRating= (orderId,userType,created_by) =>{
     
  // }

  let initialValues = {
    deleteId,
    setDeleteId,
    orderTable,
    setOrderTable,
    handleOrderDelete,
    tableKanban,
    setTableKanban,
    mappedOrderData,
    setMappedOrderData,
    viewInnerOrder,
    setViewInnerOrder,
    filterValue,
    setFilterValue,
    innertab,
    SetinnerTab,
    tabChange,
    setTabChange,
    preservicepic,
    setPreServicePic,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    preModal,
    setPreModal,
    handleClose,
    handleShow,
    preFormData,
    setPreFormDaTA,
    // toggleInnerTab,
    viewonly,
    setViewOnly,
    mappedId,
    setMappedId,
    startTime,
    setStartTime,
    pauseTime,
    setPauseTime,
    resumeTime,
    setResumeTime,
    finishedTime,
    setFinishedTime,
    postFormData,
    setPostFormData,
    postservicepic,
    setPostServicePic,
    postaddonImages,
    setPostAddonImages,
    postAddImageSubmit,
    setPostAddImageSubmit,
    postModal,
    setPostModal,
    handleClosePostModal,
    handleShowPostModal,
    mainOderData,
    setmainOderData,
    staffTable,
    setStaffTable,
    loader,
    setLoader,
    //orderHistory
    vehicleFilter,
    setVehicleFilter,
    selectedOption,
    setSelectedOption,
    feedbackInnerTab,
    setFeedbackInnerTab,
    customerRating,
    setCustomerRating,
    vehicleData,
    setVehicleData,
    customerOrdersListData,
    setCustomerOrdersListData,
    selectedVehicleId,
    setSelectedVehicleId,
    
    //editMode
    editMode,
    setEditMode,
  };

 
  useEffect(getCustomersList, []);
  return (
    <CustomerContext.Provider value={initialValues}>
      {props.children}
    </CustomerContext.Provider>
  );
};

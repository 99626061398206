import React, { useContext } from "react";
import { CustomerContext } from "./CustomerContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useState } from "react";
import { getCustomerList, getEditCustomer } from "../../../../api/services/sap/customer-management";

const customerInitialValue = {
  id: "",
  customerName: "",
};

const PersonalDetails = () => {
  // const [editData, setEditData] = useState({customerInitialValue});
  const [viewReadOnly, setViewReadOnly] = useState(true);

  const {
    innertab,
    mappedOrderData,
    editMode,
    setEditMode,
    setMappedOrderData,
    setOrderTable
  } = useContext(CustomerContext);
  const editCustomer = () => {
    setEditMode(true);
  };
  const handleCustomerSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    const data = getEditCustomer(mappedOrderData);
    data.then((res) => {
      const data = getCustomerList();
      data.then((res) => {
        console.log(res, "customer api");
        let customerList = res.data.map((item) => {
          return item;
        });
        console.log(customerList, "customerList");
        setOrderTable(customerList);
        // console.log(customerListData, "customerListData");
      });
    });
    console.log(mappedOrderData, "mappedOrderData");
    console.log("submitted");
  };
  return (
    <div
      className={innertab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div
          className="inner-form-block customer-inner-form-block"
          style={{ position: "relative" }}
        >
          <div className="block-form">
            <div className="edit-customer-block">
              {editMode ? (
                ""
              ) : (
                <button type="button" className="btn" onClick={editCustomer}>
                  <span>
                    <i className="fal fa-pen"></i>
                  </span>
                </button>
              )}
            </div>
            <div className="row m-0">
              <form onSubmit={handleCustomerSubmit}>
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-user"></i>Name
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={(e) =>
                      setMappedOrderData({
                        ...mappedOrderData,
                        customer_name: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={mappedOrderData.customer_name}
                    readOnly={editMode ? false : true}
                  />
                </div>
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-phone"></i>Contact Number
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={mappedOrderData.phone_number}
                    onChange={(e) =>
                      setMappedOrderData({
                        ...mappedOrderData,
                        phone_number: e.target.value,
                      })
                    }
                    readOnly={editMode ? false : true}
                  />
                </div>
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-envelope"></i>Email
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={mappedOrderData.email}
                    readOnly={editMode ? false : true}
                    onChange={(e) =>
                      setMappedOrderData({
                        ...mappedOrderData,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-map-marker-alt"></i>Address
                    </span>
                  </div>
                  <textarea
                    className="form-control"
                    value={mappedOrderData.customer_address}
                    readOnly={editMode ? false : true}
                    onChange={(e) =>
                      setMappedOrderData({
                        ...mappedOrderData,
                        customer_address: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                {editMode && (
                  <button className="btn btn-update" type="sybmit">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default PersonalDetails;

import { sapAxios } from "../../axios";


// const viewMappedBooking = async (id = undefined) => {
//     const axiosInst = sapAxios();
//     const url = '/view-bookings/' + id;
//     try {
//         const res = await axiosInst({
//             method: 'GET',
//             url,
//         });
//         if (res.status) {
//             return res.data;
//         }
//         return Promise.reject(res);
//     } catch (err) {
//     }
// }
const assignStaffs = async (data) => {
    const axiosInst = sapAxios();
    const url = 'assign-staffs';
    try {
        const res = await axiosInst({
            method: 'POST',
            url,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
  }
const orderStatusUpdate = async (data) => {
  const axiosInst = sapAxios();
  const url = 'order-status-update';
  try {
      const res = await axiosInst({
          method: 'POST',
          url,
          data
      });
      if (res.status) {
          return res.data;
      }
      return Promise.reject(res);
  } catch (err) {
  }
}
const orderStatusList = async (data) => {
    const axiosInst = sapAxios();
    const url = 'order-status-list';
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const saveOrderInspection = async (data) => {
    const axiosInst = sapAxios();
    const url = 'order-inspections';
    try {
        const res = await axiosInst({
            method: 'POST',
            url,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const saveEstimatedTime = async (data) => {
    const axiosInst = sapAxios();
    const url = 'save-estimated-time';
    try {
        const res = await axiosInst({
            method: 'POST',
            url,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const trackServiceTime = async (data) => {
    const axiosInst = sapAxios();
    const url = 'track-service-time';
    try {
        const res = await axiosInst({
            method: 'POST',
            url,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const getOrderInspection = async (order_id = undefined,type = 'pre') => {
    const axiosInst = sapAxios();
    const url = 'get-order-inspection/'+order_id+'/'+type;
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const saveFeedback = async (data) => {
    const axiosInst = sapAxios();
    const url = 'save-feedback';
    try {
        const res = await axiosInst({
            method: 'POST',
            url,
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
const getFeedbacks = async (order_id = undefined,user_type = undefined,created_by = undefined) => {
    const axiosInst = sapAxios();
    const url = 'get-feedbacks/'+order_id+'/'+user_type+'/'+created_by;
    try {
        const res = await axiosInst({
            method: 'GET',
            url            
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}
export {
  orderStatusUpdate,
  orderStatusList,
  assignStaffs,
  saveEstimatedTime,
  trackServiceTime,
  saveOrderInspection,
  getOrderInspection,
  saveFeedback,
  getFeedbacks
};

import React, { useState, createContext, useEffect } from "react";
import dateFormat, { masks } from "dateformat";
import {
  getDataByVehicleNumber,
  vehicleNumberSuggestion,
  serviceListByVehicleType,
  getVehicleModel,
  getManufacturerLIst,
  getBookingsList,
  createOrUpdateBooking
} from "../../../../api/services/sap/bookings-management";


export const BookingsContext = createContext();
export const BookingsProvider = (props) => {



  const [BookingsTable, SetBookingsTable] = useState([]);
  const [mappedBookingsData, setMappedBookingsData] = useState([]);
  const [mappedBookingData, setMappedBookingData] = useState([]);

  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loader, setLoader] = useState(true);
  // const [viewBookings, setViewBookings] = useState(false);
  // const [BookingsTable, SetBookingsTable] = useState([]);
  // const [mappedBookingsData, SetMappedBookingsData] = useState({});
  const [viewBookings, setViewBookings] = useState(false);
  // const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [formChange, SetFormChange] = useState(false);

  const [serviceOptions, setServiceOptions] = useState([]);
  const [currentDate, setCurrentDate] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [bookDate, setBookDate] = useState([
    dateFormat(new Date(), "yyyy-mm-dd HH:MM"),
    dateFormat(new Date(), "yyyy-mm-dd HH:MM"),
  ]);
  const [cancelData, setCancelData] = useState({});

  const filterBookingshandler = (filterData) => {
    setFilterValue(
      BookingsTable && BookingsTable.filter((item) =>
        dateFormat(item.service_date, "yyyy-mmd-dd").includes(
          dateFormat(filterData, "yyyy-mmd-dd")
        )
      )
    );
  };


  // console.log(BookingsTable, "BookingsTable");
  // const handleBookingsEdit = (newData, customer_name) => {
  //   let editedBookingList = BookingsTable.map((item) => {
  //     if (item.customer_name === customer_name) {
  //       return newData;
  //     }
  //     return item;
  //   });
  //   // console.log(editedBookingList, "editd value");
  //   SetBookingsTable(editedBookingList);
  // };
  // const handleBookingsDelete = (BookingsName) => {
  //   let newList = BookingsTable.filter((item) => item.BookingsName !== BookingsName);
  //   SetBookingsTable(newList);
  // };

  useEffect(() => {    
    const getBookingsData = getBookingsList();
    getBookingsData && getBookingsData.then((response) => {
      console.log(response, "getBookingsData");
      SetBookingsTable(response);
    });

  }, []);

  // console.log(manufacturer, "manufacturerList");
  let initialValues = {
    BookingsTable, SetBookingsTable,
    mappedBookingsData, setMappedBookingsData,
    viewReadOnly, setViewReadOnly,
    viewBookings, setViewBookings,
    viewBookings, setViewBookings,
    filterValue, setFilterValue,
    bookDate, setBookDate,
    filterBookingshandler,
    currentDate, setCurrentDate,
    editMode, setEditMode,
    // handleBookingsEdit, 
    cancelData, setCancelData,
    formChange, SetFormChange,
    loader, setLoader,
    serviceOptions, setServiceOptions,
    // manufacturer, setManufacturer,
    mappedBookingData, setMappedBookingData
  };

  return (
    <BookingsContext.Provider value={initialValues}>
      {props.children}
    </BookingsContext.Provider>
  );
};

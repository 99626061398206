import React from "react";
import SimpleReactValidator from "simple-react-validator";
import styled from "styled-components";
import {
  TextField,
  InputAdornment,
  IconButton,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import verifyicon from "./assets/img/Vector.svg";
// import { ReactComponent as Verifyicon } from "./assets/img/Vector.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export type MTextFieldProps = {
  // onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // to handle onClick functions
  color?: "primary" | "secondary"; // two styling options (you can create as many as you want)
  border?: "primary" | "secondary";
  disabled?: boolean; // make the button disabled or not
  label?: String;
  placeholder?: string;
  className?: string;
  name?: string;
  error?: string | null;
  defaultValue?: string;
  value?: string;
  type?: "password" | "text" | "number";
  autoComplete?: "off" | "";
  validator?: SimpleReactValidator;
  validations?: string;
  onChange?(
    value: string,
    data: {} | any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ): void;
  showAdornment?: boolean;
  verifyOtp?: boolean;
  sendOtp?: boolean;
  onCheckClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSendClick?: React.MouseEventHandler<HTMLButtonElement>;
  sendOtpOnly?: boolean;
};

const PhoneNumberField = ({
  onChange,
  color,
  border,
  label,
  placeholder,
  name,
  type,
  disabled,
  defaultValue,
  value,
  error,
  autoComplete,
  className,
  validator,
  validations,
  showAdornment,
  verifyOtp,
  onCheckClick,
  sendOtp,
  onSendClick,
  sendOtpOnly,
}: MTextFieldProps) => {
  const defaultCountry = "ae"; // 'ae' is the ISO 3166-1 alpha-2 code for the United Arab Emirates (UAE)
  const simpleValidator = React.useRef(validator);
  const handleBlur = (e: any) => {
    simpleValidator?.current?.showMessageFor(error ? error : "field");
  };

  const [phValue, setPhValue] = React.useState();
  const handleChangePh = (
    value: string,
    data: {} | any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    onChange?.(value, data, event, formattedValue);
    if (value && value?.length === 3) {
      return;
    }
  };
  const EndAdornment = () => {
    return (
      <>
        {verifyOtp && sendOtp ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <img src={verifyicon} width={`25px`} alt="success-icon" />
              <span>Verified!</span>
            </SuccessWrapper>
          </InputAdornment>
        ) : !sendOtp ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <Button
                disabled={!value?.length}
                onClick={onSendClick}
                variant="outlined"
                size="small"
              >
                Send Otp
              </Button>
            </SuccessWrapper>
          </InputAdornment>
        ) : !verifyOtp && sendOtp && !sendOtpOnly ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <Button onClick={onCheckClick} variant="outlined" size="small">
                Verify
              </Button>
            </SuccessWrapper>
          </InputAdornment>
        ) : sendOtp && sendOtpOnly ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <span>Sent!</span>
            </SuccessWrapper>
          </InputAdornment>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <Wrapper className={`textfield-wrapper ${" " + className}`}>
      <FieldLabel>
        {`${label ? label : ""} `}
        <div className="red-star">{validations ? "*" : ""}</div>
      </FieldLabel>
      <FieldSubWrapper>
        <FieldWrapper className="FieldWrapper">
          <PhoneInput
            disabled={disabled}
            value={value}
            // value={combinedContactNumber}
            placeholder={placeholder}
            // country={defaultCountry}
            enableSearch={true}
            onChange={handleChangePh}
            onBlur={handleBlur}
            disableSearchIcon={true}
            inputProps={{
              autoFocus: false,
            }}
          />
          {showAdornment ? EndAdornment() : ""}
        </FieldWrapper>
      </FieldSubWrapper>
    </Wrapper>
  );
};

export default PhoneNumberField;
const Wrapper = styled.div`
  &.textfield-wrapper {
    padding: 5px 0px;
    border-radius: 0px !important;
    width: 100%;
    min-width: 250px;
    max-width: 400px;
    .MuiTextField-root {
      width: 100%;
      .MuiFormLabel-root {
        top: -4px !important;
        color: #000000 !important;
        &.Mui-focused {
          color: #000000 !important;
        }
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .MuiInputBase-root {
        height: 100%;
        max-height: 48px !important;
        border-radius: 10px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        font-size: 12px !important;
        padding: 0 5px !important;
        background: #e8e8e8 !important;
        border: 1px solid #6e6e73;
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
          box-shadow: none !important;
        }
        &.Mui-disabled {
          cursor: pointer !important;
          .MuiInputBase-input {
            &.Mui-disabled {
              -webkit-text-fill-color: #ffffff !important;
            }
          }
        }
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: none !important;
            box-shadow: none !important;
          }
        }
      }
      .MuiInputBase-input {
        box-shadow: none !important;
        background: transparent !important;
        color: #000000 !important;
        ::placeholder {
          /* Firefox */
          color: #a09d9d;
          opacity: 1;
        }
        @media (min-width: 1366px) and (max-width: 4000px) {
          font-size: 13px;
        }
        @media (min-width: 1200px) and (max-width: 1365px) {
          font-size: 12px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 12px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 11px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 11px;
        }
        @media (min-width: 320px) and (max-width: 575px) {
          font-size: 10px;
        }
      }
      .MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
        box-shadow: inset 0 0 100px 100px #ffffff !important;
        font-size: 15px !important;
      }
    }
    .react-tel-input {
      & > input {
        width: 100% !important;
        min-height: 48px;
        max-height: 48px;
        border-radius: 10px;
        z-index: 1;
        border-radius: 10px;
        border-right: 0px;
        border: 0px !important;
      }
      .flag-dropdown {
        border: 0px !important;
        z-index: 1;
        background: transparent;
        &.open {
          width: 100% !important;
          ul.country-list {
            width: 100% !important;
            li {
              padding: 12px 18px !important;
              &.search {
                width: 100% !important;
                text-align: start !important;
                input {
                  width: 100%;
                  max-width: calc(100% - 18px) !important;
                  margin: 0 0 0 0;
                  height: 30px;
                  background: #fafafa;
                }
              }
              &.country {
                text-align: start !important;
              }
            }
          }
          .selected-flag {
            background: transparent;
          }
        }
        .selected-flag {
          border-radius: 10px 0 0 10px;
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
`;
const FieldLabel = styled.div`
  display: flex;
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  .red-star {
    color: red;
    font-weight: bold;
  }
`;
const FieldSubWrapper = styled.div`
  padding: 0px 15px 0px 15px;
  width: 100%;
`;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #cacaca;
  border-radius: 10px;
  position: relative;
  padding: 0px 15px 0px 15px;
  width: 100%;
`;
const SuccessWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 15px;
  z-index: 1;
  max-width: 103px;
  img {
    width: 25px;
  }
  span {
    color: #00b383;
    margin: 0 10px 0 10px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 400;
  }
  button {
    min-width: 84px;
    max-width: 84px;
  }
`;
const Errortext = styled.span`
  display: block;
  font-size: 15px;
  color: #e91344;
  letter-spacing: 0.5px;
  padding-top: 8px !important;
`;

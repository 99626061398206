import React, { useState, useContext } from "react";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import { OrdersContext } from "../OrdersContext";
import dateFormat, { masks } from "dateformat";
import { v4 as uuidv4 } from "uuid";
import empty from "./img/empty.svg";
import Card from "./card";
import "./kanban.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useEffect } from "react";

import {
  orderStatusUpdate
} from "../../../../../api/services/sap/order-management";
const Kanban = () => {
  const {
    orderTable,
    gorupedList,
    viewHandler,
    getBookingsFn } =
    useContext(OrdersContext);

    const [data, setdata] = useState([]);
    const [dateCheck, setDateCheck] = useState(false);

    // const  isLater = (dateString1, dateString2) => {
    //   if(dateString1 > dateString2){
    //     setDateCheck(true)
    //   }
    //   return console.log(dateString1 > dateString2,"...............dateString1 > dateString2"); 
      
    // }

    useEffect(() => {
    let kanbanData = gorupedList(orderTable);
    setdata([...kanbanData])
  }, [orderTable])
  // console.log(data,"...............data"); 


  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColIndex = data.findIndex((p) => p.id === source.droppableId);
      const destinationColIndex = data.findIndex((p) => p.id === destination.droppableId);
      const sourceCol = data[sourceColIndex];
      const destinationCol = data[destinationColIndex];
      const sourceTasks = [...sourceCol.tasks];
      const destinationTasks = [...destinationCol.tasks];
      const [removed] = sourceTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, removed);
      data[sourceColIndex].tasks = sourceTasks;
      data[destinationColIndex].tasks = destinationTasks;
      console.log(data[destinationColIndex].tasks, "data[destinationColIndex].tasks");
      setdata(data);
      let statusData = {
        order_id_pk: removed.order_id_pk,
        status: destinationCol.title == "Open" ? "1" : destinationCol.title == "In Progress" ? "4" : destinationCol.title == "Pending" ? "5" : destinationCol.title == "Completed" ? "6" : destinationCol.title == "Ready for Delivery" ? "7" : "8",
      };
      const statusUpdateData = orderStatusUpdate(statusData);
      statusUpdateData.then((response) => {
        getBookingsFn();
      });
    }
  };
  
  const deliveredHandler = (e) => {
    e.stopPropagation();
    const btnOrderIndex = e.target.getAttribute("btnOrderIndex");
    const btnOrderID = e.target.getAttribute("btnOrderID");
    const sourceId = "kanbanBoard5";
    const destinationId = "kanbanBoard6";
    const sourceKanban = {
      droppableId: sourceId,
      index: btnOrderIndex
    }
    const destinationKanban = {
      droppableId: sourceId,
      index: 0
    }
    const sourceColIndex = data.findIndex((p) => p.id === sourceId);
    const destinationColIndex = data.findIndex(
      (p) => p.id === destinationId
    );
    const sourceCol = data[sourceColIndex];
    const destinationCol = data[destinationColIndex];
    const sourceTasks = [...sourceCol.tasks];
    const destinationTasks = [...destinationCol.tasks];
    const [removed] = sourceTasks.splice(sourceKanban.index, 1);
    destinationTasks.splice(destinationKanban.index, 0, removed);
    data[sourceColIndex].tasks = sourceTasks;
    data[destinationColIndex].tasks = destinationTasks;
    setdata(data);
    let statusData = {
      order_id_pk: removed.order_id_pk,
      status: destinationCol.title == "Open" ? "1" : destinationCol.title == "In Progress" ? "4" : destinationCol.title == "Pending" ? "5" : destinationCol.title == "Completed" ? "6" : destinationCol.title == "Ready for Delivery" ? "7" : "8",
    };
    const statusUpdateData = orderStatusUpdate(statusData);
    statusUpdateData.then((response) => {
      getBookingsFn();
    });
  }
  // console.log(data,"................kanban res");

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="kanban">
        {data.map((section, index) => (
          <>
            {section.title == "Delivered" ? "" :
              <Droppable key={section.id} droppableId={section.id} >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    className="kanban_col"
                    kanbanblock={section.title}
                    ref={provided.innerRef}
                  >
                    <div className="kanban__section__title" title-card={section.title}><span className="sec-title">{section.title}</span> <span className="task_length">{section.tasks.length}</span></div>
                    <Scrollbars style={{ height: `calc(100vh - 230px)` }}>
                      <div className="kanban__section">
                        <div className="kanban__section__content">
                          {section.tasks.length > 0 ? <div className="tasks_block">{section.tasks.map((task, index) => (
                            <div className="kanban__sub"
                              onClick={() => viewHandler(task.order_id_pk)}
                            >
                              <Draggable
                                key={task.order_id_pk}
                                draggableId={task.order_id_pk.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div className="card_kanban"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    style={{
                                      ...provided.draggableProps.style,
                                      opacity: snapshot.isDragging ? "0.5" : "1",
                                    }}
                                  >
                                    <Card>
                                      <div className="date-time">
                                        <span>{task.service_date > dateFormat(task.created_at, "yyyy-mm-dd") && <span className="pre-booked">Pre-Booked</span>}</span><span className="service-time">{task.service_time.substring(0, task.service_time.length-3)}</span>
                                      </div>
                                      <div className="vehicle-number">
                                        {task.customerVehicle.vehicle_registration_number}
                                      </div>
                                      <div className="vehicle-details">
                                      {task.customerVehicle.vehicle.manufactures.manufacturer} {task.customerVehicle.vehicle.model} {task.customerVehicle.color}
                                      </div>
                                      <div className="services-ordered">
                                        {
                                          task.orderServices.map((item) => {
                                            return (
                                              <span className="ordered">{item.service.service_name}</span>
                                            )
                                          })
                                        }
                                      </div>
                                      <p className="updated_at_p">Updated at {dateFormat(task.updated_at, "hh:MM TT")}</p>
                                      {
                                        (section.title === "Ready for Delivery") &&
                                        <div className="mark-delivered-block">
                                          <button className="btn mark-delivered" btnOrderIndex={index} btnOrderID={task.order_id_pk} onClick={deliveredHandler}>Mark as Delivered <i className="fal fa-check ml-1"></i></button>
                                        </div>
                                      }
                                    </Card>
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          ))}</div> :
                            <div className="empty_task">
                              <div className="empty_task_main">
                                <div className="empty_task_sub">
                                  <div className="img-block">
                                    <img className="img-fluid" src={empty} />
                                  </div>
                                  <p>No Related Content</p>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                )}
              </Droppable>
            }
          </>
        ))}
      </div>
    </DragDropContext>
  );
};

export default Kanban;

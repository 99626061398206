import React, { useState, useContext } from "react";
import { BookingsContext } from "../BookingsContext";
import BookingsFilterWrapper from "./BookingsFilterWrapper";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-calendar/dist/Calendar.css";
import dateFormat, { masks } from "dateformat";

const BookingsContentTitle = (props) => {
  // let dateValues ={
  //   start_date: new Date(),
  //   end_date: new Date()
  // }
  const {
    setMappedBookingsData
  } = useContext(BookingsContext);

  const handleAddClick = () => {
    setMappedBookingsData({});
    props.handleAddBookings();
  }

  return (
    <div className="content-title-action">
      <div className="title-action">
        <div className="title-action-left">
          <h5 className="head">Bookings</h5>
          <div className="action-block">
            <button
              className="btn add-new"
              id="addBtn"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={handleAddClick}
            >
              <span>
                <i className="fal fa-plus"></i> Add New
              </span>
            </button>
            {/* <button
              className="btn btn-none"
              id=""
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={viewCalendar}
            >
              <span>
                <i className="fal fa-calendar"></i>
              </span>
            </button> */}
          </div>
        </div>
        <div className="title-action-right d-flex">
          <BookingsFilterWrapper />
          {/* {calendar && (
            <div className="popup-main">
              <div className="popup-sub">
                <div className="popup-content">
                  <div className="popup-header"></div>
                  <div className="popup">
                    <DateRangePicker
                      onChange={(e) => dateRangeFilterhandler(e)}
                      value={dateRange}
                      closeCalendar={false}
                    />
                  </div>
                  <div className="popup-footer">
                    <button className="btn">Cancel</button>
                    <button className="btn" onClick={calendarOk}>
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default BookingsContentTitle;

import React, { useState, useContext, useEffect, useRef } from "react";
import { BookingsContext } from "./BookingsContext";
import BookingsFileUploadModal from "./BookingsFileUploadModal";
import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import TimePicker from "react-time-picker";
import dateFormat, { masks } from "dateformat";
import ReactTooltip from "react-tooltip-rc";
import { Validation } from "./Ui/Validation";
import { toast } from "react-toastify";
import CancelModal from "./Ui/CancelModal";
import { submitValidation } from "./Ui/submitValidation";
import AutoSuggest from "react-autosuggest";
import Select from "react-select";

import {
  getDataByVehicleNumber,
  vehicleNumberSuggestion,
  serviceListByVehicleType,
  getVehicleModel,
  getManufacturerLIst,
  getBookingsList,
  createOrUpdateBooking,
} from "../../../../api/services/sap/bookings-management";

let initialValue = {
  vehicle_number: "",
  customer_name: "",
  phone_number: "",
  phone_extension: "",
  date: new Date(),
  time: dateFormat(new Date(), "hh:MM"),

  vehicle_manufacturer: "",
  vehicle_manufacture_year: "",
  vehicle_model: "",
  vehicle_color: "",
  cancelBook: [],

  service_booked: "",
  coupon: "",
  additional_charges: "",
  discount: "",
  total: "",
  remarks: "",

  select_service: "",
  total_rate: "",
  grand_total: "",
  pending_payment: "",
  comments: "",
  partiallyPaidPrice: "",
  payment_type: "",
  payment_status: "",
  order_payment_id: "",
  order_id: "",
  customer_id: "",
  customer_vehicle_id_fk: "",
};
const errorFields = {
  vehicle_number: "",
  customer_name: "",
  phone_number: "",
  vehicleType: "",
  vehicle_model: "",
  service_date: "",
  service_time: "",
  service: "",
  payment_type: "",
};

const BookingsForm = (props) => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [bookFilter, setBookFilter] = useState([]);
  const [final, setFinal] = useState([]);
  const multiselectRef = useRef();
  const {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    viewReadOnly,
    editMode,
    SetFormChange,
    serviceOptions,
    setServiceOptions,
    setEditMode,
    setViewReadOnly,
    handleBookingsEdit,
    // manufacturer,
    // setManufacturer,
  } = useContext(BookingsContext);

  const [formBookings, setFormBookings] = useState(initialValue);
  const [manufacturer, setManufacturer] = useState([]);

  const [model, setModel] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [vehicleType, isvehicleType] = useState("");
  const [partiallyPaid, setPartiallyPaid] = useState(false);
  const [payMethod, setPayMethod] = useState("");
  const [couponBtn, setCouponBtn] = useState("Apply Coupon");
  const [servicePrice, setServicePrice] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [couponDisplay, setCouponDisplay] = useState(false);
  const [selectService, setSelectService] = useState([]);
  const [removedService, setRemovedService] = useState([]);
  const [formErrors, SetFormErrors] = useState(errorFields);
  const [error, setError] = useState(false);

  let couponCode = "coupon";
  let couponValue = 50;
  const couponClick = (e) => {
    e.preventDefault();
  };

  const [editBookingsId, setEditBookingsId] = useState("");
  const onChangeValidate = (field) => {
    const errors = Validation(formBookings, true, field);
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setError(false);
      SetFormErrors(errorFields);
    }
  };

  const BookingsSubmitHandler = (e) => {
    e.preventDefault();
    const formData = {
      ...formBookings,
      // order_id: formBookings.order_id_pk,
      // customer_vehicle_id_fk: formBookings.customer_vehicle_id_pk,
      // customer_vehicle_id_fk: formBookings.customer_vehicle_id_pk,
      service_date: formBookings.date ? formBookings.date : new Date(),
      service_time: formBookings.time
        ? formBookings.time
        : dateFormat(new Date(), "hh:MM"),
      //order_placed_date: formBookings.date ? formBookings.date : new Date(),
      coupon_id_fk: "",
      coupon_discount: "",
      balance_amount: pendingPay,
      comments: formBookings.comments,
      actual_amount: totalPrice,
      paid_amount: formBookings.partiallyPaidPrice,
      additional_discount: formBookings.discount,
      additional_charges: formBookings.additional_charges,
      grand_total: pendingPay,
      payment_type: payMethod,
      service: selectService.map((item) => ({
        price: item.price,
        service_id: item.service_id_pk,
        order_service_id: item.order_service_id,
      })),
      removedService: removedService.map((removed) => ({
        price: removed.price,
        service_id: removed.service_id_pk,
        order_service_id: removed.order_service_id,
      })),
      // grandTotal

      // [
      //   {
      //   price: selectService.price,
      //   id: selectService.service_id_pk,
      // }],
    };

    let errors = Validation(formData, null, false);

    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      resetFormErrors();
      const create = createOrUpdateBooking({ ...formData });
      // updateOrCreateStaff(data );
      create
        .then((res) => {
          const getBookingsData = getBookingsList();
          getBookingsData &&
            getBookingsData.then((response) => {
              SetBookingsTable(response);
            });

          // props.closeform(false);
          SetFormChange(false);
          props.bookingformClose(false);
          if (formData.order_id) {
            toast.success("Booking Updated Successfully", {
              position: "bottom-right",
            });
          } else {
            toast.success("Booking Created Successfully", {
              position: "bottom-right",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.data.errors) {
            toast.error(err.data.errors[0].msg, {
              position: "bottom-right",
            });
          } else if (err.message) {
            toast.error(err.message, {
              position: "bottom-right",
            });
          } else {
            toast.error("Something went wrong!", {
              position: "bottom-right",
            });
          }
        });
    }
  };

  const resetFormErrors = () => {
    setError(false);
    SetFormErrors(errorFields);
  };

  useEffect(() => {
    const data = getManufacturerLIst();
    data.then((response) => {
      const manufacturerList =
        response &&
        response.map((item) => ({
          ...item,
          label: item.manufacturer,
          value: item.manufacturer,
        }));
      setManufacturer(manufacturerList);
    });
  }, []);

  useEffect(() => {
    fillFormData();

    // setFormBookings(rest);
    // SetFormData(mappedServiceData);
    // Unmount
    return () => {
      setFormBookings(initialValue);
      setPartiallyPaid(false);
      isvehicleType([]);
      setPayMethod([]);
      setServicePrice([]);
      setSelectService([]);
    };
  }, [props.addBookings, mappedBookingsData, editMode]);

  const fillFormData = () => {
    if (!Object.keys(mappedBookingsData).length) {
      return;
    }
    let {
      partiallyPaid,
      vehicleType,
      servicePrice,
      orderServices,
      orderPayments,
      customer,
      id,
      customerVehicle,
      selectServices,
      ...rest
    } = mappedBookingsData;

    let payment = [];
    payment =
      orderPayments &&
      orderPayments.map((payment) => {
        return payment;
      });

    setFormBookings({
      ...formBookings,
      order_id: mappedBookingsData.order_id_pk,
      vehicle_number:
        customerVehicle && customerVehicle.vehicle_registration_number,
      customer_name: customer && customer.customer_name,
      phone_number: customer && customer.phone_number,
      additional_charges: mappedBookingsData.additional_charges,
      discount: mappedBookingsData.additional_discount,
      phone_extension: mappedBookingsData.customer.phone_extension,
      comments: mappedBookingsData.comments,
      date: mappedBookingsData.service_date,
      time: mappedBookingsData.service_time,
      vehicle_color: customerVehicle && customerVehicle.color,
      vehicleType: customerVehicle && customerVehicle.vehicle.vehicle_type,
      vehicle_manufacture_year:
        customerVehicle && customerVehicle.manufactured_year,
      vehicle_manufacturer:
        customerVehicle && customerVehicle.vehicle.manufacturer,
      vehicle_model: customerVehicle && customerVehicle.vehicle.model,
      partiallyPaidPrice: mappedBookingsData.paid_amount,
      customer_id: customer && customer.customer_id_pk,
      customer_vehicle_id_fk:
        customerVehicle && customerVehicle.customer_vehicle_id_pk,
      order_payment_id:
        Object.keys(payment).length && payment[0].order_payment_id_pk,
      payment_type: Object.keys(payment).length && payment[0].payment_type,
    });
    setEditBookingsId(mappedBookingsData.customer_name);
    setPartiallyPaid(mappedBookingsData.paid_amount ? true : false);
    isvehicleType(vehicleType ? vehicleType : []);
    setServicePrice(servicePrice ? servicePrice : "");
    setPayMethod(Object.keys(payment).length && payment[0].payment_type);
    setSelectService(
      orderServices
        ? orderServices.map((item, index) => ({
            price: item.actual_price,
            order_service_id: item.order_service_id_pk,
            label: item.service.service_name,
            service_id_pk: item.service.service_id_pk,
            servicePriceDetails: [
              {
                price: item.actual_price,
              },
            ],
          }))
        : []
    );
    if (editMode && Object.keys(mappedBookingsData).length != 0) {
      getModelData(
        mappedBookingsData.customerVehicle.vehicle.manufacturer,
        mappedBookingsData.customerVehicle.vehicle.vehicle_type
      );
    }
  };

  let a = props.addBookings;
  // for Select Service states
  const handleSelectService = (selectedList, selectedItem) => {
    setSelectService(selectedList);
    // setFormBookings({ ...formBookings, select_service: e.id });
  };
  // const handleClear = () => {
  //   setServiceOptions((currentOptions) =>
  //     currentOptions.filter(
  //       (currentOption) => !formBookings.select_service.includes(currentOption)
  //     )
  //   );
  //   setFormBookings({ ...formBookings, select_service: [] });
  // };
  const removeService = (e, serviceId) => {
    let filteredService = selectService.filter(
      (el) => el.service_id_pk != serviceId
    );
    let removedService = selectService.filter(
      (el) => el.service_id_pk == serviceId
    );

    setRemovedService(removedService);
    setSelectService(filteredService);
    if (filteredService == 0) {
      multiselectRef.current.resetSelectedValues();
    }
  };
  const priceFunc = (serviceId, price) => {
    let newVar = selectService.filter((e) => {
      if (e.service_id_pk === serviceId) {
        e.price = price;
      }
      return e;
    });
    setSelectService(newVar);
  };

  let totalPrice = selectService.reduce(
    (total, selectService) => parseInt(selectService.price) + total,
    0
  );
  // let grandTotal =
  //   parseInt(totalPrice) +
  //   parseInt(formBookings.additional_charges) -
  //   parseInt(formBookings.discount);
  let grandTotal =
    parseInt(totalPrice) +
    (formBookings.additional_charges
      ? parseInt(formBookings.additional_charges)
      : 0) -
    (formBookings.discount ? parseInt(formBookings.discount) : 0);
  let pendingPay =
    parseInt(grandTotal) -
    parseInt(
      partiallyPaid && formBookings.partiallyPaidPrice
        ? formBookings.partiallyPaidPrice
        : 0
    );

  // arr.reduce((total, obj) => obj.credit + total,0)
  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    // var regex = [0-9] ;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  const selectHandler = () => {};
  const [vehicleList, setVehicleList] = useState([]);
  const getVehicleData = (formData) => {
    const data = vehicleNumberSuggestion({ vehicle_number: formData });
    data.then((res) => {
      setVehicleList(res == undefined ? [] : res);
    });
  };

  const lowerCasedVehicles = vehicleList.map((vehicle) => {
    return {
      name: vehicle.vehicle_registration_number.toLowerCase(),
    };
  });
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(value) {
    return (
      vehicleList &&
      lowerCasedVehicles.filter((vehicle) =>
        vehicle.name.includes(value.trim().toLowerCase())
      )
    );
  }

  const getCustomerData = (formData) => {
    const data = getDataByVehicleNumber({ vehicle_number: formData });
    data.then((res) => {
      setFormBookings({
        customer_name: res.customer.customer_name,
        customer_id: res.customer.customer_id_pk,
        phone_number: res.customer.phone_number,
        phone_extension: res.customer.phone_extension,
        vehicle_number: res.vehicle_registration_number,
        vehicle_manufacturer: res.vehicle.manufacturer,
        vehicle_model: res.vehicle.model,
        vehicle_color: res.color,
        vehicle_manufacture_year: res.manufactured_year,
        vehicleType: res.vehicle.vehicle_type,
        customer_vehicle_id_fk: res.customer_vehicle_id_pk,
      });
      getModelData(res.vehicle.manufacturer, res.vehicle.vehicle_type);
    });
  };
  let vehicleTypeId = null;
  const getModelData = (mid, type) => {
    if (vehicleType == "Four Wheeler") {
      vehicleTypeId = 2;
    } else if (vehicleType == "Two Wheeler") {
      vehicleTypeId = 1;
    } else {
      vehicleTypeId = 3;
    }
    // const data = getVehicleModel({ manufacturer_id: mid ,vehicle_type_id: vehicleTypeId});
    const data = getVehicleModel({
      manufacturer_id: mid,
      vehicle_type_id: type,
    });
    data
      .then((response) => {
        const modelObject = Object.assign({}, ...response);
        setModel(
          response.map((item) => ({
            ...item,
            label: item.model,
            model_id: item.vehicle_id,
          }))
        );
        setFormBookings((prevBooking) => ({
          ...prevBooking,
          vehicle_model: modelObject.model,
          model_id: modelObject.vehicle_id,
        }));
        // serviceListByVehicleType({ "vehicle_type_id": 2, "model_id": 2 })
        const typeData = serviceListByVehicleType({
          vehicle_type_id: formBookings.vehicleType
            ? formBookings.vehicleType
            : type,
          model_id: modelObject.vehicle_id,
        });
        typeData.then((response) => {
          setServiceOptions(
            response.map((item) => ({
              ...item,
              label: item.service_name,
              price: item.common_price
                ? item.common_price
                : item.servicePriceDetails.length &&
                  item.servicePriceDetails[0].price,
            }))
          );
        });
      })
      .catch((handleError) => {
        console.log(handleError.message);
        toast.warning(handleError.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const backToView = () => {
    // setEditMode(false);
    // setViewReadOnly(true);
    props.bookingformClose(false);
    setViewReadOnly(false);
    setEditMode(false);
  };

  return (
    <form className="" onSubmit={BookingsSubmitHandler}>
      <div className={viewReadOnly ? "col-12 view-mode" : "col-12"}>
        {/*Vehicle Number Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-sort-numeric-down"></i>Vehicle Number
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            {!viewReadOnly && !editMode ? (
              <AutoSuggest
                className="form-control"
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                  let newList = suggestions.map((element) => {
                    if (element.name !== value) {
                      setFormBookings({
                        customer_name: "",
                        phone_number: "",
                        vehicle_manufacturer: "",
                        vehicle_model: "",
                        vehicle_color: "",
                        vehicleType: "",
                      });
                      return element;
                    }
                    return element;
                  });
                  setSuggestions(newList);
                  setValue(value);
                  setSuggestions(getSuggestions(value));
                }}
                onSuggestionSelected={(_, { suggestionValue }) => {
                  getCustomerData(suggestionValue);
                }}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion) => (
                  <span>{suggestion.name}</span>
                )}
                inputProps={{
                  placeholder: "Eg: KL-01-AA-1010 ",
                  value: value,
                  onChange: (_, { newValue, method }) => {
                    getVehicleData(newValue);
                    setValue(newValue);
                    setFormBookings({
                      vehicle_number: newValue,
                    });
                  },
                }}
                highlightFirstSuggestion={true}
              />
            ) : (
              <input
                readOnly={viewReadOnly || editMode}
                type="text"
                className="form-control"
                value={formBookings.vehicle_number}
              />
            )}
            {error && formErrors.vehicle_number ? (
              <span className="form-error">{formErrors.vehicle_number}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Customer Name Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-user"></i>Customer Name
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            <input
              readOnly={viewReadOnly || editMode}
              type="text"
              className="form-control"
              value={formBookings.customer_name}
              onChange={(e) => {
                setFormBookings({
                  ...formBookings,
                  customer_name: e.target.value,
                });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate(e.target.value);
              }}
              onBlur={(e) => onChangeValidate("customer_name", e.target.value)}
            />
            {error && formErrors.customer_name ? (
              <span className="form-error">{formErrors.customer_name}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Phone Number Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            <div className="row m-0">
              <input
                readOnly={viewReadOnly || editMode}
                type="text"
                className="form-control phone-extension"
                placeholder="Extension"
                value={formBookings.phone_extension}
                defaultValue={formBookings.phone_extension}
                onChange={(e) =>
                  setFormBookings({
                    ...formBookings,
                    phone_extension: e.target.value,
                  })
                }
                style={{ maxWidth: "60px", marginRight: " 10px" }}
              />
              <input
                readOnly={viewReadOnly || editMode}
                type="text"
                className="form-control"
                value={formBookings.phone_number}
                onChange={(e) =>
                  setFormBookings({
                    ...formBookings,
                    phone_number: e.target.value,
                  })
                }
                onBlur={(e) => onChangeValidate("phone_number")}
              />
              {error && formErrors.phone_number ? (
                <span className="form-error">{formErrors.phone_number}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {mappedBookingsData.orderCancel && mappedBookingsData.orderCancel ? (
          <div className="">
            <div className="col-12 block-header main-title mt-4">
              <h6>
                <span className="header-title">Reason for Cancelling</span>
              </h6>
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-car"></i>Cancelled by
                </span>
              </div>
              <h6>
                {/* Need change here */}
                {mappedBookingsData.orderCancel.cancelled_by == "sap"
                  ? "Service Admin"
                  : "Customer"}
              </h6>
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-car"></i>Reason
                </span>
              </div>
              <h6>
                {mappedBookingsData.orderCancel
                  ? mappedBookingsData.orderCancel.reason
                  : ""}
              </h6>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">vehicle Details</span>
          </h6>
        </div>
        {/* vehicle Type Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-car"></i>Vehicle Type
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div
            className={`radio-title mr-4 ${
              viewReadOnly || editMode ? "pointer-events-data" : null
            }`}
          >
            <input
              // disabled={formBookings.vehicleType && formBookings.vehicleType === 2 ? true : false}
              type="radio"
              value="Two Wheeler"
              name="vehicleType"
              onChange={(e) => {
                isvehicleType(e.target.value);
                setFormBookings({
                  ...formBookings,
                  vehicleType: e.target.value === "Two Wheeler" ? 1 : 2,
                });
              }}
              checked={
                formBookings.vehicleType && formBookings.vehicleType == 1
                  ? true
                  : vehicleType == "Two Wheeler"
                  ? true
                  : false
              }
            />
            Two Wheeler
          </div>
          <div
            className={`radio-title mr-4  ${
              viewReadOnly || editMode ? "pointer-events-data" : null
            }`}
          >
            <input
              // disabled={viewReadOnly ? true : false}
              type="radio"
              value="Four Wheeler"
              name="vehicleType"
              onChange={(e) => {
                isvehicleType(e.target.value);
                setFormBookings({
                  ...formBookings,
                  vehicleType: e.target.value === "Four Wheeler" ? 2 : 1,
                });
              }}
              checked={
                formBookings.vehicleType && formBookings.vehicleType == 2
                  ? true
                  : vehicleType == "Four Wheeler"
                  ? true
                  : false
              }
            />
            Four Wheeler
          </div>
          <div className="input-block">
            {error && formErrors.vehicleType ? (
              <span className="form-error">{formErrors.vehicleType}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Manufactured Year Block*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-sitemap"></i>Manufacturer
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            <Select
              isDisabled={
                (formBookings.vehicleType || vehicleType.length) &&
                !viewReadOnly
                  ? false
                  : true
              }
              className={`form-select p-0 ${
                formBookings.vehicleType || vehicleType.length
                  ? ""
                  : "disable-select"
              }`}
              options={manufacturer}
              value={
                manufacturer &&
                manufacturer.find(
                  (x) =>
                    x.vehicle_manufacturer_id_pk ===
                    formBookings.vehicle_manufacturer
                )
              }
              onChange={(e) => {
                getModelData(
                  e.vehicle_manufacturer_id_pk,
                  formBookings.vehicleType
                );
                // SetFormData({ ...formData, vehicle_select: e.value });
              }}
            />
          </div>

          {/* <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.vehicle_manufacturer}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                vehicle_manufacturer: e.target.value,
              })
            }
          /> */}
        </div>
        {/* vehicle_manufacturer Block*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-calendar-check"></i>Manufactured Year
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.vehicle_manufacture_year}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                vehicle_manufacture_year: e.target.value,
              })
            }
          />
        </div>
        {/* Model Block*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-car"></i>Model
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            {!viewReadOnly && editMode ? (
              <Select
                isDisabled={model.length ? false : true}
                className={`form-select p-0 ${
                  model.length ? "" : "disable-select"
                }`}
                options={model}
                value={model.find(
                  (x) => x.model === formBookings.vehicle_model
                )}
                onChange={(e) => {
                  getModelData(e.vehicle_manufacturer_id_pk);
                  // SetFormData({ ...formData, vehicle_select: e.value });
                }}
              />
            ) : (
              <input
                readOnly={viewReadOnly && !editMode}
                type="text"
                className="form-control"
                value={formBookings.vehicle_model}
              />
            )}
            {error && formErrors.vehicle_model ? (
              <span className="form-error">{formErrors.vehicle_model}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* vehicle_color Block*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-palette"></i>Color
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.vehicle_color}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                vehicle_color: e.target.value,
              })
            }
          />
        </div>
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Service and Payment Details</span>
          </h6>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-calendar"></i>Date
                  {!viewReadOnly && <sup className="theme-text">*</sup>}
                </span>
              </div>

              <DatePicker
                disabled={viewReadOnly}
                selected={
                  formBookings.date ? new Date(formBookings.date) : new Date()
                }
                calendarIcon={null}
                clearIcon={null}
                format={"dd-MM-yyyy"}
                className="form-control"
                onChange={(e) =>
                  setFormBookings({
                    ...formBookings,
                    date: e,
                  })
                }
                value={
                  formBookings.date ? new Date(formBookings.date) : new Date()
                }
              />
              <div className="input-block">
                {error && formErrors.service_date ? (
                  <span className="form-error">{formErrors.service_date}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-clock"></i>Time
                  {!viewReadOnly && <sup className="theme-text">*</sup>}
                </span>
              </div>
              <TimePicker
                disabled={viewReadOnly}
                maxDetail="second"
                format="hh:mm"
                clearIcon={null}
                clockIcon={null}
                disableClock={true}
                className="form-control"
                onChange={(e) => {
                  setFormBookings({
                    ...formBookings,
                    time: e,
                  });
                }}
                value={
                  formBookings.time
                    ? formBookings.time
                    : dateFormat(new Date(), "hh:MM")
                }
              />
              <div className="input-block">
                {error && formErrors.service_time ? (
                  <span className="form-error">{formErrors.service_time}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Select Service Block */}
        <div className="input-group input-group-custom align-start">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-hand-pointer"></i>Select Service
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            {!viewReadOnly && (
              <Multiselect
                closeOnSelect={true}
                disable={formBookings.vehicle_model ? false : true}
                disabled={viewReadOnly}
                options={serviceOptions}
                selectedValues={selectService}
                displayValue="label"
                hideSelectedList
                onSelect={handleSelectService}
                isObject={true}
                // onRemove={onRemove}
                ref={multiselectRef}
                placeholder="Choose Service"
              />
            )}
            {error && formErrors.service ? (
              <span className="form-error">{formErrors.service}</span>
            ) : (
              ""
            )}
            <div className="service-list">
              {Boolean(selectService.length) &&
                selectService.map((item) => {
                  return (
                    <div
                      className="col-main-list d-flex select-service-block"
                      data-id={item.service_id_pk}
                      key={item.service_id_pk}
                    >
                      <div className="form-group col-list-row">
                        <input
                          readOnly={viewReadOnly}
                          name="variant_name"
                          value={item.label}
                          type="text"
                          className="form-control"
                          placeholder=""
                          // onChange={(e) => handleinputchange(e, i)}
                        />
                      </div>
                      <div className="form-group col-list-row">
                        <input
                          readOnly={viewReadOnly}
                          name="variant_name"
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          value={
                            item.common_price
                              ? item.common_price
                              : item.servicePriceDetails &&
                                item.servicePriceDetails.length &&
                                item.servicePriceDetails[0].price
                          }
                          // onChange={(e) => {
                          //   priceFunc(item.id, e.target.value);
                          // }}
                        />
                      </div>
                      <div className="cursor-pointer">
                        <span
                          onClick={(e) => removeService(e, item.service_id_pk)}
                          rel="tooltip"
                          data-for="removeService"
                          data-tip="Remove Service"
                          data-toggle="tooltip"
                          data-id={item.service_id_pk}
                        >
                          <i class="fal fa-times"></i>
                        </span>
                        <ReactTooltip
                          id="removeService"
                          place="left"
                          type="info"
                          effect="float"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* Total Price*/}
          {Boolean(selectService.length) && (
            <div className="select-service-block w-100">
              {/* <input
                  readOnly={viewReadOnly}
                  type="text"
                  className="form-control"
                  value={formBookings.total_rate}
                  onChange={(e) =>
                    setFormBookings({
                      ...formBookings,
                      total_rate: e.target.value,
                    })
                  }
                /> */}
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text text-center">
                    <i class="fal fa-rupee-sign"></i>Total Price
                  </span>
                </div>
                <div className="input-block coupon-input">
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    value={totalPrice}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Coupons*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-receipt"></i>Coupon Code
            </span>
          </div>
          <div className="input-block coupon-input">
            <input
              readOnly={viewReadOnly}
              type="text"
              className="form-control"
              value={formBookings.coupon}
              onChange={(e) =>
                setFormBookings({ ...formBookings, coupon: e.target.value })
              }
            />
            {/* { formBookings.coupon != couponCode ? (
              <span className="form-error">Invalid Coupon.. Try Again</span>
            ) : (
              "Valid Coupon"
            )} */}
          </div>
          {viewReadOnly ? (
            ""
          ) : (
            <div className="ml-4" onClick={couponClick}>
              <button className="btn btn-primary">{couponBtn}</button>
            </div>
          )}

          {couponDisplay && (
            <div className="w-100 text-center">
              <h6 className="text-success"> {couponAmount}</h6>
            </div>
          )}
          {/* {couponDisplay === false && (
            <div className="w-100 text-center">
              <h6 className="text-danger">InValid Coupon</h6>
            </div>
          )} */}
        </div>
        {/* Addditional Charges*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-hand-holding-usd"></i>Additional Charges
            </span>
          </div>
          <input
            onKeyPress={handleKeyPress}
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.additional_charges}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                additional_charges: e.target.value,
              })
            }
          />
        </div>
        {/* Discounts*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="far fa-percentage"></i>Additional Discounts
            </span>
          </div>
          <input
            onKeyPress={handleKeyPress}
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.discount}
            onChange={(e) =>
              setFormBookings({ ...formBookings, discount: e.target.value })
            }
          />
        </div>
        {/* Grand Total*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-coins"></i>Grand Total
            </span>
          </div>
          {/* <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.grand_total}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                grand_total: e.target.value,
              })
            }
          /> */}
          <h6>{grandTotal ? grandTotal : 0}</h6>
          {/* <h6>{formBookings.grand_total}</h6> */}
        </div>
        {/* Partially Paid (Checkbox)*/}
        <div className="input-group input-group-custom">
          <div className="radio-title mr-4" style={{ minWidth: "135px" }}>
            <input
              style={{
                margin: "0 15px 0 -5px",
                minWidth: "20px",
                textAlign: "left",
              }}
              disabled={viewReadOnly}
              type="checkbox"
              value="Two Wheeler"
              name="vehicleType"
              onChange={() => setPartiallyPaid(!partiallyPaid)}
              checked={partiallyPaid}
            />
            {viewReadOnly ? "Advance Paid" : "Advance Pay"}
          </div>

          <input
            disabled={partiallyPaid ? false : true}
            onKeyPress={handleKeyPress}
            readOnly={viewReadOnly}
            type="text"
            // className="form-control pointer-events"
            className={`form-control ${partiallyPaid ? "" : "pointer-events"}`}
            value={formBookings.partiallyPaidPrice}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                partiallyPaidPrice: e.target.value,
              })
            }
          />
        </div>
        {/* Pending Payment*/}
        {partiallyPaid && formBookings.partiallyPaidPrice ? (
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-car"></i>Pending Payment
              </span>
            </div>
            <h6>{pendingPay ? pendingPay : 0}</h6>
            {/* <input
            readOnly={viewReadOnly}
            type="text"
            className={`form-control ${partiallyPaid ? "" : "pointer-events"}`}
            value={formBookings.pending_payment}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                pending_payment: e.target.value,
              })
            }
          /> */}
          </div>
        ) : (
          ""
        )}

        {/* Mode of Payment */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-wallet"></i>Mode of Payment
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="radio-title mr-4">
            <input
              // disabled={viewReadOnly}
              type="radio"
              value="cash"
              name="payMethod"
              onChange={(e) => setPayMethod(e.target.value)}
              checked={payMethod === "cash"}
            />
            Cash
          </div>
          <div className="radio-title mr-4">
            <input
              disabled={viewReadOnly}
              type="radio"
              value="upi"
              name="payMethod"
              onChange={(e) => setPayMethod(e.target.value)}
              checked={payMethod === "upi"}
            />
            UPI
          </div>
          <div className="radio-title mr-4">
            <input
              disabled={viewReadOnly}
              type="radio"
              value="card"
              name="payMethod"
              onChange={(e) => setPayMethod(e.target.value)}
              checked={payMethod === "card"}
            />
            Card
          </div>
          <div className="input-block">
            {error && formErrors.payment_type ? (
              <span className="form-error">{formErrors.payment_type}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Comments*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-comments-alt"></i>Comments
            </span>
          </div>
          <div className="input-block">
            <textarea
              readOnly={viewReadOnly}
              className="form-control"
              value={formBookings.comments}
              onChange={(e) =>
                setFormBookings({
                  ...formBookings,
                  comments: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
        {viewReadOnly ? (
          ""
        ) : (
          <div className="d-flex justify-content-end mb-4 mt-4">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={backToView}
            >
              Cancel
            </button>
            <button className="btn btn-primary ml-3">
              {editMode ? "Update Booking" : "Create Booking"}
            </button>
          </div>
        )}

        {/* {cancelModal && <CancelModal setCancelModal={setCancelModal} />} */}
      </div>
      <BookingsFileUploadModal
        setShow={setShow}
        showfilemodal={show}
        hidefilemodal={handleClose}
      />
    </form>
  );
};

export default BookingsForm;

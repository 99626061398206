import React, { useState, useContext, useEffect, Fragment } from "react";
import { Table } from "react-bootstrap";
import { OrdersContext } from "../OrdersContext";
import qrImage from "../../../../../assets/img/qr-image/1.webp";
import upiImage from "../../../../../assets/img/qr-image/2.jpg";
import ReactTooltip from "react-tooltip-rc";
import {
  viewMappedBooking,
  updatePayments,
} from "../../../../../api/services/sap/bookings-management";

const PaymentDetailstab = () => {
  const { innertab, mappedOrderData } = useContext(OrdersContext);
  const [mappedPayment, setMappedPayment] = useState([]);
  const [paymentForm, setPaymentForm] = useState([]);
  const [payView, setPayView] = useState(false);
  const [checkedValues, setCheckedValues] = useState({
    payment_type: "",
    payment_notes: "",
  });

  //useEffect for payment details
  useEffect(() => {
    const mapData = viewMappedBooking(mappedOrderData.order_id_pk);
    mapData.then((res) => {
      setPaymentForm(res);
    });
  }, []);
  console.log(paymentForm, "res paymentForm  payment-------------");

  //totalPrice
  // let totalPrice = selectService.reduce(
  //   (total, selectService) => parseInt(selectService.price) + total,
  //   0
  // );
  //grand total
  let grandTotal =
    (paymentForm &&
      paymentForm.rate_total &&
      parseInt(paymentForm.rate_total)) +
    (paymentForm && paymentForm.additional_charges
      ? parseInt(paymentForm.additional_charges)
      : 0) -
    (paymentForm && paymentForm.additional_discount
      ? parseInt(paymentForm.additional_discount)
      : 0);

  //payable amount
  let payableAmt =
    (paymentForm &&
      paymentForm.rate_total &&
      parseInt(paymentForm.rate_total)) +
    (paymentForm && paymentForm.additional_charges
      ? parseInt(paymentForm.additional_charges)
      : 0) -
    (paymentForm && paymentForm.paid_amount
      ? parseInt(paymentForm.paid_amount)
      : 0) -
    (paymentForm && paymentForm.additional_discount
      ? parseInt(paymentForm.additional_discount)
      : 0);

  //toggle payment fn
  const togglePaymenthandler = () => {
    setPayView(!payView);
  };
  const checkPaymentHandler = (e, value) => {
    // console.log("e.target.checked", e.target.checked, "value", value);
  };

  //Checkbox handler
  const handleCheckHandler = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    // console.log(value, checked, "Checkbox Test");
    if (checked) {
      setCheckedValues({ ...checkedValues, payment_type: value });
    }
  };
  // console.log(checkedValues, "checkedValues outside");

  const paymentSubmithandler = (e) => {
    e.preventDefault();
    // setMappedOrderData([])
    setPaymentForm({ ...paymentForm });
    const data = updatePayments({
      service_admin_id: paymentForm.service_admin_id_fk,
      order_id: paymentForm.order_id_pk,
      service_date: paymentForm.service_date,
      grand_total: parseInt(grandTotal),
      additional_charges: parseInt(paymentForm.additional_charges),
      additional_discount: parseInt(paymentForm.additional_discount),
      paid_amount: parseInt(payableAmt),
      payment_type: checkedValues.payment_type,
      payment_notes: checkedValues.payment_notes,
      payment_status: 0,
    });
    // console.log(data, "datadatadatadatadata");
    data.then((res) => {});

    // console.log(payableAmt, "payableAmt  on submit -------------");
    // console.log(paymentForm, "paymentForm on submit -------------");
  };

  // console.log(paymentForm, "paymentForm payment tab-------------");
  return (
    <div
      className={innertab === 4 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <div className="inner-sub-block">
          <form className="" onSubmit={paymentSubmithandler}>
            {!payView ? (
              <div>
                <div className="pre-sub-block">
                  <Table className="mb-3" striped bordered hover={false}>
                    <thead>
                      <tr>
                        <th>Service Details</th>
                        <th>Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentForm && paymentForm.orderServices
                        ? paymentForm.orderServices.map((item, index) => {
                            return (
                              <tr key={index} className="text-left">
                                <td>{item.service.service_name}</td>
                                <td>{item.actual_price}</td>
                              </tr>
                            );
                          })
                        : ""}
                      <tr className="text-left">
                        <td>{"RATE TOTAL"}</td>
                        <td>{paymentForm.rate_total}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {paymentForm.grand_total && paymentForm.grand_total !==0 ?
                <div className="inner-payment-block">
                  <div className="pt-3">
                    {/* Add Coupon Code */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Add Coupon Code :
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Coupon Code"
                          onChange={(e) => {
                            setPaymentForm({
                              ...paymentForm,
                              coupon_discount: e.target.value,
                            });
                          }}
                          value={
                            paymentForm && paymentForm.coupon_discount
                              ? paymentForm.coupon_discount
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {/* Additional Discounts */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Additional Discounts :
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Discount"
                          readOnly={paymentForm ? true : false}
                          disabled={paymentForm ? true : false}
                          onChange={(e) => {
                            setPaymentForm({
                              ...paymentForm,
                              additional_discount: e.target.value,
                            });
                          }}
                          value={
                            paymentForm && paymentForm.additional_discount
                              ? paymentForm.additional_discount
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {/* Additional Charges */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Additional Charges :
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Additional charges"
                          readOnly={paymentForm ? true : false}
                          disabled={paymentForm ? true : false}
                          onChange={(e) => {
                            setPaymentForm({
                              ...paymentForm,
                              additional_charges: e.target.value,
                            });
                          }}
                          value={
                            paymentForm && paymentForm.additional_charges
                              ? paymentForm.additional_charges
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {/* Grand Total */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Grand Total :</span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setPaymentForm({
                              ...paymentForm,
                              actual_amount: e.target.value,
                            });
                          }}
                          value={
                            grandTotal
                            // paymentForm && paymentForm.actual_amount
                            //   ? paymentForm.actual_amount
                            //   : ""
                          }
                        />
                      </div>
                    </div>
                    {/* Payable Amount */}
                    {/* <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Payable Amount :</span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        // onChange={(e) => {
                        //   setPaymentForm({
                        //     ...paymentForm,
                        //     payable_amount: e.target.value,
                        //   });
                        // }}
                        value={parseInt(payableAmt)
                          
                          // paymentForm && paymentForm.payable_amount
                          //   ? paymentForm.payable_amount
                          //   : ""
                        }
                      />
                    </div>
                  </div> */}
                    {/* Partially Paid */}
                    {paymentForm && paymentForm.orderPayments
                      ? paymentForm.orderPayments.map((data) => {
                          return (
                            <div>
                              {data.paid_amount && data.paid_amount > 0 ? (
                                <div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        {data.type == "bookings"
                                          ? "Partially Paid"
                                          : "Paid Amount"}
                                      </span>
                                    </div>
                                    <div className="input-block">
                                      <input
                                        readOnly
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                          setPaymentForm({
                                            ...paymentForm,
                                            paid_amount: e.target.value,
                                          });
                                        }}
                                        value={
                                          data.paid_amount
                                          // paymentForm && paymentForm.paid_amount
                                          //   ? paymentForm.paid_amount
                                          //   : ""
                                        }
                                      />
                                    </div>
                                  </div>
                                  {data.type == "bookings" ? (
                                    <div className="">
                                      <div className="input-group input-group-custom">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Paid using
                                          </span>
                                        </div>
                                        <p>{data.payment_type}</p>
                                        {/* <div className="radio-title mr-4">
                                      <input
                                        type="radio"
                                        value=""
                                        name="Cash"
                                        checked={
                                        data.payment_type == "cash"
                                            ? true
                                            : false
                                        }
                                      />
                                      Cash
                                    </div>
                                    <div className="radio-title mr-4">
                                      <input
                                        type="radio"
                                        value=""
                                        name="Cash"
                                        checked={
                                          data.payment_type == "upi"
                                            ? true
                                            : false
                                        }
                                      />
                                      Upi Payment
                                    </div>
                                    <div className="radio-title mr-4">
                                      <input
                                        type="radio"
                                        value=""
                                        name="Card"
                                        checked={
                                          data.payment_type == "card"
                                            ? true
                                            : false
                                        }
                                      />
                                      Card
                                    </div> */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              {/* Radio button */}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="col-12 payment-footer">
                    <div>
                      <span>Amount to be paid:</span>
                      <span>{payableAmt}</span>
                    </div>
                    {/* <div>
                    <button
                      class="btn btn-update"
                      onClick={togglePaymenthandler}
                    >
                      <i class="fal fa-save mr-2"></i>Proceed to Payment
                    </button>
                  </div> */}
                  </div>
                  <div className="inner-sub-block">
                    {paymentForm &&
                    paymentForm.orderPayments &&
                    Object.keys(paymentForm.orderPayments).length > 0 ? (
                      paymentForm.orderPayments.map((data) => {
                        return (
                          <div>
                            {data.type === "orders" ? (
                              <div className="">
                                {/* Radio button */}
                                <div className="input-group input-group-custom">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      Payment Mode
                                    </span>
                                  </div>
                                  <div className="radio-title mr-4">
                                    <input
                                      type="radio"
                                      value="cash"
                                      name="paymentradio"
                                      checked={
                                        data.payment_type == "cash"
                                          ? true
                                          : false
                                      }
                                      onClick={handleCheckHandler}
                                    />
                                    Cash
                                  </div>
                                  <div className="radio-title mr-4">
                                    <input
                                      type="radio"
                                      value="upi"
                                      name="paymentradio"
                                      checked={
                                        data.payment_type == "upi"
                                          ? true
                                          : false
                                      }
                                      onClick={handleCheckHandler}
                                    />
                                    UPI
                                  </div>
                                  <div className="radio-title mr-4">
                                    <input
                                      type="radio"
                                      value="card"
                                      name="paymentradio"
                                      checked={
                                        data.payment_type == "card"
                                          ? true
                                          : false
                                      }
                                      onClick={handleCheckHandler}
                                    />
                                    Card
                                  </div>
                                </div>
                              </div>
                            ) : (
                              data.type !== "bookings" && (
                                <div className="">
                                  {/* Radio button */}
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Payment Mode
                                      </span>
                                    </div>
                                    <div className="radio-title mr-4">
                                      <input
                                        type="radio"
                                        value="cash"
                                        name="paymentradio"
                                        onClick={handleCheckHandler}
                                      />
                                      Cash
                                    </div>
                                    <div className="radio-title mr-4">
                                      <input
                                        type="radio"
                                        value="upi"
                                        name="paymentradio"
                                        onClick={handleCheckHandler}
                                      />
                                      UPI
                                    </div>
                                    <div className="radio-title mr-4">
                                      <input
                                        type="radio"
                                        value="card"
                                        name="paymentradio"
                                        onClick={handleCheckHandler}
                                      />
                                      Card
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="">
                        {/* Radio button */}
                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Payment Mode
                            </span>
                          </div>
                          <div className="radio-title mr-4">
                            <input
                              type="radio"
                              value="cash"
                              name="paymentradio"
                              onClick={handleCheckHandler}
                            />
                            Cash
                          </div>
                          <div className="radio-title mr-4">
                            <input
                              type="radio"
                              value="upi"
                              name="paymentradio"
                              onClick={handleCheckHandler}
                            />
                            UPI
                          </div>
                          <div className="radio-title mr-4">
                            <input
                              type="radio"
                              value="card"
                              name="paymentradio"
                              onClick={handleCheckHandler}
                            />
                            Card
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="qr-block-block">
                      <div className="qr-img-block">
                        <h6>
                          Pay <span>{payableAmt}</span>
                        </h6>
                        <img className="img-fluid qr-img" src={qrImage} />
                      </div>
                      <div className="qr-img-block">
                        <img className="img-fluid qr-img" src={upiImage} />
                      </div>
                    </div>
                    {/* payment notes */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-pen"></i>Notes
                        </span>
                      </div>
                      <div className="input-block">
                        <textarea
                          // readOnly={previewonly ? true : false}
                          className="form-control"
                          placeholder="Notes"
                          onChange={(e) => {
                            setCheckedValues({
                              ...checkedValues,
                              payment_notes: e.target.value,
                            });
                          }}
                          // value={
                          //   preformvalues && preformvalues.customer_note
                          //     ? preformvalues.customer_note
                          //     : ""
                          // }
                        ></textarea>
                        {/* <span className="form-error">Address is required!</span> */}
                      </div>
                    </div>
                    <div className="col-12 payment-footer">
                      <div className="submit-block">
                        <button classname="btn btn-update" type="submit">
                          <i class="fal fa-save mr-2"></i>MARK AS PAID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>:
                <div className="col-12">
                  <div className="text-left">
                    <p>Payment Completed</p>
                  </div>
                  </div>}
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailstab;

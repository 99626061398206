import React from "react";
import ReactTooltip from "react-tooltip-rc";
import StaffFilterWrapper from "./StaffFilterWrapper";
const StaffContentTitle = (props) => {
  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
          <h5 class="head">Staff</h5>
          <div class="action-block">
            <button
              className="btn add-new "
              id="addBtn"
              rel="tooltip"
              data-for="addTooltip"
              data-tip="Add New"
              onClick={props.handleAdd}
            >
              <span>
                <i class="fal fa-plus"></i> Add New
              </span>
            </button>
            <ReactTooltip
              id="addTooltip"
              place="bottom"
              type="info"
              effect="float"
            />
          </div>
        </div>
        <div class="title-action-right d-flex">
          <StaffFilterWrapper />
          {/* <button
          className="btn btn-card"
          rel="tooltip"
          data-toggle="tooltip"
          title="view"
          view-click="service"
        >
          <span>
            <i className="fas fa-table"></i>
          </span>
        </button> */}
        </div>
      </div>
    </div>
  );
};

export default StaffContentTitle;

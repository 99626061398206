import React from "react";
import styled from "styled-components";
import RoundedWrapperCard from "../../../../../../components/RoundedWrapperCard";
import Grid from "@mui/material/Grid";
import { MuiStyledTextField } from "../../../../../../components/MuiStyledTextField";
import { FileUploadButton } from "../../../../../../components/FileUploadButton";
import PhoneNumberField from "../../../../../../components/PhoneNumberField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

type Props = {
  isCoOwnerAdd?: boolean;
  data?: object;
  index?: number;
  CoOwnerData?: {} | null;
  setCoOwnerData: React.Dispatch<React.SetStateAction<{} | null | undefined>>;
};

const CoOwnerComponent = ({ data, CoOwnerData, setCoOwnerData }: Props) => {
  const [value, setValue] = React.useState("No");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleNationalIdUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handlePassportUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handlevisaUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  React.useEffect(() => {
    if (data) {
      setCoOwnerData(data);
    }
  }, [data]);
  console.log(CoOwnerData, "CoOwnerList");
  console.log(data, "data");

  return (
    <RoundedWrapperCard
      className="RoundedWrapperCard"
      style={{ marginBottom: "30px" }}
    >
      <StyledGridWrapper container spacing={2}>
        <Grid item xs={6}>
          <MuiStyledTextField
            label="Co-Owner Name"
            placeholder="Enter your name"
            className="background-gray"
            onChange={(e) => {
              if (CoOwnerData)
                setCoOwnerData({ ...CoOwnerData, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6} className="styled-PhoneNumberField">
          <PhoneNumberField
            label="Co-Owner Contact Number"
            placeholder="Enter your contact number"
            onChange={(e) => {
              // if (CoOwnerData) console.log(e, "eeeeeeeeeeeeeeeeeee");

              setCoOwnerData({ ...CoOwnerData, phone: e });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <RadioGroupWrapper>
            <FormControl>
              <FieldLabel>
                Are you a resident of United Arab Emirates (UAE)?{" "}
                <span className="validField">*</span>
              </FieldLabel>
              <RadioButtonWrapper className="RadioButtonWrapper">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes, I am a resident of UAE"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No, I do not reside in UAE"
                  />
                </RadioGroup>
              </RadioButtonWrapper>
            </FormControl>
          </RadioGroupWrapper>
        </Grid>

        <Grid item xs={6}>
          <FieldLabel>
            Upload your <span>Emirates National ID </span>(Applicable only if
            you are a resident of UAE)
          </FieldLabel>
          <UploadFieldWrapper>
            <FileUploadButton onFileUpload={handleNationalIdUpload} />
          </UploadFieldWrapper>
        </Grid>
        <Grid item xs={6}>
          <FieldLabel>
            Upload a copy of <span>your passport </span>for documentation
            purposes
          </FieldLabel>
          <UploadFieldWrapper>
            <FileUploadButton onFileUpload={handlePassportUpload} />
          </UploadFieldWrapper>
        </Grid>
        <Grid item xs={6}>
          <FieldLabel>
            Upload a copy of<span> your visa</span> for documentation purposes
          </FieldLabel>
          <UploadFieldWrapper>
            <FileUploadButton onFileUpload={handlevisaUpload} />
          </UploadFieldWrapper>
        </Grid>
      </StyledGridWrapper>
    </RoundedWrapperCard>
  );
};
export default CoOwnerComponent;

const StyledGridWrapper = styled(Grid)`
  /* display: "block"; */
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
    }
  }
`;
const RadioGroupWrapper = styled.div`
  display: "block";
  text-align: left;
`;
const RadioButtonWrapper = styled.div`
  padding: 0 20px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    padding: 3px 5px 3px 0;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  span {
    font-weight: 600;
    color: #000000;
  }
  .validField {
    color: #ff0000;
  }
`;
const UploadFieldWrapper = styled.div`
  padding: 5px 15px;
`;

import React, { useState, useContext, useEffect, Fragment, useRef } from "react";
import { OrdersContext } from "../OrdersContext";
import { Dropdown, DropdownButton, Accordion, Button, useAccordionButton, Card, Stack, Row, Col } from "react-bootstrap";
import dateFormat from "dateformat";
import Multiselect from "multiselect-react-dropdown";
import ReactTooltip from "react-tooltip-rc";
import TimePicker from "react-time-picker";
import {
  assignStaffs,
  orderStatusUpdate,
  trackServiceTime,
  saveEstimatedTime
} from "../../../../../api/services/sap/order-management";

const ServiceTab = () => {

  const {
    innertab,
    mappedOrderData,
    setStartTime,
    setPauseTime,
    staffTable,
    viewHandler,
    preformvalues, setPreFormValues
  } = useContext(OrdersContext);

  const [dropValue, setDropValue] = useState("");
  const [startBtn, setStartBtn] = useState(true);
  const [pauseBtn, setPauseBtn] = useState(false);
  const [resumeBtn, setResumeBtn] = useState(false);
  const [finishBtn, setFinishBtn] = useState(false);
  const [mappedServicesData, setMappedServicesData] = useState([]);
  const [mappedStaffData, setMappedStaffData] = useState([]);
  const [mappedTiming, setMappedTiming] = useState([]);
  const statusChange = (e) => {
    let statusValue = e.target.getAttribute("statusValue");
    let statusData = {
      order_id_pk: mappedOrderData.order_id_pk,
      status: statusValue,
    };
    const statusUpdateData = orderStatusUpdate(statusData);
    statusUpdateData.then((response) => {
      viewHandler(mappedOrderData.order_id_pk);
    });
  };
  const staffDetails = [...staffTable]
  const clicking = (e) => {
    e.preventDefault();
  }
  useEffect(() => {
    const mappedServices = mappedOrderData && mappedOrderData.orderServices.map((item) => ({ ...item }));
    const mappedStaff = mappedServices.map((e) => [
      ...e.orderServicesStaff
    ]);
    setMappedServicesData(mappedServices);
    setMappedStaffData(mappedStaff);
  }, [mappedOrderData])

  const multiselectRef = useRef([]);
  // const buttonChange = () =>{
  //   let newList = orderTable.map((element) => {
  //     if (element.order_id_pk == removed.order_id_pk) {
  //       element.order_status = destinationCol.title;
  //       return element;
  //     }
  //     return element;
  //   });
  // }
  const handleSelectStaff = (index, element, serviceId, value) => {
    let staffAdd = {
      order_service_id_fk: serviceId,
      staff_id_fk: element,
      deleted: value
    };
    const staffAddData = assignStaffs(staffAdd);
    staffAddData.then((response) => {
      viewHandler(mappedOrderData.order_id_pk);
      if (mappedStaffData[index].length == 1 && value == "1") {
        // setMappedStaffData({ ...mappedStaffData, ...response });
      }
    });
  }

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log(''),
    );
    return (
      <div className="block-click"
        onClick={decoratedOnClick}
      >{children}</div>
    );
  }

  const trackTiming = (timeType, order_service) => {
    let timeAdd = {
      order_service_id_fk: order_service,
      type: timeType
    };
    const timeData = trackServiceTime(timeAdd);
    timeData.then((response) => {
      viewHandler(mappedOrderData.order_id_pk);
    });
  }
  const estimatedTimeHandler = (e, orderServiceId) => {
    console.log(e, orderServiceId, "e-orderServiceId");

    let estimatedTime = {
      order_service_id_pk: orderServiceId,
      estimated_time: e
    };

    const estimatedTimeData = saveEstimatedTime(estimatedTime);
    estimatedTimeData.then((response) => {
    });

  }
  console.log(preformvalues && preformvalues.customer_note, "--------------------------------------------");
  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <Fragment>
          <div className="inner-stack-header" direction="horizontal" gap={2} >
            <div className="stack-card">
              <Row>
                <Col xs={12}>
                  {mappedOrderData && mappedOrderData.comments ? <textarea class="form-control textarea-custom" disabled>{mappedOrderData.comments}</textarea> : ""}
                  {preformvalues && preformvalues.customer_note ? <textarea class="form-control textarea-custom" disabled>{preformvalues.customer_note}</textarea> : ""}
                </Col>
              </Row>
            </div>
          </div>
          <div className="text-end">
            <div className="status-change-block">
              <DropdownButton
                title={dropValue ? dropValue : mappedOrderData.orderStatus.title}
                id="dropdown-menu-align-end"
              >
                <Dropdown.Item
                  eventKey="1"
                  statusValue="4"
                  onClick={statusChange}
                >
                  In Progress
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  statusValue="5"
                  onClick={statusChange}
                >
                  Pending
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  statusValue="6"
                  onClick={statusChange}
                >
                  Completed
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="8"
                  statusValue="Delivered"
                  onClick={statusChange}
                >
                  Delivered
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  statusValue="7"
                  onClick={statusChange}
                >
                  Ready for Delivery
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="accoBlock">
            <Accordion defaultActiveKey="0">
              <div className="row w-100 m-0 header-row">
                <div className="col-md-3"></div>
                <div className="col-md-3 col-text text-left p-0">Assignee</div>
                <div className="col-md-3 col-text">Estimated Time</div>
                <div className="col-md-3 col-text">Actions</div>
              </div>

              {mappedServicesData && mappedServicesData.map((item, index) => {
                return (
                  <Card>
                    <Card.Header>

                      <div className="row w-100 m-0">
                        <div className="col-md-3 p-0">
                          <CustomToggle eventKey={index}>{item.service.service_name}</CustomToggle>
                        </div>
                        <div className="col-md-3 p-0">
                          <div className="circle-main">
                            <div className="staff-assign">
                              {
                                mappedStaffData && mappedStaffData[index].map((b_item) => (
                                  <>
                                    <div className="staff-circle" rel="tooltip" data-for="staffTooltip" data-tip={b_item.name}>
                                      <ReactTooltip
                                        id="staffTooltip"
                                        place="top"
                                        type="info"
                                        effect="float"
                                      />
                                      {item.status != "Finished" &&
                                        <span className="delete-span"
                                          onClick={(e) => handleSelectStaff(index, b_item.staff_id_fk, item.order_service_id_pk, "1")}
                                        ><i className="fal fa-times"></i></span>}
                                      {b_item.name && b_item.name.substring(0, 1)}</div>
                                  </>
                                ))
                              }
                            </div>
                            <div className="staff-list">
                              {item.status != "Finished" &&
                                <Multiselect
                                  options={staffDetails}
                                  selectedValues={mappedStaffData[index]}
                                  displayValue="name"
                                  disable={item.status == "Finished" ? true : false}
                                  hideSelectedList
                                  onSelect={(e) =>
                                    handleSelectStaff(index, Object.assign({}, ...e).id, item.order_service_id_pk, "0")
                                  }
                                  closeOnSelect={true}
                                  isObject={true}
                                  ref={multiselectRef}
                                  name="test-name"
                                  placeholder="+"
                                />}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 p-0">
                          <div className="estimated-time">
                            <TimePicker
                              clearIcon={null}
                              clockIcon={null}
                              disableClock={true}
                              onChange={(e) =>
                                estimatedTimeHandler(e, item.order_service_id_pk)
                              }
                              maxDetail="minute"
                              hourAriaLabel="Hour"
                              hourPlaceholder="hh"
                              minuteAriaLabel="Minute"
                              minutePlaceholder="mm"
                              format="hh:mm"
                              minTime="00:30"
                              value={item.estimated_time == null ? "00:00" : item.estimated_time}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 p-0">
                          <div className="button-block">
                            {Object.keys(mappedStaffData[index]).length !== 0 && item.status == null &&
                              <Button
                                className="ml-1 mr-1"
                                variant="primary"
                                size="sm"
                                onClick={(e) => {
                                  trackTiming("Started", item.order_service_id_pk);
                                }}
                              >Start</Button>}
                            {item.status == "Started" ? <><Button
                              className="ml-1 mr-1"
                              variant="warning"
                              size="sm"
                              onClick={(e) => {
                                trackTiming("Paused", item.order_service_id_pk);
                              }}
                            >Pause</Button><Button
                              className="ml-1 mr-1"
                              variant="success"
                              size="sm"
                              onClick={(e) => {
                                trackTiming("Finished", item.order_service_id_pk);
                              }}
                            >
                                Finish
                              </Button></> : item.status == "Paused" ? <Button
                                className="ml-1 mr-1"
                                variant="success"
                                size="sm"
                                onClick={(e) => {
                                  trackTiming("Resumed", item.order_service_id_pk);
                                }}
                              >
                                Resume
                              </Button> : item.status == "Resumed" ? <><Button
                                className="ml-1 mr-1"
                                variant="warning"
                                size="sm"
                                onClick={(e) => {
                                  trackTiming("Paused", item.order_service_id_pk);
                                }}
                              >Pause</Button><Button
                                className="ml-1 mr-1"
                                variant="success"
                                size="sm"
                                onClick={(e) => {
                                  trackTiming("Finished", item.order_service_id_pk);
                                }}
                              >
                                  Finish
                                </Button></> : item.status == "Finished" && ""}
                            {item.status == "Finished" && <span class="badge bg-success p-2">Finished</span>}
                          </div>
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body>
                        <div className="progressList-card">
                          <ul className="progess-ul">
                            {
                              item.orderServiceTracking.map((timeList) => (
                                <>
                                  {
                                    timeList.type == "Paused" || timeList.type == "Finished" ? <><li>{item.orderServiceTracking.length == 1 ? "Started" : "Resumed"} at {dateFormat(timeList.start_time, "h:MM tt")} on {dateFormat(timeList.start_time, "mmmm dS")} by {timeList.startedBy}</li>  <li>{timeList.type} at {dateFormat(timeList.end_time, "h:MM tt")} by {timeList.endedBy}</li></> : <li> {timeList.type} at {dateFormat(timeList.start_time, "h:MM tt")} by {timeList.startedBy}</li>
                                  }

                                </>
                              ))
                            }
                          </ul>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                );
              })}
            </Accordion>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default ServiceTab;

import { commonAxios } from "../../axios";


const getLookupDataAPI = async (data) => {
    const axiosInst = commonAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'get-lookup-data',
            data
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getVehicleVariantsAPI = async (data) => {
    const axiosInst = commonAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'get-vehicle-variants',
            data
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export { getLookupDataAPI, getVehicleVariantsAPI };
// import {ContentHeader,FilterWrapper,ContentTitle,WindowLeft,WindowRight,InnerLoader} from './Ui';
import Layout from "../../Layout/Layout";
import BookingsContentTitle from "./Ui/BookingsContentTitle";
import BookingsWindowLeft from "./BookingsWindowLeft";
import BookingsWindowRight from "./BookingsWindowRight";
import BookingsInnerLoader from "./Ui/BookingsInnerLoader";
import React, { useState, useEffect } from "react";
import { BookingsProvider } from "./BookingsContext";
import mainLoader from "../../../../assets/img/loader/loader.gif";
import ContentHeader from "../../Layout/Ui/ContentHeader";

const Bookings = () => {
  const [addBookings, SetAddBookings] = useState(false);
  const handleAddBookings = () => SetAddBookings(!addBookings);
  let toggleClassCheck = addBookings ? " change" : "";
  const [pageloader, SetPageLoader] = useState(false);
  //Use Effect Loader
  useEffect(() => {
    SetPageLoader(true);
    const loaderTime = setTimeout(() => {
      SetPageLoader(false);
    }, 1500);
    return () => {
      clearTimeout(loaderTime);
    };
  }, []);

  return (
    <>
      {
        pageloader
          ?
          (
            <div className="loader-block" >
              <img className="img-fluid" src={mainLoader} />
            </div >
          ) :
          (
            <Layout>
              <BookingsProvider>
                <div className="content-block">
                  <BookingsInnerLoader />
                  <section className="main-section">
                    <div className="container-fluid p-0">
                      <ContentHeader />
                      <div className="col-12 p-0 page-window">
                        <BookingsContentTitle handleAddBookings={handleAddBookings} />
                        <div className="content-section" view-content="service">
                          <div className={`content-window ${toggleClassCheck} `}>
                            <BookingsWindowLeft
                              handleAddBookings={handleAddBookings}
                              bookingformClose={SetAddBookings}
                            />
                            {addBookings && (
                              <BookingsWindowRight
                                bookingformClose={SetAddBookings}
                                addBookings={addBookings}
                                handleAddBookings={handleAddBookings}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </BookingsProvider>
            </Layout>
          )
      }
    </>
  );
};

export default Bookings;

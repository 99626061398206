import React, { useState, useContext, useEffect, Fragment } from "react";
import { OrdersContext } from "../OrdersContext";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import PreServiceImageModal from "./PreServiceImageModal";
import configData from "../../../../../config.json";
import {
  saveOrderInspection,
  getOrderInspection,
} from "../../../../../api/services/sap/order-management";

const PreServiceInspectionTab = () => {
  //Form state

  const [formsubmit, setFormSubmit] = useState(false);
  const [preform, setPreForm] = useState([]);
  const [error, setError] = useState(false);
  const [editView, setEditView] = useState(false);
  const [preview, setPreview] = useState(true);
  const {
    innertab,
    setPreServicePic,
    handleShow,
    mappedOrderData,
    previewonly,
    setPreViewOnly,
    preFormWindow,
    setPreFormWindow,
    preInitial,
    preformvalues,
    setPreFormValues,
  } = useContext(OrdersContext);

  useEffect(() => {
    setPreViewOnly(false);
    const data = getOrderInspection(mappedOrderData.order_id_pk, "pre");
    data
      .then((res) => {
        if (res) {
          setPreFormValues(res);
          if (Object.keys(res.orderInspectionComment).length) {
            setPreFormWindow([]);
          }
          setPreViewOnly(true);
          setEditView(false);
        }
      })
      .catch((err) => {
        setPreFormWindow([preInitial]);
        setPreViewOnly(false);
        setEditView(true);
      });
  }, []);

  //image note handler
  const imageNoteHandler = (value, dataIndex, type) => {
    if (type === "edit") {
      let newVar = preformvalues.orderInspectionComment.map(
        (predata, index) => {
          // console.log(predata, "predata[index]");
          if (index === dataIndex) {
            predata.description = value;
          }
          return predata;
        }
      );
      // console.log(newVar, "newVarnewVar");

      setPreFormValues({ ...preformvalues, orderInspectionComment: newVar });
      // setPreServicePic({...preservicepic,...newVar});
    }
    if (type === "add") {
      let description = preFormWindow.map((desc, index) => {
        if (index === dataIndex) {
          desc.description = value;
          desc.index = dataIndex;
          desc.error = "success";
        }
        return desc;
      });
      setPreFormWindow(description);
    }
  };

  //preFormSubmitHandler
  const preFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setPreViewOnly(true);
    let finalArray =
      preFormWindow && preformvalues.orderInspectionComment
        ? [...preformvalues.orderInspectionComment, ...preFormWindow]
        : preFormWindow
        ? [...preFormWindow]
        : preformvalues.orderInspectionComment
        ? [...preformvalues.orderInspectionComment]
        : [];

    const preFormData = saveOrderInspection({
      ...preformvalues,
      orderInspectionComment: finalArray,
      type: "pre",
      order_id_fk: mappedOrderData.order_id_pk,
      order_inspection_id_pk: preformvalues.order_inspection_id_pk,
    });
    // debugger
    preFormData.then((res) => {
      console.log(res, "...............res pre");

      setPreFormValues(res);
      setPreServicePic([]);
      setPreFormWindow([]);
      setEditView(false);
    });
  };

  const handleaddclick = () => {
    // console.log(preFormWindow.find((data, index) => index == prePicLength).description, "desc");

    setPreFormWindow([
      ...preFormWindow,
      {
        description: "",
        index: "",
        inspection_image: "",
        error: "empty",
      },
    ]);
  };
  const handleremove = (index) => {
    const list = [...preFormWindow];
    list.splice(index, 1);
    setPreFormWindow(list);
  };

  const preformHandleRemove = (index) => {
    // console.log(index, "image id");
    const deleteRow = preformvalues.orderInspectionComment.filter(
      (v, i) => i !== index
    );
    setPreFormValues({ ...preformvalues, orderInspectionComment: deleteRow });
  };

  console.log(
    preformvalues,
    "preformvalues-----------------------------------------------------"
  );
  console.log(
    preFormWindow,
    "preFormWindow-----------------------------------------------------"
  );

  return (
    <div
      className={innertab === 1 ? "show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block order-inner-block">
        <Fragment>
          <div className="head-title">
            <h6>
              Kindly check vehicle to look for spots, patches or any belongings
            </h6>
            {!editView &&
            preformvalues &&
            Object.keys(preformvalues).length > 0 ? (
              <div class="col-12 text-end p-0 mt-3">
                <button
                  class="btn btn-update"
                  onClick={(e) => {
                    setPreViewOnly(false);
                    setPreview(false);
                    setEditView(true);
                  }}
                >
                  <i class="fal fa-pen mr-2"></i>Edit
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <form onSubmit={preFormSubmitHandler}>
            {/* preservice pic block */}
            <div className="form-box">
              <div>
                {preformvalues && preformvalues.orderInspectionComment
                  ? preformvalues.orderInspectionComment.map((data, index) => {
                      return (
                        <Fragment>
                          <div className="col-12 p-0">
                            <div className="input-list-row">
                              <div className="col-main-list">
                                <div class="col-md-2 p-0 text-left">
                                  {/* {data.inspection_image != "" && ( */}
                                  {data.image_url == "" ||
                                  data.image_url == null ? (
                                    <div className="small-block col-12 p-0">
                                      <button
                                        disabled={
                                          !editView ||
                                          (!editView &&
                                            preformvalues &&
                                            Object.keys(preformvalues).length >
                                              0)
                                            ? true
                                            : false
                                        }
                                        type="button"
                                        className="btn-image"
                                        onClick={handleShow}
                                      >
                                        <div className="upload-section justify-content-center">
                                          <div className="upload-img">
                                            <img
                                              src={uploadImageFile}
                                              alt="loading..."
                                            />
                                          </div>
                                        </div>
                                        {data.image_url == "" ||
                                        (data.image_url == null && editView)
                                          ? "Upload"
                                          : "No Image"}
                                      </button>
                                    </div>
                                  ) : (
                                    <div
                                      className="col-12 col-img-list img-150"
                                      key={index}
                                    >
                                      <div className="img-list">
                                        <div className="choosed-file-block img-150">
                                          <div className="pdf-block imgBlock">
                                            <img
                                              src={`${configData.SERVER_URL}/uploads/order-inspection-images/${data.image_url}`}
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* )} */}
                                </div>
                                <div class="col-md-10 p-0">
                                  <div class="form-group m-0">
                                    <textarea
                                      readOnly={!editView ? true : false}
                                      type="text"
                                      className="form-control img-text"
                                      placeholder="Description*"
                                      onChange={(e) => {
                                        imageNoteHandler(
                                          e.target.value,
                                          index,
                                          "edit"
                                        );
                                      }}
                                      value={
                                        data.description ? data.description : ""
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="button-remove">
                                {/* {editView && Object.keys(preformvalues).length > 0 ? ( */}
                                {editView &&
                                preformvalues.length > 1 &&
                                !preformvalues.find(
                                  (data, preIndex) =>
                                    data.error == "empty" &&
                                    index == preIndex &&
                                    preformvalues.length >= 2
                                ) ? (
                                  <span
                                    onClick={() => preformHandleRemove(index)}
                                  >
                                    <span>
                                      <i class="fal fa-times"></i>
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                  : ""}
              </div>
              {/* for BASE64 */}
              <div>
                {preFormWindow &&
                  preFormWindow.map((data, index) => {
                    return (
                      <Fragment>
                        <div className="col-12 p-0">
                          <div className="input-list-row">
                            <div className="col-main-list">
                              <div class="col-md-2 text-left p-0">
                                {data.inspection_image == "" && (
                                  <div className="small-block col-12 p-0">
                                    <button
                                      // disabled={previewonly ? true : false}
                                      type="button"
                                      className="btn-image"
                                      onClick={handleShow}
                                    >
                                      <div className="upload-section justify-content-center">
                                        <div className="upload-img">
                                          <img
                                            src={uploadImageFile}
                                            alt="loading..."
                                          />
                                        </div>
                                      </div>
                                      Upload
                                    </button>
                                  </div>
                                )}

                                {/* for BASE64 */}
                                {data.inspection_image != "" && (
                                  <div
                                    className="col-12 col-img-list img-150 p-0"
                                    key={index}
                                  >
                                    <div className="img-list">
                                      <div className="choosed-file-block img-150">
                                        <div className="pdf-block">
                                          <img
                                            src={data.inspection_image.base64}
                                            className="img-fluid"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* textarea */}
                              <div class="col-md-10 pr-0">
                                <div class="form-group m-0">
                                  {/* <label for="" class="form-text text-start text-info">Description <sup className="theme-text">*</sup></label> */}
                                  <textarea
                                    // readOnly={!editView ? true : false}
                                    type="text"
                                    placeholder="Description*"
                                    className="form-control img-text"
                                    onChange={(e) => {
                                      imageNoteHandler(
                                        e.target.value,
                                        index,
                                        "add"
                                      );
                                    }}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            {editView &&
                            preFormWindow.length > 1 &&
                            !preFormWindow.find(
                              (data, preIndex) =>
                                data.error == "empty" &&
                                index == preIndex &&
                                preFormWindow.length >= 2
                            ) ? (
                              <div className="button-remove">
                                <span onClick={() => handleremove(index)}>
                                  <span>
                                    <i class="fal fa-times"></i>
                                  </span>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
              </div>

              {editView &&
              !preFormWindow.find((data) => data.error === "empty") ? (
                <div class="form-group button-add mt-3 text-right">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleaddclick}
                  >
                    <i className="fal fa-plus"></i> Add more
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <p className=" mb-3 text-start text-primary">
              Add photos or comments of anything you find
            </p>
            {/* SAP Notes */}
            <div class="input-group input-group-custom">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-pen"></i>SAP Notes
                </span>
              </div>
              <div className="input-block">
                <textarea
                  readOnly={
                    !editView && Object.keys(preformvalues).length > 0
                      ? true
                      : false
                  }
                  class="form-control"
                  placeholder="Notes from SAP"
                  onChange={(e) => {
                    setPreFormValues({
                      ...preformvalues,
                      sap_note: e.target.value,
                    });
                  }}
                  value={
                    preformvalues && preformvalues.sap_note
                      ? preformvalues.sap_note
                      : ""
                  }
                ></textarea>
                {/* <span className="form-error">Address is required!</span> */}
              </div>
            </div>
            {/* Customer Notes */}
            <div class="input-group input-group-custom">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-pen"></i>Customer Notes
                </span>
              </div>
              <div className="input-block">
                <textarea
                  readOnly={
                    !editView && Object.keys(preformvalues).length > 0
                      ? true
                      : false
                  }
                  class="form-control"
                  placeholder="Notes from Customer"
                  onChange={(e) => {
                    setPreFormValues({
                      ...preformvalues,
                      customer_note: e.target.value,
                    });
                  }}
                  value={
                    preformvalues && preformvalues.customer_note
                      ? preformvalues.customer_note
                      : ""
                  }
                ></textarea>
                {/* <span className="form-error">Address is required!</span> */}
              </div>
            </div>
            {/* {previewonly ? */}

            {editView ? (
              <div class="col-12 text-end p-0 mt-3">
                <button
                  class="btn btn-update"
                  disabled={
                    preformvalues.sap_note || preformvalues.customer_note
                      ? false
                      : true
                  }
                  type="submit"
                >
                  <i class="fal fa-save mr-2"></i>Save
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
          <PreServiceImageModal />
        </Fragment>
      </div>
    </div>
  );
};

export default PreServiceInspectionTab;

import React, { useContext }  from 'react';
import { Button } from "react-bootstrap";
import ServiceFilterWrapper from "./SuperUserFilterWrapper";
import { SuperContext } from "../SuperContext";

const ContentTitle = (props) => {
  const { setIsUpdateForm, SetMappedServiceData,SetViewReadOnly,SetEditMode } = useContext(SuperContext);
  const handleClick = () => {
    SetMappedServiceData({});
    SetViewReadOnly(false);
    SetEditMode(false);
    setIsUpdateForm(false);
    props.handleAdd();
  }
  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
          <h5 class="head">Service Center</h5>
          <div class="action-block">
          <button
              class="btn add-new"
              id="addBtn"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              // onClick={() => {props.handleAddService(true)}}
              onClick={handleClick}
            >
              <span>
                <i class="fal fa-plus"></i> Add New
              </span>
            </button>
          </div>
        </div>
        <div class="title-action-right d-flex">
          <ServiceFilterWrapper />
        </div>
      </div>
    </div>
  );
};

export default ContentTitle;

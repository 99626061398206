import React, { Fragment, useContext, useState } from "react";
import { StaffContext } from "../StaffContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import noLoginImg from "../../../../../assets/img/No-Data/key.png";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  createStaffLoginCridential,
} from "../../../../../api/services/sap/staff-management";


let initialcredential = {
  staff_id: "",
  username: "",
  password: "",
  confirmPassword: "",
};
const checkVal = [
  {
    label: "Staff",
    all:"staffall",
    create: "",
    view: "",
    edit: "",
    delete: "",
  },
  {
    label: "Service",
    all: "serviceall",
    create: "",
    view: "",
    edit: "",
    delete: "",
  },
  {
    label: "Others",
    all: "othersall",
    create: "",
    view: "",
    edit: "",
    delete: "",
  },
  ,
];
const StaffPreviligesTab = () => {
  // const [credentialform, SetCredentialForm] = useState(initialstate);
  const [viewform, SetViewForm] = useState(true);
  const [roles, SetRoles] = useState(false);
  const [credentials, SetCredentials] = useState(initialcredential);
  const [check, setCheck] = useState(checkVal);

  // useEffect = (() => {
  //   setCheck(checkval);
  // },[]);

  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    innertab,
    SetinnerTab,
    staffcredential,
    SetStaffCredential,
    isCredential,
    SetIsCredential,
    viewPriviliges,
    SetViewPrivileges,
    editPriviliges,
    SetEditPrivileges,
  } = useContext(StaffContext);

  // const staffid = mappedStaff && mappedStaff.id;
  // const staffusername = mappedStaff && mappedStaff.phone;

  //credential form submit
  const CredentialSumbitHandler = (e) => {
    e.preventDefault();
    // SetStaffCredential({ staffcredential, ...credentials, id: staffid });
    console.log("staffcredential are ", credentials);

    const data = createStaffLoginCridential({ ...credentials });

    data
      .then((res) => {
        console.log("dataaaas", res);
        SetRoles(true);
        SetViewForm(false);
        e.stopPropagation();    
        
        toast.success("Staff credential created successfully", {
          position: "top-right",
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.data.errors) {
          toast.error(err.data.errors[0].msg, {
            position: "bottom-right",
          });
        } else {
          toast.error(err.message, {
            position: "bottom-right",
          });
        }
      });

    // SetRoles(true);
    // SetViewForm(false);
    // e.stopPropagation();
  };

  const addCred = () => {
    SetViewForm(true);
  };
  const cancelCred = () => {
    SetViewForm(false);
  };
  const onEditHandler = () => {
    console.log(check);
  };
  const cancelHandler = () => {
    SetViewPrivileges(true);
  };
  // const clearFormHandler = () => {
  //   SetCredentials({...credentials, password:"" , confirmpassword:""});
  // };

  // const [check1, setCheck1] = useState({
  //   id: "staff1",
  //   all: "",
  //   create: "",
  // });
  // const trClick = (e) => {};

  //useEffect
  useEffect(() => {
    // mappedStaff.forEach((item) => {
      if (mappedStaff.user) {
          console.log("userrr", mappedStaff.user);
          SetViewForm(false);
          // SetRoles(true);
        } else {
          console.log("no userrr", mappedStaff.user);
          SetCredentials({
            staff_id: mappedStaff.staff_id_pk,
            username: mappedStaff.emp_id,
          })
        }
      // });
      setCheck(checkVal);
      
     }, []);

  // console.log(check, "before check click")
  const handleCheck = (e) => {
    const {name, checked} = e.target;
    // if(name === "allprivileges"){
    //   let tempData = check.map((obj) => {
    //     return {...obj, isChecked : checked};
    //   });
    //   setCheck(tempData);
    // }
    // else{
    //   let tempData = check.map((obj => obj.name = name ? {...obj ,isChecked : checked}: obj))
    //   setCheck(tempData);
    // }
    
    const val = e.target.value;
    // const checked = e.target.checked;
    // console.log(check[e.target.accessKey].all, "000000")


    // setCheck([
    //   ...check, 
    //   {...check[e.target.accessKey][all] : checked }
    // ]);


    // let newList = check.map((item) => item.label == e.target.accessKey);
    // setCheck([...check, check[0].all]);
    // const index = check.findIndex(object => {
    //   return object.id === e.index;
    // });

    // let newList = check.map((item) => {
    //   console.log("TABLE ID", item.id);
    //   if (item.id === e.id) {
    //     return e;
    //   }
    //   return item;
    //   console.log(item);
    // });
    // let newList = check.find((item) => item.label == e.target.accessKey);
    // console.log("newList", newList);
    // console.log("bubbles", e.target.accessKey);

    // setCheck(() =>
    //     (newList)
    // );
    // setCheck((prevCheck) => (
    //   [...prevCheck,
    //     {...newList}
    //   ];
    // );
    // console.log(typeof check, "type");
    // console.log(check, "from check");
    // console.log(typeof newList, "type");
    // console.log(newList, "from newList");
    // if(checked){
    //   setCheck(
    //    { ...check, val}
    //   )
    // }
    // else{

    // }
  };
  const privilegeSubmit = (e) => {
    e.preventDefault();
  };

  // (e) => {
  //   setCheck({
  //     ...check,
  //     all: e.target.checked,
  //   });
  // }

  // console.log(check, "check value");
  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-privileges"
      role="tabpanel"
      aria-labelledby="nav-privileges-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        {/* {mappedStaff.length &&
          mappedStaff.map((data, index) => {
            console.log("datasaas", data);
            return ( */}
              <Fragment key="">
                <div className="row m-0">
                  <div className="text-left w-100">
                    {/* {viewform && (
                      <button className="btn btn-light m-2" onClick={cancelCred}>
                        Cancel
                      </button>
                    )}
                    {!roles && (
                      <button className="btn btn-primary m-2" onClick={addCred}>
                        Create credentials
                      </button>
                    )} */}
                  </div>
                  {/* <div className="login-img-block ">
                        <div className="no-data-available">
                          <div className="no-data-img">
                            <img src={noLoginImg} height="100" />
                            <h6>No Data Available</h6>
                          </div>
                        </div>
                      </div> */}
                  <div className="credentials-block pt-4">
                    {viewform ? (
                      <div className="credential-block">
                        <h5>
                          You have 2 logins left of 2 in your current subscription
                        </h5>
                        <h6>Create a login for this staff member</h6>
                        <form onSubmit={CredentialSumbitHandler}>
                          <div className="input-group input-group-custom">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fal fa-user"></i>Username
                                <sup className="theme-text">*</sup>
                              </span>
                            </div>
                            <div className="input-block">
                              <input
                                type="num"
                                className="form-control"
                                // onChange={(e) => {
                                //   SetCredentials({
                                //     ...credentials,
                                //     username: e.target.value,
                                //   });
                                // }}
                                value={mappedStaff.emp_id}
                              />
                              {/* <span className="form-error">Name is required!</span> */}
                            </div>
                          </div>
                          <div className="input-group input-group-custom">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fal fa-user"></i>Password
                                <sup className="theme-text">*</sup>
                              </span>
                            </div>
                            <div className="input-block">
                              <input
                                type="password"
                                className="form-control"
                                onChange={(e) => {
                                  SetCredentials({
                                    ...credentials,
                                    password: e.target.value,
                                  });
                                }}
                                //   required
                                value={credentials.password}
                                // minLength={6}
                                // maxLength={6}
                              />
                              {/* <span className="form-error">
                                Password is required!
                              </span> */}
                            </div>
                          </div>
                          <div className="input-group input-group-custom">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fal fa-user"></i>Confirm password
                                <sup className="theme-text">*</sup>
                              </span>
                            </div>
                            <div className="input-block">
                              <input
                                type="password"
                                className="form-control"
                                onChange={(e) => {
                                  SetCredentials({
                                    ...credentials,
                                    confirmPassword: e.target.value,
                                  });
                                }}
                                //   required
                                value={credentials.confirmPassword}
                                // minLength={6}
                                // maxLength={6}
                              />
                              {/* <span className="form-error">
                                confirmpassword is required!
                              </span> */}
                            </div>
                          </div>
                          <div class="col-12 text-end p-0 mt-3">
                            <button
                              class="btn btn-light mr-2"
                              // onClick={clearFormHandler}
                            >
                              <i class="fal fa-save mr-2"></i>CLEAR
                            </button>
                            <button class="btn btn-update mr-2" type="submit">
                              <i class="fal fa-save mr-2"></i>CREATE LOGIN
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </div>
                </div>

                {staffcredential.password && staffcredential.confirmpassword ? (
                  <div className="inner-form-block">
                    <div className="row">
                      <div className="col-12 inner-header">
                        {viewPriviliges && (
                          <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                            <button class="btn btn-danger mr-2" type="submit">
                              <i class="fal fa-trash mr-2"></i>DELETE THIS LOGIN
                            </button>
                            <button
                              class="btn btn-update mr-2"
                              type="submit"
                              onClick={onEditHandler}
                            >
                              <i class="fal fa-save mr-2"></i>EDIT
                            </button>
                          </div>
                        )}

                        <div className="block-header main-title">
                          <h6>
                            <span className="header-title">
                              Set privileges for this user :
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 inner-content-table-block">
                        <form onSubmit={privilegeSubmit}>
                          <div className="custom-form-group">
                            <div className="custom-control custom-checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="allprivileges"
                                  className="custom-control-input"
                                  onChange={handleCheck}    
                                  checked={check.filter((obj) => obj.isChecked !== true).length < 1}
                                />
                                <div className="custom-control-label"></div>
                              </label>
                            </div>
                            All Permissions (Same as admin)
                          </div>
                          <div className="text-center mb-2">OR</div>
                          <div className="content-table table-responsive">
                            <table className="table bg-white custom-table inner-tbl">
                              <thead>
                                <tr>
                                  <th>Module</th>
                                  <th>
                                    All{" "}
                                    <i
                                      className="fal fa-info-circle"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Checking will allow Staff to View Details"
                                    ></i>{" "}
                                  </th>
                                  <th>
                                    Create{" "}
                                    <i
                                      className="fal fa-info-circle"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Checking will allow Staff to Create Details"
                                    ></i>
                                  </th>
                                  <th>
                                    View{" "}
                                    <i
                                      className="fal fa-info-circle"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Checking will allow Staff to Create Details"
                                    ></i>
                                  </th>
                                  <th>
                                    Edit{" "}
                                    <i
                                      className="fal fa-info-circle"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Checking will allow Staff to Edit Details"
                                    ></i>
                                  </th>
                                  <th>
                                    Delete{" "}
                                    <i
                                      className="fal fa-info-circle"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Checking will allow Staff to Edit Details"
                                    ></i>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {check &&
                                  check.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td scope="row">
                                          {data.label}
                                          <span className="text-danger">{index}</span>
                                        </td>

                                        <td>
                                          <div className="custom-control custom-checkbox">
                                            <label>
                                              <span className="text-danger">
                                                {index}
                                              </span>
                                              <input
                                                type="checkbox"
                                                name={data.all}
                                                className="custom-control-input"
                                                // onChange={(e) => {
                                                //   setCheck({ ...check, all: e.target.checked });
                                                //   if (e.target.value && e.target.value.length > 0) {
                                                //     SetFormChange(true);
                                                //   } else {
                                                //     SetFormChange(false);
                                                //   }
                                                // }}
                                                
                                                onChange={handleCheck} 
                                                // accessKey={index}
                                                checked={data.isChecked}
                                              />
                                              <div className="custom-control-label"></div>
                                            </label>
                                          </div>
                                        </td>
                                        {/* <td>
                                          <div className="custom-control custom-checkbox">
                                            <label>
                                              <span className="text-danger">
                                                {index}
                                              </span>
                                              <input
                                                type="checkbox"
                                                name="createpreviliges"
                                                className="custom-control-input"
                                                onChange={handleCheck}
                                                accessKey={index}
                                                value=""
                                              />
                                              <div className="custom-control-label"></div>
                                            </label>
                                          </div>
                                        </td> */}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-end p-0 mt-3">
                            <button
                              class="btn btn-light mr-2"
                              onClick={cancelHandler}
                            >
                              <i class="fal fa-save mr-2"></i>CANCEL
                            </button>
                            <button class="btn btn-update mr-2" type="submit">
                              <i class="fal fa-save mr-2"></i>SAVE
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : null}
              </Fragment>
            {/* );
          })} */}
      </Scrollbars>
      {/* {staffcredential.map((e) => {
        return(
<span className="dammy">passwoers -{e.password}</span>
        );
      })} */}
    </div>
  );
};

export default StaffPreviligesTab;

import React , {useContext, useEffect, useState} from 'react'
import Scrollbars from 'react-custom-scrollbars-2';
import DataTable from 'react-data-table-component';
import { getCustomerOrders } from '../../../../api/services/sap/customer-management';
import { CustomerContext } from "./CustomerContext";
import OrderHistoryDropDown from './Ui/OrderHistoryDropDown';

const OrderHistory = () => {

  const [filteredOrders, setFilteredOrders] = useState([])
  

  let columns = [
    {
      name: "Order ID",
      selector: (row) => row.order_unique_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.service_date,
      // cell:(row) => <div>{new Date(row.order_placed_date)}</div>,  // need salman's help
      sortable: true,
      allowOverflow: false,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.vehicle_registration_number,
      sortable: true,
    },
    {
      name: "Services Booked",
      selector: (row) => {
        let req =[];
        row.orderServices.forEach((element)=>{
           req.push(element.service_name)
         })

         return req.join(', ')
      },
      // cell: (row) => <div data-tag="allowRowEvents">row.orderService && {row.orderService.length}</div>, // need salman's help
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ];
  const column2 = [
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.order_placed_date, // need salman's help
      // cell: (row) => ,
      sortable: true,

      allowOverflow: false,
    },
    {
      name: "Services",
      selector: (row) => row.order_service_count,
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.order_completed,
      sortable: true,
    },
  ];
  
    //PAGINATION OPTIONS
    const paginationComponentOptions = {
      rowsPerPageText: "rows",
      rangeSeparatorText: "of",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
      noRowsPerPage: 0,
    };
    //Datatable sort icon
    const sortIcon = <i class="fal fa-sort"></i>;
    //custom datatable styles
    const customStyles = {
      rows: {
        style: {
          minHeight: "50px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    const {
      mappedOrderData,
      vehicleFilter,
      filterValue,
      innertab,
      selectedOption,
      customerOrdersListData,
      setCustomerOrdersListData,
      selectedVehicleId
    } = useContext(CustomerContext);
    
    useEffect(()=>{  // need salman's help
      const data = getCustomerOrders(mappedOrderData.customer_id);
    data.then((res) => {
      console.log(res, "customer api");
      let customerOrdersList = res.data.map((item) => {
        return item;
      });
      console.log(customerOrdersList, "customerOrdersList");
      setCustomerOrdersListData(customerOrdersList);
    })
    },[])
 
    useEffect(()=>{
     if(vehicleFilter){
      let filteredCustomerVehicleOrders= customerOrdersListData.filter(order=>order.vehicle_id === selectedVehicleId);
      setFilteredOrders(filteredCustomerVehicleOrders);
      console.log(customerOrdersListData);
     }else{
      setFilteredOrders([]);
     }
    },[vehicleFilter,selectedOption])

    // need salman's help
    // const ordersList = [  
    //   {
    //     "order_id": 1,
    //     "order_unique_id": "29vvx",
    //     "service_date": "1970-01-01",
    //     "order_placed_date": "2022-07-21T09:35:31.820Z",
    //     "actual_amount": 10000,
    //     "status": 0,
    //     "vehicle_id": 1,
    //     "vehicle_registration_number": "KL-04-AR-8510",
    //     "order_completed": "Completed",
    //     "order_service_count": 2,
    //     "orderServices": [
    //       {
    //         "order_id_fk": 1,
    //         "service_id_fk": 1,
    //         "actual_price": 100,
    //         "service_name": "Car Wash"
    //       },
    //       {
    //         "order_id_fk": 1,
    //         "service_id_fk": 2,
    //         "actual_price": 200,
    //         "service_name": "Wash"
    //       }
    //     ]
    //   },
    //   {
    //     "order_id": 2,
    //     "order_unique_id": "mbe3zk",
    //     "service_date": "1970-01-01",
    //     "order_placed_date": "1970-01-01T00:00:00.000Z",
    //     "actual_amount": 10000,
    //     "status": 0,
    //     "vehicle_id": 1,
    //     "vehicle_registration_number": "KL-04-AR-8510",
    //     "order_completed": "Completed",
    //     "order_service_count": 1,
    //     "orderServices": [
    //       {
    //         "order_id_fk": 2,
    //         "service_id_fk": 2,
    //         "actual_price": 200,
    //         "service_name": "Wash"
    //       }
    //     ]
    //   },
    //   {
    //     "order_id": 3,
    //     "order_unique_id": "1tgzj",
    //     "service_date": "1970-01-01",
    //     "order_placed_date": "1970-01-01T00:00:00.000Z",
    //     "actual_amount": 10000,
    //     "status": 0,
    //     "vehicle_id": 1,
    //     "vehicle_registration_number": "KL-04-AR-8510",
    //     "order_completed": "Completed",
    //     "order_service_count": 0,
    //     "orderServices": []
    //   },
    //   {
    //     "order_id": 4,
    //     "order_unique_id": "zjm2f",
    //     "service_date": "1970-01-01",
    //     "order_placed_date": "1970-01-01T00:00:00.000Z",
    //     "actual_amount": 10000,
    //     "status": 0,
    //     "vehicle_id": 1,
    //     "vehicle_registration_number": "KL-04-AR-8510",
    //     "order_completed": "Completed",
    //     "order_service_count": 0,
    //     "orderServices": []
    //   }
    // ]


    return (
        <div
          className={innertab === 3? " show active" : "tab-pane fade"}
          id="nav-personal"
          role="tabpanel"
            aria-labelledby="nav-personal-tab"
          >
            <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
            <div className="inner-form-block customer-inner-form-block">
                <div className="row">
                <div className="sort-filter mb-5">
                    <OrderHistoryDropDown/>
                </div>
                <div className="main-body mt-5">
                  <div className="body-title">
                    <p>Order history of {vehicleFilter ? selectedOption : mappedOrderData.customer_name}</p>
                  </div>
                  <div className="body-order-history-table mt-3">
                 {vehicleFilter?  ( <DataTable
                    columns={column2}
                    data={filteredOrders}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    sortIcon={sortIcon}
                    customStyles={customStyles}
                    onColumnOrderChange={vehicleFilter}
                  />) : ( <DataTable
                    columns={columns}
                    data={customerOrdersListData}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    sortIcon={sortIcon}
                    customStyles={customStyles}
                    onColumnOrderChange={vehicleFilter}
                  />)}
                  </div>
                </div>
              </div>
             </div>
           </Scrollbars>
        </div>
      );
}

export default OrderHistory
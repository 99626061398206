import React, { useState, useContext, useEffect } from "react";
import { SuperContext } from "../../Pages/User/SuperContext";
import { toast } from "react-toastify";
// import { Form, Button } from 'semantic-ui-react';
// import { useForm } from "react-hook-form";
import "../../../../assets/css/tags.css";
import { Validation } from "../../Layout/Ui/Validation";

const initialState={
  name: "",
  owner_name: "",
  email: "",
  phone_number: "",
  phone_extension: "091",
  website: "",
  address: "",
  password: "",
  confirm_password: "",
};

const errorFields={
    name: "",
    owner_name: "",
    email: "",
    phone_number: "",
    address: "",
    password: "",
    confirm_password: "",
}

function SuperUserForm(props) {
  const [error, setError] = useState(false);
  const [formErrors,SetFormErrors] = useState({});
  const [editServiceId, setEditServiceId] = useState("");
  const [formData, SetFormData] = useState(initialState);
  const {
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    handleServiceEdit,
    editMode,
    SetEditMode,
    isUpdateForm,
    SetFormChange,
  } = useContext(SuperContext);
  //Image Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // useEffect
  useEffect(() => {
    SetFormErrors({});
    let { id, ...rest } = mappedServiceData;
    setEditServiceId(id);
    if (props.addservice && !isUpdateForm) {
      SetFormData(initialState);
    } else {
      SetFormData(rest);
    }
    // Unmount
    return () => {
      SetFormData(initialState);
    };
  }, [props.addservice, isUpdateForm, mappedServiceData]);

  // Toastify
  const updateToast = () => {
    return editMode ? "Updated Successfully" : "Added Successfully";
  };
  
  const onChangeValidate=(field)=>{
       const errors = Validation(formData,true,field);
       if(Object.keys(errors).length != 0){
        setError(true);
        if(Object.keys(formErrors).length != 0){   
          const updatedErrorObject = {...formErrors,...errors};   
          SetFormErrors(updatedErrorObject);
        }else{
          SetFormErrors(errors);
        }
       }else{
        if(Object.keys(formErrors).length != 0){
          delete formErrors[field]
          const updatedErrorObject = {...formErrors};
          SetFormErrors(updatedErrorObject);
        }else{
          setError(false);
          SetFormErrors(errorFields);
        }
       } 
  }

  //Form Submit
  const handleServiceForm  = (e) => {
    console.log(error);
    console.log(formData.name.length);
    let errors = Validation(formData);
   
    console.log(errors,"sadasd");
    if(editMode){
      if(Object.keys(errors).length != 0){
       delete errors['confirm_password'];
       delete errors['password'];
      }
 }
    if(Object.keys(errors).length != 0){
      console.log("errr");
      e.preventDefault();
      SetFormErrors(errors);
      setError(true);
     
    }else{
      if (editMode) {
        console.log("editmode");
        e.preventDefault();
        handleServiceEdit(formData, editServiceId); //for validation add 2 func succ and fail
        props.serviceformClose(false);
        // add new  service admin issue
        SetEditMode(false);
        setEditServiceId("");
        SetMappedServiceData({});
        SetFormChange(false);
        setError(false);
        toast.success(updateToast, {
          position: "bottom-right",
        });
      }
   else {
  console.log(formData);
      e.preventDefault();
      handleServiceEdit(formData, editServiceId); //for validation add 2 func succ and fail
      props.serviceformClose(false);
      // add new  service admin issue
      SetEditMode(false);
      setEditServiceId("");
      SetMappedServiceData({});
      SetFormChange(false);
      setError(false);
      toast.success(updateToast, {
        position: "bottom-right",
      });
  
      }
    }   
  };

  const handleKeyPress =(e)=> {  
    var key = e.key;
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
        e.preventDefault();
    } 
  }
  const nameFieldRestriction =(event)=>{
   
    if(!((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || event.charCode == 45)){
      event.preventDefault();
    }
  }

  return (
    <div className="col-12">
      <form className="w-100" id="add-service" onSubmit={handleServiceForm}>
        {/* Serice Name */}
        {/* <Form.Field> */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Name <sup className="theme-text">*</sup>
            </span>
          </div>
            <div className="input-block">
              <input
                type="text"
                className="form-control"
                placeholder=""
                readOnly={viewReadOnly}
                value={formData.name}
                onChange={(e) =>
                  SetFormData(
                    { ...formData, name: e.target.value },
                    SetFormChange(true),
                  )
                }
                onBlur={(e)=> onChangeValidate("name") 
                }
              />
            {error && formErrors.name ? (
              <span className="form-error">{formErrors.name}</span>
            ) : (
              ""
            )}
          </div>
          {/* {errors.firstName?.type === 'required' && <p>Please check the First Name</p>}
          {errors.firstName?.type === 'maxLength' && <p>Please check the length</p>} */}
        
        </div>
        {/* </Form.Field> */}
        {/* Owner Name */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Owner Name<sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
          <input
            type="text"
            className="form-control"
            placeholder=""
            onKeyPress={nameFieldRestriction}
            readOnly={viewReadOnly}
            value={formData.owner_name}
            onChange={(e) =>
              SetFormData(
                { ...formData, owner_name: e.target.value },
                SetFormChange(true)
              )
            }
            onBlur={(e)=> onChangeValidate("owner_name") 
                }
          />
         {error && formErrors.owner_name ? (
              <span className="form-error">{formErrors.owner_name}</span>
            ) : (
              ""
            )}
        </div>
        </div>

        {/* Email */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-envelope"></i>Email<sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
          <input
            type="text"
            className="form-control"
            placeholder=""
            readOnly={viewReadOnly}
            value={formData.email}
            onChange={(e) =>
              SetFormData(
                { ...formData, email: e.target.value },
                SetFormChange(true)
              )
            }
            onBlur={(e)=> onChangeValidate("email") 
                }
          />
              {error && formErrors.email ? (
              <span className="form-error">{formErrors.email}</span>
            ) : (
              ""
            )}
        </div>  
        </div>
        {/* Phone-details-section */}
        <section className="phone-section">
          {/* Phone Number */}
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-phone"></i>Phone Number<sup className="theme-text">*</sup>
              </span>
            </div>
            {/* Serice Phone Extension */}
            <div className="input-block">
              <div className="row m-0">
            <input
              type="text"
              style={{ maxWidth: "60px", marginRight: "10px" }}
              className="form-control phone-extension"
              // readonly
              placeholder="091"
              // readOnly={viewReadOnly}              
              value="091"
              onChange={(e) =>
                SetFormData(
                  { ...formData, phone_extension: parseInt(e.target.value) },
                  SetFormChange(true)
                )
              }
              onBlur={(e)=> onChangeValidate("phone_number") 
                }
            />
          
            <input
              type="text"
              className="form-control"
              onKeyPress={handleKeyPress}
              placeholder="9898989898"
              autoComplete="new-off"
              readOnly={viewReadOnly}
              value={formData.phone_number}
              onChange={(e) =>
                SetFormData(
                  { ...formData, phone_number:e.target.value },
                  SetFormChange(true),
                  
                )
              }
              onBlur={(e)=> onChangeValidate("phone_number") 
                }
            />
             {error && formErrors.phone_number ? (
              <span className="form-error">{formErrors.phone_number}</span>
            ) : (
              ""
            )}
          </div>  
          </div>
          </div>
        </section>
        {/* Password */}
        {!isUpdateForm && (
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-lock"></i>Password<sup className="theme-text">*</sup>
              </span>
            </div>
          <div className="input-block">
            <input
              type="password"
              autoComplete="new-password"
              className="form-control"
              placeholder=""
              readOnly={viewReadOnly}
              value={formData.password}
              onChange={(e) =>
                SetFormData(
                  { ...formData, password: e.target.value },
                  SetFormChange(true)
                )
              }
              onBlur={(e)=> onChangeValidate("password") 
                }
            />
           {error && formErrors.password ? (
              <span className="form-error">{formErrors.password}</span>
            ) : (
              ""
            )}
        </div>
        </div>
        )}

        {/* Confirm password */}
        {!isUpdateForm && (
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-lock"></i>Confirm Password<sup className="theme-text">*</sup>
              </span>
            </div>
          <div className="input-block">
            <input
              type="password"
              className="form-control"
              placeholder=""
              readOnly={viewReadOnly}
              value={formData.confirm_password}
              onChange={(e) =>
                SetFormData(
                  { ...formData, confirm_password: e.target.value },
                  SetFormChange(true)
                )
              }
              onBlur={(e)=> onChangeValidate("confirm_password") 
                }
            />
             {error && formErrors.confirm_password ? (
              <span className="form-error">{formErrors.confirm_password}</span>
            ) : (
              ""
            )}
          </div>
          </div>
        
        )}

        {/* Serice Address */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-map-marker-alt"></i>Address<sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
          <textarea
            type="text"
            className="form-control"
            placeholder=""
            readOnly={viewReadOnly}
            value={formData.address}
            onChange={(e) =>
              SetFormData(
                { ...formData, address: e.target.value },
                SetFormChange(true)
              )
            }
            onBlur={(e)=> onChangeValidate("address") 
                }
          />
           {error && formErrors.address ? (
              <span className="form-error">{formErrors.address}</span>
            ) : (
              ""
            )}
        </div>
        </div>
        {/* Serice Website */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-globe"></i>Website
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder=""
            readOnly={viewReadOnly}
            value={formData.website}
            onChange={(e) =>
              SetFormData(
                { ...formData, website: e.target.value },
                SetFormChange(true)
              )
            }
          />
        </div>
        <div className="col-12 text-right p-0 mt-3">
          <button
            className={"btn btn-update" + (viewReadOnly ? " d-none" : "")}
            type="submit"
          >
            <i className="fal fa-save mr-2"></i>
            {editMode ? "Update" : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default SuperUserForm;

import React from "react";
import "../../../assets/fontawesome/css/fontawesome-all.css";
import "../../../assets/css/admin-custom.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import mainLogo from "../../../assets/img/logo/logo.svg";
import { NavLink } from "react-router-dom";
import BikeScooterOutlinedIcon from "@mui/icons-material/BikeScooterOutlined";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";
import GarageIcon from "@mui/icons-material/Garage";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

const Sidebar = () => {
  const menuItems = [
    {
      path: "/sup/dashboard",
      name: "Dashboard",
      icon: <DashboardCustomizeOutlinedIcon />,
    },
    {
      path: "/sup/user",
      name: "Service Centers",
      icon: <LocalCarWashIcon />,
    },
    {
      path: "/sup/services",
      name: "Services",
      icon: <EngineeringIcon />,
    },
    {
      path: "/sup/vendors",
      name: "Vendors",
      icon: <StorefrontOutlinedIcon />,
    },
    {
      path: "/sup/variants",
      name: "Variants",
      icon: <NoCrashIcon />,
    },
    {
      path: "/sup/bookings",
      name: "Bookings",
      icon: <EventAvailableOutlinedIcon/>,
    },
  ];

  return (
    <div className="sidebar-block admin">
      <div className="menu-side">
        <div className="sidebar-min-head text-center">
          <div className="sidebar-logo">
            <span className="logo">
              <img
                className="img-fluid"
                src={mainLogo}
                style={{ width: "125px" }}
              />
            </span>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 107px)` }}>
          <div className="menu-scroll">
            <ul className="list-sidebar bg-defoult menu-list">
              {menuItems.map((item, index) => (
                <li>
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link "
                    activeClassName="active"
                  >
                    <div className="nav-label">
                      <span className="mr-1 icon-span">{item.icon}</span>
                      <span className="span-name">{item.name}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default Sidebar;

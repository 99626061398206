import React, { useState, createContext,useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import {
  createStaff,
  getStaffList,
  deleteStaff,
} from "../../../../api/services/sap/staff-management";
// import {getLookupDataAPI} from "../../../../api/services/common/commonAPIs";

export const StaffContext = createContext("");

export const StaffProvider = (props) => {
  const [staffTable, setStaffTable] = useState([]);
  //Credentials

  const [staffcredential, SetStaffCredential] = useState({});
  const [isCredential, SetIsCredential] = useState(false);

  // Priviliges
  const [viewPriviliges, SetViewPrivileges] = useState(false);
  const [editPriviliges, SetEditPrivileges] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewStaff, setViewStaff] = useState(false);

  // const handleDeleteCancel = (e) => {
  //   setDeleteModal(false);
  //   console.log("errjhrbfjhe",deleteModal);
  // };
  // const [staffTable, setStaffTable] = useState([]);
  //Pic state for add and edit
  const [pic, setPic] = useState([]);
  const [editpic, setEditPic] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [addonImages, setAddonImages] = useState([]);
  const [addImageSubmit, setAddImageSubmit] = useState(false);
  const [editImageSubmit, setEditImageSubmit] = useState(false);

  //Idproof state for add and edit
  let id = uuidv4();
  const [idproof, setIdProof] = useState([]);
  const [editId, setEditId] = useState([]);
  const [addonId, setAddonId] = useState([]);
  const [addProofSubmit, setProofSubmit] = useState(false);
  const [editProofSubmit, setEditProofSubmit] = useState(false);
  
  const [editFormSubmit, setEditFormSubmit] = useState(false);
  const [mappedStaff, setMappedStaff] = useState([]);
  const [cancelFile, setCancelFile] = useState({});
  const [editbtn, setEditBtn] = useState(true);
  const [innertab, SetinnerTab] = useState(1);
  const [formChange, SetFormChange] = useState(false);
  const [loader, SetLoader] = useState(true);
  const [rowAcive, SetRowActive] = useState(false);
  const [bloodGroups, setBloodGroups] = useState([{ 'title': '', 'value': '' }]);
  const [idproofType, setIdproofType] = useState([{ 'title': '', 'value': '' }]);
  const [relationType, setRelationType] = useState([{ 'title': '', 'value': '' }]);
  const [gender, setGender] = useState([{ 'title': '', 'value': '' }]);


  // const updateOrCreateStaff = (formData) => {
  //   console.log(formData);

  //   const data = createStaff({ ...formData});

  //   data
  //       .then((res) => {
  //     let index = undefined;
  //     let newTable = [];
  //     let existingRecord = staffTable.filter((item, i) => {
  //       const value = item.id === res.staff_id_pk;
  //       if (value) {
  //           index = i;
  //       }
  //       console.log(value);
  //       return value;
  //     })
  //     if (existingRecord.length) {
  //       newTable = staffTable.map((item, i) => {
  //         if (i === index) {
  //           item = {
  //             id: res.staff_id_pk,
  //             name: res.name,
  //           };
  //         }
  //         return item;
  //       })
  //     } else newTable = [{
  //       id: res.staff_id_pk,
  //       profile: res.profile_image,
  //       name: res.name,
  //       email: res.email,
  //       phone: res.phone,
  //       designation: res.designation,
  //     }, ...staffTable];
  //     setStaffTable(newTable);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     toast.error(err.data.errors[0].msg, {
  //       position: "bottom-right",
  //     });
  //   });

  // }
  // const update = (formData) => {

  //   const data = createStaff({ ...formData });
  //   if (data) {
    
  //     setEditFormSubmit(true);
  //     setPic([]);
  //     setIdProof([]);
  //     setAddonId([]);
  //     props.closeStaffEdit(false);
  //     toast.success("editStaffform Updated successfully", {
  //       position: "top-right",
  //     });
  //   }
  //   console.log("ifcondition",data);
    
  // }

  const handleStaffEdit = (newData) => {
      // const data = createStaff({ ...newData });
      let newList = staffTable.map((item) => {
            if (item.id === newData.id) {
              return newData;
            }
            return item;
          });
          setStaffTable(newList);
        };
  
  const getStaffData = (id) =>{
    // setMappedStaff([]);
    const data = getStaffList(id);
    data.then((res)=>{
      setMappedStaff(res)
      SetRowActive(true);
  });
 }


  //Function for Delete
  const handleStaffDelete = (id) => {
    let data = deleteStaff(id);
    let newList = staffTable.filter((item) => item.id !== id);
    setStaffTable(newList);
  };

  let initialContext = {
    staffTable,
    setStaffTable,
    id,
    pic,
    setPic,
    editpic,
    setEditPic,
    mappedStaff,
    idproof,
    setIdProof,
    editId,
    setEditId,
    addonId,
    setAddonId,
    addProofSubmit,
    setProofSubmit,
    editProofSubmit,
    setEditProofSubmit,
    setMappedStaff,
    innertab,
    SetinnerTab,
    editbtn,
    setEditBtn,
    handleStaffDelete,
    cancelFile,
    setCancelFile,
    formChange,
    SetFormChange,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    editImageSubmit,
    setEditImageSubmit,
    editFormSubmit,
    setEditFormSubmit,
    addProofSubmit,
    setProofSubmit,
    loader,
    SetLoader,
    staffcredential,
    SetStaffCredential,
    isCredential,
    SetIsCredential,
    viewPriviliges,
    SetViewPrivileges,
    editPriviliges,
    SetEditPrivileges,
    rowAcive,
    SetRowActive,
    // updateOrCreateStaff,
    // update,
    getStaffData,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
    deleteId,
    setDeleteId,
    handleStaffEdit,
    setDeleteModal,
    deleteModal,
    // handleDeleteCancel,
    viewStaff,
    setViewStaff,
  };

  useEffect(() => {
    const data = getStaffList();
    data.then((res) => {
      let tableData = res.map((item, i) => ({
        id: item.staff_id_pk,
        profile: item.profile_image,
        name: item.name,
        email: item.email,
        phone: item.phone,
        designation: item.designation,
      }));
      setStaffTable([...tableData]);
    });
  }, []);
  
  return (
    <StaffContext.Provider value={initialContext}>
      {props.children}
    </StaffContext.Provider>
  );
};

import React, { Fragment, useContext, useEffect, useState } from "react";
import { StaffContext } from "./StaffContext";
import configData from "../../../../config.json";
import { Scrollbars } from "react-custom-scrollbars-2";
import placeholderImg from "../../../../assets/img/user/3.png";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { createStaff } from "../../../../api/services/sap/staff-management";
import Select from "react-select";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import uploadFileId from "../../../../assets/img/icon/upload/add-file.svg";
import DatePicker from "react-date-picker";
import { getLookupDataAPI } from "../../../../api/services/common/commonAPIs";

const EditStaffform = (props) => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    editpic,
    setEditPic,
    viewStaff,
    SetViewStaff,
    editstaff,
    SetEditStaff,
    id,
    idproof,
    setIdProof,
    setAddonId,
    mappedStaff,
    setMappedStaff,
    handleStaffEdit,
    editImageSubmit,
    setEditImageSubmit,
    editFormSubmit,
    getStaffData,
    setEditFormSubmit,
    formChange,
    SetFormChange,
    editProofSubmit,
    setEditProofSubmit,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
    updateOrCreateStaff,
    update,
    addonImages,
    addProofSubmit,
    setProofSubmit,
    addonId,
    setAddonImages,
  } = useContext(StaffContext);

  const [editStaffform, SetEditStaffForm] = useState({ ...mappedStaff });
  // Object.entries(mappedStaff);
  // useState(...mappedStaff);
  const [editStaffID, SetEditStaffID] = useState("");
  const [selectedIdProofValue, setSelectedIdProofValue] = useState([]);
  const [removeMappedIdProof, setremoveMappedIdProof] = useState(false);

  let BloodOptions = [{}];
  let IdOptions = [{}];
  let RelationOptions = [{}];
  let GenderOption = [{}];

  BloodOptions = bloodGroups.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  IdOptions = idproofType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  RelationOptions = relationType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  GenderOption = gender.map((item) => ({
    label: item.title,
    value: item.value,
  }));
  // let a = Array.from(props.pic);
  const sideEffect = () => {
    const bloodGroup = getLookupDataAPI({ type: "blood_group" });
    const idproof = getLookupDataAPI({ type: "idproof_type" });
    const relationType = getLookupDataAPI({ type: "relation_type" });
    const gender = getLookupDataAPI({ type: "gender" });

    bloodGroup
      .then((res) => {
        setBloodGroups(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    idproof
      .then((res) => {
        setIdproofType(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    relationType
      .then((res) => {
        setRelationType(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    gender
      .then((res) => {
        setGender(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });
  };

  useEffect(sideEffect, []);
  //set mapped id proof valueF
  // useEffect(() => {
  //   if (mappedStaff && IdOptions) {
  //     setSelectedIdProofValue(
  //       IdOptions?.filter(
  //         (item) =>
  //           item?.value === mappedStaff?.staffIdProof?.idProofType?.value
  //       )
  //     );
  //   }
  // }, []);

  // const testId = editStaffform.staff_id_pk;

  const StaffEditSubmitHandler = (e) => {
    e.preventDefault();
    const formData = {
      ...editStaffform,
    };
    console.log(mappedStaff.profile_image);
    const updatedStaffData = createStaff({
      ...formData,
      // profile_image: [Object.assign({}, ...mappedStaff.profile_image)],
      profile_image: mappedStaff.profile_image.length
        ? [Object.assign({}, ...mappedStaff.profile_image)]
        : (mappedStaff["profile_image"] = [...addonImages]),
    });

    updatedStaffData
      .then((res) => {
        let index = undefined;
        let newTable = [];
        let existingRecord = staffTable.filter((item, i) => {
          const value = item.id === res.staff_id_pk;
          if (value) index = i;
          return value;
        });
        if (existingRecord.length) {
          newTable = staffTable.map((item, i) => {
            if (i === index) {
              item = {
                id: res.staff_id_pk,
                profile: res.profile_image,
                name: res.name,
                email: res.email,
                phone: res.phone,
                designation: res.designation,
              };
            }

            return item;
          });
        } else {
          newTable = [
            {
              id: res.staff_id_pk,
              profile: res.profile_image,
              name: res.name,
              email: res.email,
              phone: res.phone,
              designation: res.designation,
            },
            ...staffTable,
          ];
          setStaffTable(newTable);
          setEditFormSubmit(true);
          setPic([]);
          setIdProof([]);
          setAddonId([]);
          props.closeStaffEdit(false);
          toast.success("Updated successfully", {
            position: "top-right",
          });
        }
        setStaffTable(newTable);
        setEditFormSubmit(true);
        setPic([]);
        setIdProof([]);
        setAddonId([]);
        props.closeStaffEdit(false);
        toast.success("Updated successfully", {
          position: "top-right",
        });
        //   handleStaffEdit({
        //     ...editStaffform,
        //     id: testId,
        //     profile_image: [Object.assign({}, ...pic)],
        //     // idproof: [...mappedStaff["idproof"]],
        //   });

        //   setEditFormSubmit(true);
        //   setPic([]);
        //   setIdProof([]);
        //   setAddonId([]);
        //   props.closeStaffEdit(false);
        //   toast.success("editStaffform Updated successfully", {
        //     position: "top-right",
        //   });
        // })
        // .catch((err) => {
        //   console.log(err);
        //   if (err.data) {
        //     toast.error(err.data.errors[0].msg, {
        //       position: "bottom-right",
        //     });
        //   } else {
        //     toast.error(err.message, {
        //       position: "bottom-right",
        //     });
        //   }
        getStaffData(res.staff_id_pk);
        setProofSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.data) {
          toast.error(err.data.errors[0].msg, {
            position: "bottom-right",
          });
        } else {
          toast.error(err.message, {
            position: "bottom-right",
          });
        }
      });

    //   handleStaffEdit({
    //    ...editStaffform,
    //    id: testId,
    //    // pic: [...mappedStaff[0]["pic"]],
    //    // idproof: [...mappedStaff[0]["idproof"]],
    //  });
    //  setEditFormSubmit(true);
    //  setPic([]);
    //  setIdProof([]);
    //  setAddonId([]);
    //  props.closeStaffEdit(false);
    //  toast.success("editStaffform Updated successfully", {
    //    position: "top-right",
    //  });
  };

  const removeImage = () => {
    mappedStaff.profile_image = "";
    SetEditStaffForm(mappedStaff);
  };
  //Remove img
  const handleImgRemove = (image, i) => {
    let newImg =
      addonImages &&
      addonImages.filter((e, newIndex) => {
        if (newIndex == i) {
          return e !== image;
        }
      });
    setAddonImages(newImg);
    setEditImageSubmit(false);
  };

  //check id extension
  function getExtension(filename) {
    return filename && filename.split(".").pop();
  }

  console.log(mappedStaff, "mappedStaff mappedStaff");
  return (
    <div className="">
      <form className="" onSubmit={StaffEditSubmitHandler}>
        {/* {mappedStaff &&
          mappedStaff.map((e, index) => {

            console.log("edit dataaa", e);
            return ( */}
        <Fragment>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend d-flex align-items-center">
              <span className="input-group-text">
                <i class="fal fa-images"></i>Photo
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="small-block">
              <div className="row-img row">
                {(mappedStaff.profile_image &&
                  editImageSubmit &&
                  addonImages) ||
                (!mappedStaff.profile_image &&
                  editImageSubmit &&
                  addonImages) ? (
                  addonImages.map((photo, index) => {
                    return (
                      <div className="col-img-list">
                        <div className="img-list" key={index}>
                          <button
                            className="btn"
                            onClick={(e, index) =>
                              handleImgRemove(photo, index)
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>{" "}
                          <img
                            src={photo.base64}
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />{" "}
                        </div>
                      </div>
                    );
                  })
                ) : // <div className="col-img-list">
                //   <div className="img-list" >
                //     <button
                //         className="btn"
                //         onClick={(e,index) =>
                //           handleImgRemove(photo, index)
                //         }
                //       >
                //         <i class="fal fa-times"></i>
                //       </button>
                //       <img src={mappedStaff.profile_image[0].base64} className="img-fluid test" alt="" height="100" width="100"/>
                //   </div>
                // </div>
                mappedStaff.profile_image ? (
                  <div className="col-img-list">
                    <div className="img-list">
                      <button
                        type="button"
                        className="btn"
                        onClick={(e) => removeImage()}
                      >
                        <i class="fal fa-times"></i>
                      </button>
                      <img
                        src={`${configData.SERVER_URL}${mappedStaff.profile_image}`}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* <div className="small-img mr-2">
                {mappedStaff && mappedStaff.profile_image && mappedStaff.profile_image[0].base64 ? 
                
                <img
                  src={mappedStaff.profile_image[0].base64}
                  className="img-fluid"
                  alt=""
                  height="100"
                  width="100"
                />
                  :
                  <>
                    {mappedStaff.profile_image && (
                      <>
                          <button type="button"
                            className="btn"
                            onClick={(e) => removeImage()}>
                            <i class="fal fa-times"></i>
                          </button>
                      
                          <img
                            src={`${configData.SERVER_URL}${mappedStaff.profile_image}`}
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />
                      </>
                    )}
                  </>
                }
              </div> */}
              {!addonImages.length &&
              !mappedStaff.profile_image &&
              !editImageSubmit ? (
                <button
                  type="button"
                  className="btn-image"
                  onClick={props.handleShowEditFile}
                >
                  <img src={uploadImageFile} alt="loading..." />
                  <span>Upload</span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-user"></i>Name
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <input
                type="hidden"
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    name: e.target.value,
                  });
                  {
                    editStaffform.name !== e.target.value
                      ? SetFormChange(true)
                      : SetFormChange(false);
                  }
                }}
                minLength={3}
                maxLength={12}
                value={editStaffform.staff_id_pk}
              />
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    name: e.target.value,
                  });
                  {
                    editStaffform.name !== e.target.value
                      ? SetFormChange(true)
                      : SetFormChange(false);
                  }
                  // if (e.target.value.length > 0) {
                  //   SetFormChange(true);
                  //   console.log(formChange, " is value")
                  // } else {
                  //   SetFormChange(false);
                  // }
                }}
                minLength={3}
                maxLength={12}
                value={editStaffform.name}
              />
              {/* <span className="form-error">Name is required!</span> */}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-envelope"></i>E-mail
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    email: e.target.value,
                  })
                }
                // value={mappedStaff.name ? mappedStaff.name : editStaffform.name ? editStaffform.name : e.name}

                value={editStaffform.email}
                type="text"
                className="form-control"
              />
              {/* <span className="form-error">E-mail is required!</span> */}
            </div>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-phone"></i>Phone Number
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <div className="row m-0">
                <input
                  disabled
                  type="text"
                  className="form-control phone-extension"
                  placeholder="091"
                  value={editStaffform.phone_extension}
                  defaultValue={editStaffform.phone_extension}
                  style={{ maxWidth: "60px", marginRight: " 10px" }}
                />
                <input
                  onChange={(e) =>
                    SetEditStaffForm({
                      ...editStaffform,
                      phone: e.target.value,
                    })
                  }
                  defaultValue={editStaffform.phone}
                  type="num"
                  className="form-control"
                />
                {/* <span className="form-error">
                        Phone Number is required!
                      </span> */}
              </div>
            </div>
          </div>

          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-map-marker-alt"></i>Address
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <textarea
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    address: e.target.value,
                  })
                }
                value={editStaffform.address}
                class="form-control"
              ></textarea>
              {/* <span className="form-error">Address is required!</span> */}
            </div>
          </div>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-tint"></i>Gender
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <Select
                // defaultValue={e.gender}
                defaultValue={GenderOption.find(
                  (x) => x.value === editStaffform.gender
                )}
                options={GenderOption}
                onChange={(e) =>
                  SetEditStaffForm(
                    { ...editStaffform, gender: e.value },
                    SetFormChange(true)
                  )
                }
              />
            </div>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>Date of Birth
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <DatePicker
                maxDate={new Date()}
                format="dd/MM/y"
                type="date"
                calendarIcon={null}
                clearIcon={null}
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    date_of_birth: e,
                  });
                  if (e && e !== editStaffform.date_of_birth) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                }}
                value={new Date(editStaffform.date_of_birth)}
              />
              {/* <span className="form-error">Date of Joining is required!</span> */}
            </div>
          </div>

          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-tint"></i>Blood Group
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <Select
                defaultValue={BloodOptions.find(
                  (x) => x.value === editStaffform.blood_group_id
                )}
                options={BloodOptions}
                onChange={(e) =>
                  SetEditStaffForm(
                    { ...editStaffform, blood_group_id: e.value },
                    SetFormChange(true)
                  )
                }
              />

              {/* <span className="form-error">Blood Group is required!</span> */}
            </div>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-user-tag"></i>Designation
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    designation: e.target.value,
                  })
                }
                value={editStaffform.designation}
                type="text"
                className="form-control"
              />
              {/* <span className="form-error">Designation is required!</span> */}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-money-bill-alt"></i>Salary
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    salary: e.target.value,
                  })
                }
                value={editStaffform.salary}
                type="text"
                className="form-control"
              />
              {/* <span className="form-error">Salary is required!</span> */}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>Date of Joining
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <DatePicker
                maxDate={new Date()}
                format="dd/MM/y"
                type="date"
                calendarIcon={null}
                clearIcon={null}
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    date_of_joining: e,
                  });
                  if (e && e !== editStaffform.date_of_joining) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                }}
                value={new Date(editStaffform.date_of_joining)}
              />
              {/* <span className="form-error">Date of Joining is required!</span> */}
            </div>
          </div>
          <div class="col-12 block-header main-title">
            <h6>
              <span class="header-title">ID Proof</span>
            </h6>
          </div>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-paste"></i>Type
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="upload-group">
              <div className="row m-0">
                <div className="col-md-8 upload-group-col">
                  <div className="input-block">
                    <Select
                      defaultValue={IdOptions.find((x) =>
                        x.value ===
                        (editStaffform.staffIdProof &&
                          editStaffform.staffIdProof.id_proof_type)
                          ? editStaffform.staffIdProof.id_proof_type
                          : 0
                      )}
                      options={IdOptions}
                      onChange={(e) => {
                        SetEditStaffForm(
                          {
                            ...editStaffform,
                            staff_id_proof_type: e.value,
                          },
                          SetFormChange(true)
                        );
                        setSelectedIdProofValue(
                          IdOptions?.filter((item) => item?.value === e.value)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 ">
                  <button
                    type="button"
                    className="btn-image"
                    onClick={props.handleShowEditId}
                  >
                    <img src={uploadFileId} className="pdf-file" alt="" />
                    Upload{" "}
                    {editStaffform.prooftype &&
                    editStaffform.prooftype == "Choose..."
                      ? ""
                      : selectedIdProofValue &&
                        ` ${selectedIdProofValue?.[0]?.label}`}
                  </button>
                </div>
              </div>
            </div>
            <div className="file-upload-view">
              <div className="small-block">
                <div class="row-img row">
                  {addProofSubmit && addonId ? (
                    addonId.map((proof, index) => {
                      return (
                        <Fragment>
                          <div class="img-list-col">
                            <div className="img-list" key={proof}>
                              <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                  setAddonId(addonId.filter((e) => e !== proof))
                                }
                              >
                                <i class="fal fa-times"></i>
                              </button>
                              <div>
                                {getExtension(proof["name"]).toLowerCase() ===
                                "pdf" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-pdf"></i>
                                    </span>
                                    <h5>{proof.name}</h5>
                                  </div>
                                ) : getExtension(
                                    proof["name"]
                                  ).toLowerCase() === "docx" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-word word"></i>
                                    </span>
                                    <h5>{proof.name}</h5>
                                  </div>
                                ) : getExtension(
                                    proof["name"]
                                  ).toLowerCase() === "doc" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-word word"></i>
                                    </span>
                                    <h5>{proof.name}</h5>
                                  </div>
                                ) : (
                                  <img
                                    src={proof.base64}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                  ) : mappedStaff.staffIdProof &&
                    mappedStaff.staffIdProof.id_proof &&
                    !removeMappedIdProof ? (
                    // mappedStaff.staffIdProof.idProofType.map((proof, index) => {
                    //   return (
                    <Fragment className="mr-2 pl-2 pr-2">
                      <div class="img-list-col">
                        <div className="img-list">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => setremoveMappedIdProof(true)}
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <div>
                            {getExtension(
                              mappedStaff.staffIdProof.id_proof
                            ).toLowerCase() === "pdf" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-pdf"></i>
                                </span>
                                <h5>
                                  {mappedStaff.staffIdProof.idProofType.title}
                                </h5>
                              </div>
                            ) : getExtension(
                                mappedStaff.staffIdProof.id_proof
                              ).toLowerCase() === "docx" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-word word"></i>
                                </span>
                                <h5>
                                  {mappedStaff.staffIdProof.idProofType.title}
                                </h5>
                              </div>
                            ) : getExtension(
                                mappedStaff.staffIdProof.id_proof
                              ).toLowerCase() === "doc" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-word word"></i>
                                </span>
                                <h5>
                                  {mappedStaff.staffIdProof.idProofType.title}
                                </h5>
                              </div>
                            ) : (
                              <div className="small-img" key="">
                                <img
                                  src={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                    // <div className="img-list">
                    //   <div className="pdf-block ">
                    //     <span className="pdf-icon">
                    //       <i class="fal fa-file-times"></i>
                    //     </span>
                    //     <h5>No data uploaded</h5>
                    //   </div>
                    // </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 block-header main-title">
            <h6>
              <span class="header-title">Emergency Contact Details</span>
            </h6>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-user"></i>Name
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    emergency_details_name: e.target.value,
                  })
                }
                defaultValue={
                  editStaffform.staffEmergencyDetail &&
                  editStaffform.staffEmergencyDetail.name
                    ? editStaffform.staffEmergencyDetail.name
                    : ""
                }
                type="text"
                className="form-control"
              />
              {/* <span className="form-error">
                      Emergency Contact Name is required!
                    </span> */}
            </div>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-phone"></i>Phone Number
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <div className="row m-0">
                <input
                  onChange={(e) =>
                    SetEditStaffForm({
                      ...editStaffform,
                      emergency_details_phone: e.target.value,
                    })
                  }
                  defaultValue={
                    editStaffform.staffEmergencyDetail &&
                    editStaffform.staffEmergencyDetail.phone
                      ? editStaffform.staffEmergencyDetail.phone
                      : ""
                  }
                  type="num"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-users"></i>Relation
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <Select
                disabled
                // defaultValue={e.gender}
                defaultValue={RelationOptions.find((x) =>
                  x.value ===
                  (editStaffform.staffEmergencyDetail &&
                    editStaffform.staffEmergencyDetail.relation_type)
                    ? editStaffform.staffEmergencyDetail.relation_type
                    : 0
                )}
                options={RelationOptions}
                onChange={(e) =>
                  SetEditStaffForm(
                    {
                      ...editStaffform,
                      emergency_details_relation_type: e.value,
                    },
                    SetFormChange(true)
                  )
                }
              />

              {/* <select
                      onChange={(e) =>
                        SetEditStaffForm({
                          ...editStaffform,
                          relation: e.target.value,
                        })
                      }
                      value={editStaffform.relation}
                      id="warranty_period"
                      class="form-select "
                    >
                      <option value="" disabled selected>
                        Choose...
                      </option>
                      <option value="Parent">Parent</option>
                      <option value="Family">Family</option>
                      <option value="Brother">Brother</option>
                      <option value="Others">Others</option>
                    </select> */}
              {/* <span className="form-error">Relation is required!</span> */}
            </div>
          </div>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-map-marker-alt"></i>Emergency Address
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <textarea
                class="form-control"
                defaultValue={
                  editStaffform.staffEmergencyDetail &&
                  editStaffform.staffEmergencyDetail.address
                    ? editStaffform.staffEmergencyDetail.address
                    : ""
                }
                onChange={(e) =>
                  SetEditStaffForm(
                    {
                      ...editStaffform,
                      emergency_details_address: e.target.value,
                    },
                    SetFormChange(true)
                  )
                }
              ></textarea>
              {/* <span className="form-error">Address is required!</span> */}
            </div>
          </div>
          <div class="col-12 text-end p-0 mt-3">
            <button class="btn btn-update" type="submit">
              <i class="fal fa-save mr-2"></i>Update
            </button>
          </div>
        </Fragment>
        {/* );
          })} */}
      </form>
    </div>
  );
};

export default EditStaffform;

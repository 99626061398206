import React, { Fragment, useContext, useEffect, useState } from "react";
import { StaffContext } from "./StaffContext";
import { v4 as uuidv4 } from "uuid";
import "./Ui/css/style.css";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-date-picker";
import dateFormat, { masks } from "dateformat";
import { createStaff } from "../../../../api/services/sap/staff-management";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import uploadFileId from "../../../../assets/img/icon/upload/add-file.svg";
import { getLookupDataAPI } from "../../../../api/services/common/commonAPIs";
import { Validation } from "./Ui/Validation";
import { submitValidation } from "./Ui/submitValidation";

let initialstate = {
  name: "",
  email: "",
  emergency_details_phone_extension: "091",
  emergency_details_phone: "",
  emergency_details_name: "",
  emergency_details_relation_type: "",
  emergency_details_address: "",
  phone_extension: "091",
  phone: "",
  address: "",
  designation: "",
  gender: "",
  date_of_birth: new Date(),
  salary: "",
  blood_group_id: "",
  date_of_joining: new Date(),
  staff_id_proof_type: "",
  employeeid: "",
  id: "",
};

const errorFields = {
  name: "",
  email: "",
  phone: "",
  address: "",
  date_of_birth: "",
  blood_group_id: "",
  staff_id_proof_type: "",
  emergency_details_name: "",
  emergency_details_phone: "",
  emergency_details_relation_type: "",
  pic: [],
  idproof: [{}],
};

const AddStaffForm = (props) => {
  const [staffform, SetStaffForm] = useState(initialstate);
  //form validation state
  const [formErrors, SetFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [selectedIdProofValue, setSelectedIdProofValue] = useState([]);
  console.log(selectedIdProofValue, "selectedIdProofValue");

  // Staff Context
  const {
    setStaffTable,
    pic,
    setPic,
    idproof,
    setIdProof,
    addImageSubmit,
    setAddImageSubmit,
    SetFormChange,
    addProofSubmit,
    updateOrCreateStaff,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
    staffTable,
  } = useContext(StaffContext);

  let BloodOptions = [{}];
  let IdOptions = [{}];
  let RelationOptions = [{}];
  let GenderOption = [{}];

  BloodOptions = bloodGroups.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  IdOptions = idproofType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  RelationOptions = relationType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  GenderOption = gender.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  const onChangeValidate = (field, value) => {
    const errors = Validation(staffform, value, true, field);

    if (Object.keys(errors).length != 0) {
      setError(true);
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length != 0) {
        // delete formErrors[field];
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setError(false);
        // SetFormErrors(errorFields);
      }
    }
  };
  // console.log(staffform, "before values");

  //Form Submit Handler
  const StaffSubmitHandler = (e) => {
    e.preventDefault();
    // submitValidation(staffform);

    setFormSubmit(true);
    // SetFormErrors(errors);
    //validation onsubmit
    let errors = Validation(staffform);

    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      const formData = {
        ...staffform,
      };
      const createStaffData = createStaff({
        ...formData,
        profile_image: pic.length ? [Object.assign({}, ...pic)] : [],
        staff_id_proof: [...idproof],
      });
      console.log(createStaffData.profile_image, "profile_image");
      console.log(createStaffData.staff_id_proof, "staff_id_proof");
      console.log(pic, "picstaff_id_proof");
      console.log(idproof, "staff_id_proof");
      createStaffData
        .then((res) => {
          let index = undefined;
          let newTable = [];
          let existingRecord = staffTable.filter((item, i) => {
            const value = item.id === res.staff_id_pk;
            if (value) {
              index = i;
            }
            return value;
          });
          if (existingRecord.length) {
            newTable = staffTable.map((item, i) => {
              if (i === index) {
                item = {
                  id: res.staff_id_pk,
                  name: res.name,
                  profile: res.profile_image,
                };
              }
              setStaffTable(newTable);
              SetStaffForm([]);
              setPic([]);
              setIdProof([]);
              props.closeform(false);
              SetFormChange(false);
              toast.success("Staff Created Successfully", {
                position: "bottom-right",
              });
            });
          } else
            newTable = [
              {
                id: res.staff_id_pk,
                profile: res.profile_image,
                name: res.name,
                email: res.email,
                phone: res.phone,
                designation: res.designation,
              },
              ...staffTable,
            ];
          setStaffTable(newTable);
          SetStaffForm([]);
          setPic([]);
          setIdProof([]);
          props.closeform(false);
          SetFormChange(false);
          toast.success("Staff Created Successfully", {
            position: "bottom-right",
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.data) {
            toast.error(err.data.errors[0].msg, {
              position: "bottom-right",
            });
          } else {
            toast.error(err.message, {
              position: "bottom-right",
            });
          }
        });
    }
    // setStaffTable((prevStaff) => [
    //   ...prevStaff,
    //   {
    //     ...staffform,
    //     pic: pic,
    //     id: 1,
    //     idproof: idproof,
    //     // producttags: producttags,
    //   },
    // ]);
  };

  const sideEffect = () => {
    const bloodGroup = getLookupDataAPI({ type: "blood_group" });
    const idproofType = getLookupDataAPI({ type: "idproof_type" });
    const relationType = getLookupDataAPI({ type: "relation_type" });
    const gender = getLookupDataAPI({ type: "gender" });

    bloodGroup
      .then((res) => {
        setBloodGroups(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    idproofType
      .then((res) => {
        setIdproofType(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    relationType
      .then((res) => {
        setRelationType(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });

    gender
      .then((res) => {
        setGender(res.data);
      })
      .catch((e) => {
        //error handling
        console.log(e);
      });
  };

  useEffect(sideEffect, []);
  //check id extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);

  // const removeimagehandler = (data ,dIndex) =>{
  //   let imagePic = pic && pic.filter((img, index) =>{
  //     if(dIndex){
  //       index != data;
  //       return index
  //     }

  //   })
  //   console.log(imagePic, "................imagePic");
  //   return imagePic

  // }
  //Remove img
  const handleImgRemove = (image, i) => {
    let newImg =
      pic &&
      pic.filter((e, newIndex) => {
        if (newIndex == i) {
          return e !== image;
        }
      });
    setPic(newImg);
    setAddImageSubmit(false);
  };

  console.log(idproofType, "idproofType - 1");
  return (
    <div className="">
      <form className="" onSubmit={StaffSubmitHandler}>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend d-flex align-items-center">
            <span className="input-group-text">
              <i class="fal fa-images"></i>Photo
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="small-block">
            <div className="row-img row">
              {addImageSubmit &&
                pic &&
                pic.map((photo, index) => {
                  return (
                    <div className="col-img-list">
                      <div className="img-list" key={index}>
                        <button
                          className="btn"
                          onClick={(e, index) => handleImgRemove(photo, index)}
                        >
                          <i class="fal fa-times"></i>
                        </button>{" "}
                        <img
                          src={photo.base64}
                          className="img-fluid"
                          alt=""
                          height="100"
                          width="100"
                        />{" "}
                      </div>
                    </div>
                  );
                })}
            </div>
            {props.addstaff && !pic.length ? (
              <button
                type="button"
                className="btn-image"
                onClick={props.handleShow}
              >
                <div className="upload-section justify-content-center">
                  <div className="upload-img">
                    <img src={uploadImageFile} alt="loading..." />
                  </div>
                </div>
                Upload
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, name: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("name", e.target.value);
              }}
              value={staffform.name}
              onBlur={(e) => onChangeValidate("name", e.target.value)}
            />
            {error && formErrors.name ? (
              <span className="form-error">{formErrors.name}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-envelope"></i>E-mail
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, email: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("email", e.target.value);
              }}
              onBlur={(e) => onChangeValidate("email", e.target.value)}
              value={staffform.email}
            />
            {error && formErrors.email ? (
              <span className="form-error">{formErrors.email}</span>
            ) : (
              ""
            )}
            {/* <span className="form-error">E-mail is required!</span> */}
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <div className="row m-0">
              <input
                disabled
                type="text"
                className="form-control phone-extension"
                placeholder="091"
                value={staffform.phone_extension}
                defaultValue={staffform.phone_extension}
                style={{ maxWidth: "60px", marginRight: " 10px" }}
              />
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  SetStaffForm({
                    ...staffform,
                    phone: e.target.value.replace(/[^0-9]/g, ""),
                  });
                  if (e.target.value && e.target.value.length > 0) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                  onChangeValidate("phone", e.target.value);
                }}
                onBlur={(e) => onChangeValidate("phone", e.target.value)}
                value={staffform.phone}
              />
              {error && formErrors.phone ? (
                <span className="form-error">{formErrors.phone}</span>
              ) : (
                ""
              )}

              {/* <span className="form-error">Phone Number is required!</span> */}
            </div>
          </div>
        </div>

        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-map-marker-alt"></i>Address
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <textarea
              class="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, address: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                // onChangeValidate("address", e.target.value)
              }}
              // onBlur={(e) => onChangeValidate("address", e.target.value)}
              value={staffform.address}
            ></textarea>
            {error && formErrors.address ? (
              <span className="form-error">{formErrors.address}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-tint"></i>Gender
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <Select
              options={GenderOption}
              onChange={(e) =>
                SetStaffForm(
                  { ...staffform, gender: e.value },
                  SetFormChange(true)
                )
              }
            />
          </div>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Date of Birth
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <DatePicker
              maxDate={new Date()}
              format="dd/MM/y"
              calendarIcon={null}
              clearIcon={null}
              className="form-control"
              onChange={(e) => {
                // alert(e);

                SetStaffForm({ ...staffform, date_of_birth: e });
                if (e && e !== staffform.date_of_birth) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("date_of_birth", e);
              }}
              onBlur={(e) => onChangeValidate("date_of_birth", e)}
              value={staffform.date_of_birth}
              defaulValue={staffform.date_of_birth}
            />
            {error && formErrors.date_of_birth ? (
              <span className="form-error">{formErrors.date_of_birth}</span>
            ) : (
              ""
            )}
            {/* <span className="form-error">Date of Joining is required!</span> */}
          </div>
        </div>

        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-tint"></i>Blood Group
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <Select
              options={BloodOptions}
              onChange={(e) => {
                SetStaffForm(
                  { ...staffform, blood_group_id: e.value },
                  SetFormChange(true)
                );
                onChangeValidate("blood_group_id", e.value);
              }}
              onBlur={(e) =>
                onChangeValidate("blood_group_id", staffform.blood_group_id)
              }
            />
            {error && formErrors.blood_group_id ? (
              <span className="form-error">{formErrors.blood_group_id}</span>
            ) : (
              ""
            )}
            {/* <span className="form-error">Blood Group is required!</span> */}
          </div>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-user-tag"></i>Designation
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, designation: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.designation}
            />
            {/* <span className="form-error">Designation is required!</span> */}
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-money-bill-alt"></i>Salary
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, salary: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.salary}
            />
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Date of Joining
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <DatePicker
              format="dd/MM/y"
              calendarIcon={null}
              clearIcon={null}
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, date_of_joining: e });
                if (e && e !== staffform.date_of_joining) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.date_of_joining}
            />
            {/* <span className="form-error">Date of Joining is required!</span> */}
          </div>
        </div>
        <div class="col-12 block-header main-title">
          <h6>
            <span class="header-title">ID Proof</span>
          </h6>
        </div>
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-paste"></i>Type
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="upload-group">
            <div className="row m-0">
              <div className="col-md-8 upload-group-col">
                <div className="input-block">
                  <Select
                    options={IdOptions}
                    onChange={(e) => {
                      SetStaffForm(
                        { ...staffform, staff_id_proof_type: e.value },
                        SetFormChange(true)
                      );
                      setSelectedIdProofValue(
                        IdOptions?.filter((item) => item?.value === e.value)
                      );
                      onChangeValidate("staff_id_proof_type", e.value);
                    }}
                    onBlur={(e) =>
                      onChangeValidate(
                        "staff_id_proof_type",
                        staffform.staff_id_proof_type
                      )
                    }
                  />
                  {error && formErrors.staff_id_proof_type ? (
                    <span className="form-error">
                      {formErrors.staff_id_proof_type}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-4 p-0">
                {staffform.staff_id_proof_type ? (
                  <button
                    type="button"
                    className="btn-image"
                    onClick={props.handleShowID}
                  >
                    <img src={uploadFileId} className="pdf-file" alt="" />
                    Upload
                    {staffform.staff_id_proof_type &&
                    staffform.staff_id_proof_type == "Choose..."
                      ? ""
                      : ` ${selectedIdProofValue?.[0]?.label}`}
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn-image"
                    onClick={props.handleShowID}
                  >
                    <img src={uploadFileId} className="pdf-file" alt="" />
                    Upload
                  </button>
                )}
              </div>
            </div>
            {/* <span className="form-error">ID Proof Type is required!</span> */}
          </div>
          <div className="file-upload-view">
            <div className="small-block">
              <div class="row-img row">
                {addProofSubmit &&
                  idproof &&
                  idproof.map((proof, index) => {
                    return (
                      <Fragment>
                        <div class="img-list-col">
                          <div className="img-list" key={proof}>
                            <button
                              type="button"
                              className="btn"
                              onClick={() =>
                                setIdProof(idproof.filter((e) => e !== proof))
                              }
                            >
                              <i class="fal fa-times"></i>
                            </button>
                            <div>
                              {getExtension(proof["name"]).toLowerCase() ===
                              "pdf" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-file-pdf"></i>
                                  </span>
                                  <h5>{proof.name}</h5>
                                </div>
                              ) : getExtension(proof["name"]).toLowerCase() ===
                                "docx" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-file-word word"></i>
                                  </span>
                                  <h5>{proof.name}</h5>
                                </div>
                              ) : getExtension(proof["name"]).toLowerCase() ===
                                "doc" ? (
                                <div className="pdf-block ">
                                  <span className="pdf-icon">
                                    <i class="fal fa-word word"></i>
                                  </span>
                                  <h5>{proof.name}</h5>
                                </div>
                              ) : (
                                <img
                                  src={proof.base64}
                                  className="img-fluid"
                                  alt=""
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="input-group input-group-custom">
          <div className="input-group-prepend d-flex align-items-center">
            <span className="input-group-text">
              <i class="fal fa-file-pdf"></i>ID Proof
            </span>
          </div>
          <div className="small-block">
            {pic &&
              pic.map((photo, index) => {
                console.log(photo);
                return (
                  <div className="small-img" key={index}>
                    <img
                      src={photo.base64}
                      className="img-fluid"
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                );
              })}
          </div>
        </div> */}

        <div class="col-12 block-header main-title">
          <h6>
            <span class="header-title">Emergency Contact Details</span>
          </h6>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({
                  ...staffform,
                  emergency_details_name: e.target.value,
                });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("emergency_details_name", e.target.value);
              }}
              onBlur={(e) =>
                onChangeValidate("emergency_details_name", e.target.value)
              }
              value={staffform.emergency_details_name}
            />
            {error && formErrors.emergency_details_name ? (
              <span className="form-error">
                {formErrors.emergency_details_name}
              </span>
            ) : (
              ""
            )}
            {/* <span className="form-error">
              Emergency Contact Name is required!
            </span> */}
          </div>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <div className="row m-0">
              <input
                type="text"
                className="form-control phone-extension"
                placeholder="091"
                value={staffform.emergency_details_phone_extension}
                defaultValue={staffform.emergency_details_phone_extension}
                style={{ maxWidth: "60px", marginRight: " 10px" }}
              />
              <input
                type="tel"
                className="form-control"
                // onChange={(e) => {
                //   SetStaffForm({
                //     ...staffform,
                //     emergency_details_phone: e.target.value,
                //   });
                //   if (e.target.value && e.target.value.length > 0) {
                //     SetFormChange(true);
                //   } else {
                //     SetFormChange(false);
                //   }
                // }}
                // onBlur={(e) => onChangeValidate("emergency_details_phone")}
                value={staffform.emergency_details_phone}
                onChange={(e) => {
                  SetStaffForm({
                    ...staffform,
                    emergency_details_phone: e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    ),
                  });
                  if (e.target.value && e.target.value.length > 0) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                  onChangeValidate("emergency_details_phone", e.target.value);
                }}
                onBlur={(e) =>
                  onChangeValidate("emergency_details_phone", e.target.value)
                }
              />
              {error && formErrors.emergency_details_phone ? (
                <span className="form-error">
                  {formErrors.emergency_details_phone}
                </span>
              ) : (
                ""
              )}
              {/* <span className="form-error">emergencycontact Number is required!</span> */}
            </div>
          </div>
        </div>

        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-users"></i>Relation
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <Select
              options={RelationOptions}
              onChange={(e) => {
                SetStaffForm(
                  { ...staffform, emergency_details_relation_type: e.value },
                  SetFormChange(true)
                );
                onChangeValidate("emergency_details_relation_type", e.value);
              }}
              onBlur={(e) =>
                onChangeValidate(
                  "emergency_details_relation_type",
                  staffform.emergency_details_relation_type
                )
              }
            />
            {error && formErrors.emergency_details_relation_type ? (
              <span className="form-error">
                {formErrors.emergency_details_relation_type}
              </span>
            ) : (
              ""
            )}
            {/* <span className="form-error">Relation is required!</span> */}
          </div>
        </div>
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-map-marker-alt"></i>Emergency Address
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <textarea
              class="form-control"
              onChange={(e) => {
                SetStaffForm({
                  ...staffform,
                  emergency_details_address: e.target.value,
                });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.emergency_details_address}
            ></textarea>
            {/* <span className="form-error">Address is required!</span> */}
          </div>
        </div>
        <div class="col-12 text-end p-0 mt-3">
          <button class="btn btn-update" type="submit">
            <i class="fal fa-save mr-2"></i>Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddStaffForm;

import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import LogoUploadModal from "./LogoUploadModal";
import ReactTooltip from "react-tooltip-rc";
import configData from "../../../../../config.json";
import { toast } from "react-toastify";
import {
  getProfileInfo,
  updateProfileInfo,
} from "../../../../../api/services/sap/profile-management";
import { InfoValidation } from "../Ui/InfoValidation";

const InfoTab = () => {
  const {
    innertab,
    userProfile,
    setUserProfile,
    sapLogo,
    addImageSubmit,
    handleShow,
    infoviewonly, setInfoViewOnly,
  } = useContext(ProfileContext);

  //Validation State
  const [formErrors, SetFormErrors] = useState({});
  const [error, setError] = useState(false);
  //ON change Validate
  const onChangeValidate = (field, value) => {
    const errors = InfoValidation(userProfile, value, true, field);
    if (Object.keys(errors).length != 0) {
      setError(true);
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setError(false);
      }
    }
  };

  const [formsubmit, setFormSubmit] = useState(false);
  //preFormSubmitHandler
  const infoFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    let errors = InfoValidation(userProfile);
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setInfoViewOnly(true);
      setUserProfile({ ...userProfile, logo_image: sapLogo });
      const updatedSapData = updateProfileInfo({
        ...userProfile,
        logo_image: sapLogo[0],
      });
      toast.success("Details Updated Successfully", {
        position: "top-right",
      });
      updatedSapData && updatedSapData.then((res) => {
        const id = localStorage.getItem("userid");
        const data = getProfileInfo(id);
        data.then((response) => {
          setUserProfile(response);
          localStorage.setItem("userName", response.service_center_name);
          localStorage.setItem(
            "serviceCenterName",
            response.service_center_name
          );
        });
      });
    }
  };
  return (
    <div
      className={innertab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <form className="" onSubmit={infoFormSubmitHandler}>
                {/* <h5 className=" mb-3  text-info">Update Service center Details</h5> */}
                <div className="form-box">
                  <div className="input-group input-group-custom img-block">
                    <div className="small-img-block">
                      {addImageSubmit && sapLogo ? (
                        sapLogo.map((photo, index) => {
                          return (
                            <div
                              className="small-block small-append"
                              key={index}
                            >
                              <img
                                src={photo.base64}
                                className="img-fluid"
                                alt=""
                                height="100"
                                width="100"
                              />{" "}
                            </div>
                          );
                        })
                      ) : userProfile && userProfile.logo ? (
                        <div className="small-block small-append">
                          <img
                            src={userProfile && `${configData.SERVER_URL}/uploads/service_admin/${userProfile.logo}`}
                            className="img-fluid nodata"
                            alt=""
                            height="100"
                            width="100"
                          />
                        </div>
                      ) : ""}
                      <div className="small-block">
                        <button
                          disabled={infoviewonly ? true : false}
                          type="button"
                          className="btn-image"
                          onClick={handleShow}
                        >
                          <div className="upload-section justify-content-center">
                            <div className="upload-img">
                              <img src={uploadImageFile} alt="loading..." />
                            </div>
                          </div>
                          Upload Logo
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Service Center Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-warehouse"></i>Service Center Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={infoviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Service center name..."
                        onChange={(e) => {
                          setUserProfile({
                            ...userProfile,
                            service_center_name: e.target.value,
                          });
                          onChangeValidate(
                            "service_center_name",
                            e.target.value
                          );
                        }}
                        value={userProfile && userProfile.service_center_name ? userProfile.service_center_name : ""}
                        onBlur={(e) => {
                          if (!infoviewonly) {
                            onChangeValidate(
                              "service_center_name",
                              e.target.value
                            )
                          }
                        }

                        }
                      />
                      {error && formErrors.service_center_name ? (
                        <span className="form-error">
                          {formErrors.service_center_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* Phone Number */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-phone"></i>Phone Number
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        type="text"
                        readOnly={infoviewonly ? true : false}
                        className="form-control"
                        placeholder="Phone Number..."
                        onChange={(e) => {
                          setUserProfile({
                            ...userProfile,
                            phone: e.target.value.replace(/[^0-9]/g, ""),
                          });
                          onChangeValidate("phone", e.target.value);
                        }}
                        value={userProfile && userProfile.phone ? userProfile.phone : ""}
                        onBlur={(e) => {
                          if (!infoviewonly) {
                            onChangeValidate("phone", e.target.value)
                          }
                        }

                        }
                      />
                      {error && formErrors.phone ? (
                        <span className="form-error">
                          {formErrors.phone}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* <span className="form-error">Phone Number is required!</span> */}
                    </div>
                  </div>
                  {/* Service Center Mail Id */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-envelope"></i>Email
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={infoviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Email id..."
                        onChange={(e) => {
                          setUserProfile({
                            ...userProfile,
                            email: e.target.value,
                          });
                          onChangeValidate("email", e.target.value);
                        }}
                        value={userProfile && userProfile.email ? userProfile.email : ""}
                        onBlur={(e) => {
                          if (!infoviewonly) {
                            onChangeValidate("email", e.target.value)
                          }
                        }

                        }
                      />
                      {error && formErrors.email ? (
                        <span className="form-error">
                          {formErrors.email}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Service Center Owner Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-user-shield"></i>Owner Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={infoviewonly ? true : false}
                        type="text"
                        className="form-control"
                        placeholder="Owner name..."
                        onChange={(e) => {
                          setUserProfile({
                            ...userProfile,
                            owner_name: e.target.value,
                          });
                          onChangeValidate("owner_name", e.target.value);
                        }}
                        value={userProfile && userProfile.owner_name ? userProfile.owner_name : ""}
                        onBlur={(e) => {
                          if (!infoviewonly) {
                            onChangeValidate("owner_name", e.target.value)
                          }
                        }

                        }
                      />
                      {error && formErrors.owner_name ? (
                        <span className="form-error">
                          {formErrors.owner_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Address */}
                  <div class="input-group input-group-custom">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fal fa-home"></i>Address
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <textarea
                        readOnly={infoviewonly ? true : false}
                        class="form-control"
                        placeholder="Address... "
                        onChange={(e) => {
                          setUserProfile({
                            ...userProfile,
                            address: e.target.value,
                          });
                          onChangeValidate("address", e.target.value);
                        }}
                        onBlur={(e) => {
                          if (!infoviewonly) {
                            onChangeValidate("address", e.target.value)
                          }
                        }

                        }
                        value={userProfile && userProfile.address ? userProfile.address : ""}
                      ></textarea>
                      {error && formErrors.address ? (
                        <span className="form-error">
                          {formErrors.address}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* <span className="form-error">Address is required!</span> */}
                    </div>
                  </div>
                </div>
                {!infoviewonly && (
                  <div class="col-12 text-end p-0 mt-3">
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
          <LogoUploadModal />
        </div>
      </Scrollbars>
    </div>
  );
};

export default InfoTab;

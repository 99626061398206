import ServiceRow from "./ServiceRow";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { ServiceContext } from "./ServiceContext";
import { v4 as uuidv4 } from "uuid";
import "../../Layout/Ui/css/Pagination.css";
import ReactPaginate from "react-paginate";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import DataTable from "react-data-table-component";
import ServiceModal from "./Ui/ServiceModal";
import NavToggle from "./Ui/NavToggle";
import ReactTooltip from "react-tooltip-rc";
const WindowLeft = (props) => {
  const {
    table,
    setEditData,
    SetTable,
    mappedServiceData,
    pic,
    SetEditMode,
    setAddMode,
    filterValue,
    setFilterValue,
    vehicleTypes,
    SetViewReadOnly,
    SetMappedServiceData,
  } = useContext(ServiceContext);
  const [viewservice, SetViewService] = useState(false);
  const [deletemodal, SetDeleteModal] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  // const [activeStatus, setActiveStatus] = useState(table.is_active);
  // console.log(activeStatus)

  const [rowId, setRowId] = useState();
  const [rowName, setRowName] = useState();
  const [rowToggle, setRowToggle] = useState();
  const [rowToggleId, setRowToggleId] = useState();
  const [activeStatus, setActiveStatus] = useState(rowToggle);

  // console.log(rowToggle, "rowToggle");
  let toggleClassCheck = viewservice ? " view" : "";
  //Handle Service Json
  const handleViewService = () => {
    SetViewService(!viewservice);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Pages",
    rangeSeparatorText: "10",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: 0,
  };
  console.log(rowId, "-------rowId-------");
  useEffect(() => {}, [table]);
  //With React
  const handleDelete = (e, serviceId, serviceName) => {
    SetDeleteModal(true);
    e.stopPropagation();
    // console.log(serviceId);
    setRowId(serviceId);
    setRowName(serviceName);
  };
  const setEditForm = (e) => {
    setEditData(e.id, setOpenEditForm);
    SetMappedServiceData(e);
  };
  console.log(mappedServiceData, "mappedServiceData-----mappedServiceData");
  const effectFn = () => {
    if (table.map((e) => e.id === rowToggleId) && activeStatus !== rowToggle) {
      console.log("first");
      setActiveStatus(rowToggle);
    }
    // if (
    //   props.id === mappedServiceData.service_id && activeStatus !== mappedServiceData.is_active
    // ) {
    //   setActiveStatus(mappedServiceData.is_active);
    // }
    if (openEditForm) {
      handleViewService();
      props.serviceformClose(true);
      SetViewReadOnly(true);
      SetEditMode(false);
      setAddMode(false);
      setOpenEditForm(false);
    }
  };
  // let a = Array.from(props.pic);
  // let a = props.pic;

  // useEffect(effectFn, [openEditForm]);
  useEffect(effectFn, [openEditForm, table]);
  const toggleActive = (e, serviceToggleId, serviceToggle) => {
    console.log(e, serviceToggleId, serviceToggle, "full");
    e.stopPropagation();
    setRowToggleId(serviceToggleId);
    setRowToggle(serviceToggle);
  };
  // const vehicleType = vehicleTypes.filter((e) => e.value === vehTypId);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.service_name,
      sortable: true,
      cell: (row) => (
        <div className="">
          {row.service_name}
          {row.status === -1 && (
            <span class="badge rejected ml-2" data-tag="allowRowEvents">
              Rejected
            </span>
          )}
          {row.status === 0 && (
            <span class="badge draft ml-2" data-tag="allowRowEvents">
              Draft Saved
            </span>
          )}
          {row.status === 1 && (
            <span class="badge  ml-2 pending" data-tag="allowRowEvents">
              Pending Review
            </span>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Vehichle Type",
      selector: (row) => row.vehicle_type_id,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {vehicleTypes
            .filter((e) => e.value === row.vehicle_type_id)
            .map((e) => e.title)}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row.status == 2 ? "Active" : "Inactive"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div
          class="btn-group actions toggle-delete-actions"
          role="group"
          aria-label="Basic example"
        >
          <button type="button" class="btn">
            <span
              data-for="delete"
              data-tip="Delete service"
              className="action-delete"
              onClick={(e) => {
                handleDelete(e, row.id, row.service_name, row.vehicle_type_id);
              }}
            >
              <i className="fal fa-trash"></i>
            </span>
          </button>
          <ReactTooltip id="delete" place="top" type="info" effect="float" />

          <button
            disabled={
              (row.is_new == 1 && row.status == 2) ||
              row.status == 2 ||
              (row.is_new != 1 && row.status != -1)
                ? false
                : true
            }
            type="button"
            class="btn"
          >
            <span
              data-for="toggleStatus"
              data-tip="Change status"
              className=""
              mappedId={row.id}
              rowis_active={row.is_active}
              onClick={(e) => toggleActive(e, row.id, row.is_active)}
            >
              {/* {row.is_active} is_active */}
              <NavToggle
                status={row.status}
                id={row.id}
                name={row.service_name}
                mappedServiceData={mappedServiceData}
                isActive={row.is_active}
                // if (table.map((e) => e.id === rowToggleId) && activeStatus !== rowToggle) {
                //   console.log("first");
                //   setActiveStatus(rowToggle);
                // }
                // isActive={activeStatus}
                setActiveStatus={setActiveStatus}
                mappedRowData={row}
              />
            </span>
          </button>
          <ReactTooltip
            id="toggleStatus"
            place="top"
            type="info"
            effect="float"
          />
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: "My test vEH tYPE  ",
    //   selector: (row) => row.vehicle_type_id,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="actions toggle-delete-actions">
    //       {vehicleTypes
    //         .filter((e) => e.value === row.vehicle_type_id)
    //         .map((e) => e.title)}
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: "Action",
    //   // selector: (row) => row.id,
    //   cell: (a) =>
    //   <div className="actions toggle-delete-actions">
    //            <span className="action-delete" onClick={deleteBooking} data-id={a.customer_name}>Delete</span>
    //   </div>,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  return (
    <Fragment>
      <div className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive single-column-view">
            <DataTable
              onRowClicked={setEditForm}
              columns={columns}
              data={table}
              pagination
              highlightOnHover={true}
              pointerOnHover={true}
              paginationComponentOptions={paginationComponentOptions}
            />
            <table className="table custom-table clickable-table">
              {/* <thead>
                <tr
                  className={`trow single-row          ${
                    Object.keys(mappedServiceData).length &&
                    mappedServiceData.service_id === table.id
                      ? "activeRow"
                      : "test1"
                  }`}
                >
                  <th scope="col">Name</th>
                  <th scope="col">Vehicle Type</th>
                  <th>Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(table).length ? (
                  table.map((a, index) => (
                    <ServiceRow
                      handleViewService={handleViewService}
                      id={a.id}
                      key={a.id}
                      pic={a.pic}
                      name={a.service_name}
                      serviceformClose={props.serviceformClose}
                      status={a.status}
                      vehicleSelect={a.vehicle_type_id}
                      isActive={a.is_active}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="no-data-td">
                      <div className="no-data-available">
                        <div className="no-data-img">
                          <img src={noDataImage} alt="" />
                          <h5>No Data Available</h5>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Amet ullam voluptatem quam pariatur?
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
      {/* { viewservice ? <WindowRight class={toggleClassCheck} /> : ""} */}

      {/* { viewservice ? < WindowRight someObj={someObj} /> : ""} */}
      {deletemodal && (
        <ServiceModal
          SetDeleteModal={SetDeleteModal}
          id={rowId}
          name={rowName}
        />
      )}
    </Fragment>
  );
};

export default WindowLeft;
